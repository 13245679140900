







































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import SampleParamsEditor from './SampleParamsEditor.vue';
import * as API from '../../store/api';

@Component({
  components: { SampleParamsEditor },
})
export default class ModalResultCard extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  @Prop({ type: Object, required: false, default: null }) sample;
  @Prop({ type: Object, required: false, default: null }) test;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;
  isEditing = false;
  isFinalEditing = false;
  sampleTestParamsTree = [];
  isEditingFinalParam = false;
  bus = new Vue();
  numbers = [];
  numberValues = [];
  selectedNumbers = [];
  sampleTool = [];
  fetchedSampleTools = [];
  users = null;
  resultUserValue = null;
  numberValue = null;
  enabled = true;
  editedSampleTestParam = null;
  isEditingSampleTestParam = false;
  deletedParams = [];
  methodId = null;
  testToolGroups = [];

  @Emit()
  modalClose() {
    return 1;
  }

  get mappedUsers() {
    if (this.users) {
      return this.users.map(u => {
        const retUser = { id: u.id, name: u.firstname + ' ' + u.lastname };
        return retUser;
      });
    } else {
      return [];
    }
  }

  get finalParams() {
    return this.test.sampleTestParams.filter(stp => {
      return stp.is_final_result;
    });
  }

  get number() {
    this.revalidate;
    return this.numbers;
  }

  hoverOn(stp) {
    stp['hover'] = true;
    this.$forceUpdate();
  }
  hoverOff(stp) {
    stp['hover'] = false;
    this.$forceUpdate();
  }
  editParamClick(stp) {
    console.log('edit click', stp);
    if (!stp.is_final_result) {
      this.isEditingSampleTestParam = true;
    } else {
      this.isEditingFinalParam = true;
    }
    this.editedSampleTestParam = stp;
  }
  editParamStop() {
    this.isEditingSampleTestParam = false;
    this.isEditingFinalParam = false;
  }
  toggleEditing() {
    this.isEditing = !this.isEditing;
  }
  toggleFinalEditing() {
    this.isFinalEditing = !this.isFinalEditing;
  }
  async showModal(params: any) {
    console.log('ModalResultCard::showModal', params);
    this.test = params.currentTest;
    this.show = true;
    this.save = false;
    this.params = params;
    let response = await API.getSampleTest(this.test.id);
    if (response.data.success) {
      this.test = response.data.sample_test;
      if (this.test.test_laborant_id === undefined) {
        this.test.test_laborant_id = null;
      }
      if (this.test.result_laborant_id === undefined) {
        this.test.result_laborant_id = null;
      }
      console.log('fetch test on showmodal', this.test);
    }
    this.bus.$emit('setup');
    this.bus.$emit('treeFromParams');

    response = await API.fetchUsers();
    this.users = response.data.users;
    const mappedusers = this.users.map(u => {
      const retUser = { id: u.id, name: u.firstname + ' ' + u.lastname };
      return retUser;
    });

    console.log('MappedUsers', mappedusers, this.test);
    this.selectedTestLaborant = mappedusers.find(u => {
      return u.id === this.test.test_laborant;
    });

    this.resultUserValue = mappedusers.find(u => {
      return u.id === this.test.result_laborant;
    });

    const methodNumber = this.test.number;
    response = await API.fetchTest({ number: methodNumber });
    if (response.data.success) {
      const method = response.data.test;
      this.methodId = method.id;
      console.log('method id', this.methodId);
    }

    response = await API.fetchTestToolGroups(this.methodId);
    if (response.data.success) {
      this.testToolGroups = response.data.test_tool_groups;
      console.log('testToolGroups', this.testToolGroups);
    }

    response = await API.fetchModel('Tool');
    this.numberValues = response.data.objs.map(el => {
      return { id: el.id, codeNumber: el.code_number };
    });
    for (let i = 0; i < this.testToolGroups.length; i++) {
      this.sampleTool.push({ sample_id: this.test.sample, code_number: i });
    }

    const sampleId = this.test.sample;
    response = await API.fetchSampleTools(sampleId);
    if (response.data.success) {
      this.fetchedSampleTools = response.data.sample_tools;
      for (let i = 0; i < this.testToolGroups.length; i++) {
        this.numbers.push({ id: i + 1, codeNumber: this.fetchedSampleTools[i].code_number });
      }
    } else {
      this.fetchedSampleTools = [];
    }
  }
  async hideModal() {
    this.params['save'] = this.save;
    if (!this.save) {
      const response = await API.getSampleTest(this.test.id);
      if (response.data.success) {
        this.test = response.data.sample_test;
        console.log('fetch test on cancel', this.test);
      }
    }
    this.params['savedTest'] = this.test;
    console.log('TEST', this.test.sampleTestParams, 'SAVE', this.params['save']);
    this.deletedParams = [];
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
  emitUpdate(emitTest) {
    this.test = emitTest;
  }
  workUserSelected(e: any) {
    this.selectedTestLaborant = e;
  }
  carryUserSelected(e: any) {
    this.resultUserValue = e;
  }
  numberSelected(e: any, id: number) {
    e.id = id + 1;
    if (this.numbers.length <= id) {
      this.numbers.push(e);
    } else {
      this.numbers[id] = e;
    }
    this.revalidate++;
  }
  async saveModal() {
    this.save = true;
    this.params['save'] = true;
    this.params['savedTest'] = this.test;
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.show = false;
    if (this.testUserValue === undefined) {
      this.test['test_laborant_id'] === 0;
    } else {
      this.test['test_laborant_id'] = this.testUserValue;
    }

    if (this.resultUserValue === undefined) {
      this.test['result_laborant_id'] === 0;
    } else {
      this.test['result_laborant_id'] = this.resultUserValue;
    }

    if (this.finalParams.length === 1) {
      this.test.value = this.finalParams[0].value;
    }
    console.log('TO SEND TEST', this.test);
    console.log('TO SEND SAMPLE', this.sample);
    if (this.number.length > 0) {
      for (let i = 0; i < this.testToolGroups.length; i++) {
        this.sampleTool[i].code_number = this.number[i].codeNumber;
      }
    }
    console.log('TO SEND SAMPLE TOOL', this.sampleTool);
    const response = await API.saveSample(this.sample, this.test, this.sampleTool);
    console.log(response);
  }

  selectedTestLaborant = {};
  get testUserValue() {
    return this.selectedTestLaborant;
  }

  async created() {
    console.log('start sampleTool', this.sampleTool);
    this.enabled =
      this.sample.status === consts.SampleStatus.IN_EXAM.id || this.sample.status === consts.SampleStatus.CORRECT.id;
  }
}
