console.log('--- TYPES FILE LOADING ---');
import * as consts from '@/consts';
import { OrderStatus, SortDir, VisitStatus, UserRightSymbols } from '@/consts';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

import { Enumify } from 'enumify';
import { identity } from '@fullcalendar/vue';
import { Test } from 'mocha';
import { elpolabApi } from '@/store/api';

export class RightQueueItem {
  modelName: string;
  data: object;
}

export class DashboardEntity {
  id: number;
  number: string;
  name?: string;

  get getNumber() {
    return this.number;
  }
  set getNumber(value: string) {
    this.number = value;
  }
  get getDONEActionStatus(): Enumify {
    return null;
  }
  get getStatus() {
    return null;
  }
  set statusId(id: number) {
    console.log('');
  }
  update() {
    console.log('');
  }
}

export class ExtendedParam {
  value?: any;
  fieldName?: any;
  headerName: string;
  action?: any;
  getValues?: any;
  visible?: any;
  fieldType?: string;
  fieldCss?: any;
  css?: string;
  columnCss?: string;
  options?: any[];
  disabled?: boolean;
  multiSelectTrack?: string;
  valueSelected?: any;
  badgeVariant?: any;
  useAlternativeBadgeStyling?: any;
  changed?: any;
  maxlength?: any;
  href?: any;
  bufferValue?: any;
  load?: any;
  icon?: string;
  size?: string;
  headerCss?: string;
  engine?: any;
  sortField?: string;
  evaluate?: any;
  mainCss?: string;
  key?: number;

  constructor(value: string, type: string, headerName: string) {
    this.value = value;
    this.headerName = headerName;
  }
}

interface VaffleComponentEngine {
  load: any;
  action: any;
}

export class StandardMultiselectEngine implements VaffleComponentEngine {
  //fieldName: string;

  fieldNameLoad: string;
  fieldNameWrite: string;

  constructor(fieldNameWrite: string, fieldNameLoad?: string) {
    if (!fieldNameLoad) {
      this.fieldNameLoad = 'id';
    } else {
      this.fieldNameLoad = fieldNameLoad;
    }
    this.fieldNameWrite = fieldNameWrite;
  }

  load(object, param) {
    console.log('ENGINE LOAD', param.options, this.fieldNameLoad, this.fieldNameWrite, object);
    const res = param.options.find(el => {
      return el[this.fieldNameLoad] === object[this.fieldNameWrite];
    });
    return res;
  }

  action(event, object) {
    object[this.fieldNameWrite] = event.id;
  }
}

export class Sample extends DashboardEntity {
  take_date: string;
  take_time: string;

  sample_object_id: number;
  sample_object: any;
  object_type_id: number;
  pt_number: string;

  source_id: number;
  source: any;
  take_method_id: number;
  take_method_ids: any[];
  take_place_ids: any[];
  area_test_id: number;
  prepare_norm_id: number;
  recalculate_norm_id: number;

  posting_date: string | null;
  arrival_datetime: string | null;
  declared_class: string | null;
  mine_name: string | null;
  wagon_number: string | null;

  tonnage: number;
  vehicle_count: number;
  additional: boolean;
  package_name: string;
  description: string;
  status_id: number;
  status?: consts.SampleStatus;
  tests?: Method[];
  sample_dates: any[];
  sample_tests?: any[];
  sample_take_methods: any[];
  sample_take_places?: any[];
  implementation_base_string?: string;
  take_person_string?: string;
  identifier_number: string;
  take_norm_string?: string;
  take_place_string?: string;
  take_method_string?: string;

  client_name: string;
  client_type: number;
  client_id?: number;

  constructor() {
    super();

    this.status_id = consts.SampleStatus.REGISTERED.id;
    this.status = consts.SampleStatus.REGISTERED;
    this.id = 0;
    this.number = '';
    this.take_date = '';
    this.take_time = '';

    this.sample_object_id = -1;
    this.sample_object = null;
    this.source_id = -1;
    this.source = null;
    this.object_type_id = -1;
    this.take_method_id = -1;
    this.take_method_ids = [];
    this.take_place_ids = [];
    this.area_test_id = -1;

    this.posting_date = null;
    this.arrival_datetime = null;
    this.declared_class = null;
    this.mine_name = null;
    this.wagon_number = null;

    this.additional = false;
    this.package_name = '';
    this.description = '';
    this.tests = [];

    this.implementation_base_string = '';
    this.take_person_string = '';
    this.take_norm_string = '';
    this.take_place_string = '';
    this.take_method_string = '';

    this.prepare_norm_id = -1;
    this.recalculate_norm_id = -1;

    this.client_name = '';
    this.client_type = consts.ClientType.INTERNAL;
    this.client_id = -1;
  }
  load(copy = true) {
    console.log('Sample load empty');
    return undefined;
  }
}

// export class SamplePrepareChecklist {
//   sample: Sample;
//   sample_type: string;
//   sample_action: string;
//   sample_date: string;
//   execute_user: User;

//   constructor() {
//     this.sample = new Sample();
//     this.sample_type = '';
//     this.sample_action = '';
//     this.sample_date = '';
//     this.execute_user = new User();
//   }
// }

export class Address {
  id: number;
  city?: string;
  street_and_house_no?: string;
  postal_code?: string;
  correspond?: string;
  create_date_time?: string;
  changed_date_time?: string;

  constructor() {
    this.id = 0;
    this.city = '';
    this.street_and_house_no = '';
    this.postal_code = '';
    this.correspond = '';
  }
}

export class Client {
  id: number;
  name?: string;
  short_name?: string;
  code?: string;
  address_id?: number;
  address?: Address;
  nip?: string;
  active?: boolean;
  correspond_address?: string;

  contact_person_name?: string;
  contact_email?: string;
  contact_phone?: string;

  cplaces?: ConstructionPlace[];

  contract?: string;

  create_date_time?: string;
  changed_date_time?: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.code = '';
    this.cplaces = [];
    this.short_name = '';
    this.address_id = -1;
    this.address = new Address();
    this.active = true;
    this.contact_email = '';
    this.contact_person_name = '';
    this.contact_phone = '';
    this.contract = '';
  }

  load() {
    this.address = null; //dictModule.addressById(this.address_id);
  }

  import?(obj: object) {
    Object.assign(this, obj);
  }
}

export class ConstructionPlace {
  id: number;
  name: string;
  short_name: string;
  symbol?: string;
  active?: boolean;
  client_id: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.short_name = '';
    this.symbol = '';
    this.active = true;
  }
}

export class Exam {
  id: number;
  protocol_id?: number;
  number?: string;
  name: string;
  client_id: number;
  client?: Client;
  description: string;
  sample_ids: number[];
  samples?: Sample[];
  stage_id?: number;
  stage?: ExamStage;
  current_laborant_id?: number;
  current_laborant?: User;
  age?: number;
  status?: number;

  constructor() {
    this.id = 0;
    this.number = '';
    this.sample_ids = [];
    this.age = 28;
    this.status = 1; // do badania
  }

  load() {
    //this.client = dictModule.clientById(this.client_id);
    //this.stage = examsModule.examStageById(this.stage_id);
    this.samples = [];
    this.sample_ids.forEach(id => {
      //this.samples.push(Object.assign(new Sample(), samplesModule.sampleById(id)));
    });
    //this.current_laborant = usersModule.userById(this.current_laborant_id);

    console.log('Exam ', this.id, this.number, 'loaded');
  }
}

export class ExamStage {
  id: number;
  index: number; // numer etapu
  name: string;
  description?: string;
  exam_id?: number;
  exam?: Exam;
  tool_ids?: number[];
  tools?: Tool[];
}

export class ExamStageParam {
  id: number;
  name: string;
  unit?: string;
  formula?: string;
  description?: string;
  exam_stage_id?: number;
  exam_stage?: ExamStage;
  symbol?: string;
  on_rd?: boolean; // czy jest na sprawozdaniu
}
export class ExamSampleStageParam {
  id: number;
  param_id?: number;
  param?: ExamStageParam;
  sample_id?: number;
  sample?: Sample;
  value?: string;
}

export class MethodStage {
  id: number;
  name: string;
  description: string;
  method_id?: number;
  method?: Method;
  tool_ids?: number[];
  tools?: Tool[];

  params?: MethodStageParam[];
  stage_tools?: MethodStageTool[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.method_id = -1;
    this.method = null;
    this.tool_ids = [];
    this.tools = [];
  }
  load() {
    //this.params = methodsModule.methodStageParams.filter(p => p.method_stage_id === this.id);
    //this.stage_tools = methodsModule.methodStageTools.filter(t => t.method_stage_id === this.id);
    this.tools = [];
    this.stage_tools.forEach(st => {
      //this.tools.push(toolsModule.toolById(st.tool_id));
    });
  }
}
export class MethodStageParam {
  id: number;
  name: string;
  unit?: string;
  formula?: string;
  description?: string;
  method_stage_id?: number;
  method_stage?: MethodStage;
  symbol?: string;
  on_rd?: boolean; // czy jest na sprawozdaniu
}

export class MethodStageTool {
  id: number;
  method_stage_id?: number;
  method_stage?: MethodStage;
  tool_id?: number;
  tool?: Tool;
}

export class Localization {
  id: number;
  name: string;
  code?: string;
}

export class UserRight {
  id: number;
  name: string;
  view_group?: string;
  symbol: string;
  description?: string;
}

export class UserAuth {
  id: number;
  name: string; // dict.value
  type?: number; // dict.type
}

export class UsersRight {
  id: number;
  right_id: number;
  right: UserRight;
  user_id: number;
  user: User;
}

export class UserSubmit {
  email: string;
  password: string;
}

export class UserGroup {
  id: number;
  name: string;
  type: number;
  index: number;
  symbol: string;
  group_ids: number[];
  right_ids: number[];
  rights?: UserRight[];
  right_symbols?: string[];
  description: string;

  load() {
    this.rights = [];
    this.right_ids.forEach(right_id => {
      //const r = usersModule.getRightById(right_id);
      //this.rights.push(r);
    });
  }
}

export class UserData {
  id: number;
  initials: string;
  positions: string;
  module: string;
  status_id: number;
  created_date_time?: string;
  changed_date_time?: string;
  user_id?: number;
  phone: string;
  calendar_index?: number;
}

export class User extends DashboardEntity {
  email: string;
  firstname: string;
  lastname: string;
  position?: string;
  token?: string;
  is_logged_in: boolean;
  created_date_time?: string;
  changed_date_time?: string;
  module?: number;
  initials: string;
  role_id?: number;
  group_ids?: number[];
  groups?: UserGroup[];
  right_ids?: number[];
  rights?: UserRight[];
  status_id?: number;
  status?: consts.UserStatus;
  user_data?: UserData;
  sign_image?: File;
  sign_image_id?: number;

  sign_image_guid: string;

  rights_to_delete: Array<RightQueueItem>;
  new_rights: Array<RightQueueItem>;

  public get fullName() {
    return this.firstname + ' ' + this.lastname;
  }
  get getNumber() {
    return this.fullName;
  }
  get getStatus() {
    return this.status;
  }
  set statusId(id: number) {
    this.status_id = id;
    this.status = consts.UserStatus.byId(id);
  }

  get getDONEActionStatus(): Enumify {
    return consts.PersonelActionStatus.DONE;
  }

  async update() {
    //await usersModule.updateUser(this);
  }

  constructor() {
    super();
    this.id = 0;
    this.status_id = consts.UserStatus.NEW.id;
    this.status = consts.UserStatus.NEW;
    this.email = '';
    this.firstname = '';
    this.lastname = '';
    this.token = '';
    this.is_logged_in = false;
    this.group_ids = [];
    this.groups = [];
    this.right_ids = [];
    this.rights = [];
    this.role_id = -1;
  }

  load(copy = true) {
    //console.log('User load', this);
    this.rights = [];
    this.right_ids.forEach(rightId => {
      //this.rights.push(usersModule.getRightById(rightId));
    });
    this.groups = [];
    this.group_ids.forEach(groupId => {
      //const gr = usersModule.getGroupById(groupId);
      //gr.load();
      //this.groups.push(gr);
    });
    this.status = consts.UserStatus.byId(this.status_id);
  }
  hasRight(rightToCheck: UserRight) {
    //let result = false;
    this.right_ids.forEach(rId => {
      //const right = usersModule.getRightById(rId);
      //if (right.id === rightToCheck.id) {
      //result = true;
      //}
    });
    //return result;
  }
}
export class UserResponse {
  user: User;
}
export class Profile {
  user?: User;
}

export class ResultsDoc {
  id: number;
  number: string;
  object_type_id: number;
  object_type: object;
  type: string;
  date: string;
  result_file: File;
  period: string;
  status_id: number;
  status: consts.ReportStatus;
  user_created: User;
  user_authorised: User;
  user_accepted: User;

  load() {
    this.status = consts.ReportStatus.arr[this.status_id];
  }
}

export class BreadcrumbItem {
  title: string;
  name?: string;
  link?: string | object;
  view?: AppView;
  class?: string;
}

export class Breadcrumb {
  routePrefix: string;
  items: BreadcrumbItem[];
}

export interface AppView {
  broadcrumb?: Breadcrumb;
}

/**
 * IFilterabe - this interface is needed when we want to use [] accessing member by name
 */
export interface Filterable {
  [propName: string]: any;
}

export interface Filter {
  field: string;
  value: string;
}

export interface Sort {
  field: string;
  dir: SortDir;
}

export interface ListParams {
  pageNo: number;
  pageSize: number;
  curPageSize: number;
  rowsAllCount: number;
  pagesAllCount: number;
  filter: Filter;
  sort: Sort;
}

export interface RouterSettings {
  x: number;
  //last_view?: CalendarView;
}

export interface Settings {
  router_settings?: RouterSettings;
}

export class Material {
  id: number;
  name: string;
  description?: string;
  visible?: boolean; // cy pokazywac material na listach
}

export class MethodUser {
  id?: number;
  user_id?: number;
  user?: User;
  method_id?: number;
  method?: Method;
  from_date?: string;
  date_type?: string;

  load() {
    //this.user = usersModule.userById(this.user_id);
    //this.method = methodsModule.methodById(this.method_id)
  }
}

export class MethodGroup {
  id: number;
  name: string;
  symbol?: string;
}

export class ToolTemplate {
  id: number;
  template_id: number;
  template: TemplateItem;

  tool_id: number;
  tool: Tool;

  start_date_time: string;
  end_date_time: string;

  changed_date_time: string;
  create_date_time: string;
}

export class ToolService {
  id: number;
  execution_date: string;

  executor: string;
  description: string;
  changed_date_time: string;
  create_date_time: string;
  tool_id: number;
  tool: Tool;
}

export class ToolTest {
  id: number;
  specification: string;

  result: string;
  changed_date_time: string;
  create_date_time: string;

  test_id: number;
  test: Method;

  tool_id: number;
  tool: Tool;
}

export class TemplateItem {
  id: number;
  code_number: string;
  name: string;
  value: string;

  category_number: string;
  producer: string;
  distributor: string;
  user: string;
  delivery_date: string;
  expiration_date: string;

  remarks?: string;

  changed_date_time: string;
  created_date_time: string;
}

export class ToolMaintenance {
  id: number;
  tool_id: number;
  tool: Tool;

  execution_date: string;
  executor: string;
  description: string;
  changed_date_time: string;
  create_date_time: string;
}

export class ToolElement {
  id: number;
  tool_id: number;
  tool: Tool;

  user_id: number;
  user: User;

  start_use_date: string;
  end_use_date: string;

  create_date_time: string;
  changed_date_time: string;
}

export class ToolComment {
  id: number;
  tool_id: number;
  tool: Tool;

  user_id: number;
  user: User;

  description: string;

  comment_date: string;
  create_date_time: string;
  changed_date_time: string;
}

export class Method extends DashboardEntity {
  description?: string;
  norm?: string;
  add_date_time?: string;
  status_id: number;
  status?: any;
  group_id?: number;
  group?: MethodGroup;
  unit?: string;
  price?: number;
  limit?: number;
  acreditation?: boolean;
  actual?: number;

  created_date_time?: string;
  changed_date_time?: string;

  method_user_ids?: number[];
  method_users?: MethodUser[];

  userHasRight: boolean;

  stages?: MethodStage[];
  params?: MethodStageParam[];
  tools?: MethodStageTool[];

  constructor() {
    super();
    this.id = 0;
    this.status_id = consts.MethodStatus.NEW.id;
    this.status = consts.MethodStatus.NEW;
    this.name = '';
    this.group_id = -1;
    this.norm = '';
    this.actual = 0;
    this.acreditation = false;
    this.method_users = [];
  }

  get getNumber() {
    return this.number;
  }
  set setNumber(value: string) {
    this.number = value;
  }
  get getStatus() {
    return this.status;
  }
  set statusId(id: number) {
    this.status_id = id;
    this.status = consts.MethodStatus.byId(id);
  }

  get getDONEActionStatus(): Enumify {
    return consts.MethodActionStatus.DONE;
  }
  async update() {
    this.load();
    //await methodsModule.updateMethod(this);
  }
  load() {
    //console.log('Method ', this.id, 'loading...');
    //this.group = dictModule.methodGroupById(this.group_id);
    this.status = consts.MethodStatus.byId(this.status_id);
    this.method_users = [];
    // this.method_user_ids.forEach(uId => {
    //   //this.method_users.push(methodsModule.methodUserById(uId));
    // });
    //this.stages = methodsModule.methodStagesByMethodId(this.id);
    // this.stages.forEach(s => {
    //   s.load();
    // });
  }
}

export class Car {
  id: number;
  number: string;
  register_number?: string;
  default_user_id?: number;
  default_user?: User;
}
export class ToolGroup {
  id: number;
  name: string;
  index?: number;
}

export class FilterData {
  constructor(
    model: string,
    page: number,
    pageSize: number,
    filters: object,
    sortParams: object,
    additionalData?: object
  ) {
    this.model = model;
    this.page = page;
    this.pageSize = pageSize;
    this.filters = filters;
    this.sortParams = sortParams;
    this.additionalData = additionalData;
  }

  model: string;
  page: number;
  pageSize: number;
  filters: object;
  sortParams: object;
  additionalData: object;
}

export class Tool extends DashboardEntity {
  group_id?: number;
  tool_group?: ToolGroup;
  description?: string;
  localization_id?: number;
  localization?: Localization;
  add_date_time?: string;
  status_id: number;
  status?: consts.ToolStatus;
  fabric_number?: string;
  invent_number?: string;
  car_id?: number;
  car?: Car;

  tool_tests: Array<ToolTest>;
  tool_services: Array<ToolService>;
  tool_templates: Array<ToolTemplate>;
  tool_maintenances: Array<ToolMaintenance>;
  tool_elements: Array<ToolElement>;
  tool_comments: Array<ToolComment>;

  userHasRight: boolean;

  w_check: boolean;
  w_templated: boolean;
  w_calibration: boolean;

  created_date_time?: string;
  changed_date_time?: string;

  constructor() {
    super();
    this.id = 0;
    this.status_id = consts.ToolStatus.NEW.id;
    this.status = consts.ToolStatus.NEW;
    this.name = '';
    this.localization_id = -1;
    this.group_id = -1;
    this.car_id = -1;
    this.fabric_number = '';
    this.invent_number = '';
  }

  get getNumber() {
    return this.invent_number;
  }
  set setNumber(value: string) {
    this.invent_number = value;
  }
  get getDONEActionStatus(): Enumify {
    return consts.ToolActionStatus.DONE;
  }
  get getStatus() {
    return this.status;
  }
  set statusId(id: number) {
    this.status_id = id;
    this.status = consts.ToolStatus.byId(id);
  }
  async update() {
    //await toolsModule.updateTool(this);
  }
  load(allTests?: Array<Method>, allTemplateItems?: Array<TemplateItem>) {
    this.status = consts.ToolStatus.byId(this.status_id);

    ['tool_comments', 'tool_maintenances', 'tool_elements', 'tool_services', 'tool_tests', 'tool_templates'].forEach(
      arrField => {
        if (Array.isArray(this[arrField])) {
          return;
        }
        if (this[arrField] === undefined) {
          this[arrField] = [];
        } else if (typeof this[arrField] === 'object') {
          this[arrField] = [this[arrField]];
        }
      }
    );

    if (allTests) {
      this.tool_tests.forEach(el => {
        el.test = allTests.find(test => test.id === el.test_id);
        el.tool_id = this.id;
      });
    }

    if (allTemplateItems) {
      this.tool_templates.forEach(el => {
        el.template = allTemplateItems.find(templateItem => templateItem.id === el.template_id);
        el.tool_id = this.id;
      });
    }
  }
}

export class DashboardAction {
  id: number;
  index: number;
  from_status_id: number;
  to_status_id: number;
  comment?: string;
  dashboard_item_id: number;
  dashboard_item: DashboardItem;
  action_date_time: string;
  new_status_person_id?: number;
  new_status_person?: User;
  action_person_id?: number;
  action_person?: User;

  constructor() {
    this.id = 0;
    this.index = 0;
    this.from_status_id = -1;
    this.to_status_id = -1;
    this.comment = '';
    this.dashboard_item_id = -1;
    this.dashboard_item = null;
    this.new_status_person_id = -1;
    this.new_status_person = null;
    this.action_person_id = -1;
    this.action_person = null;
  }
  load(dashboardItem: DashboardItem) {
    //this.new_status_person = usersModule.userById(this.new_status_person_id);
    //this.dashboard_item = dashboardItemsModule.dashboardItemById(this.dashboard_item_id);
  }
}

export class DashboardColumn {
  title: string;
  statusId: number;
  status: Enumify;
  index: number;
  fullIndex: string;
  editable: boolean;
  items: DashboardItem[];

  constructor(status: Enumify, index: number, editable = false) {
    this.title = status['name'];
    this.statusId = status['id'];
    this.status = status;
    this.index = index;
    this.editable = editable;
  }
}

export class DashboardPerson {
  id: number;
  person_id: number;
  person?: User;
  dashboard_item_id?: number;
  dashboard_item?: DashboardItem;
  required?: boolean;
  status_id?: number;
  status?: Enumify;
  done: boolean;

  constructor() {
    this.id = 0;
    this.person_id = 0;
    this.person = null;
    this.dashboard_item_id = 0;
    this.dashboard_item = null;
    this.required = false;
    this.status_id = 0;
    this.status = null;
    this.done = false;
  }
}

export class DashboardItem {
  id: number;
  object_id: number; // id obiektu czyli. tool, method, user itd
  object?: DashboardEntity; // obiekt ktorego typ poznamy pozniej, tool, method, use itd
  object_type_id: number; // DashboardActionTypes
  object_type?: consts.DashboardObjectTypes;
  operation_type_id: number; //  EDYCJA, SERWIS
  operation_type?: Enumify;
  status_id: number; // ZAINICJOWANE, W TRAKCIE, UKONCZONE... ToolActionStatus, MethodActionStatus...
  status?: Enumify;
  start_date_time: string;
  end_date_time?: string;
  person_initiated_id?: number; // osoba ktora zainicjowala akcje
  person_initiated?: User;
  enabled: boolean;
  comment?: string; // zbiorczy koment wszystkich akcji

  index?: number;
  columnStatusId?: number;
  objectTypeName?: string;

  constructor() {
    this.id = 0;
    this.start_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    this.object_type_id = -1;
    this.operation_type_id = -1;
    this.status_id = -1;
    this.end_date_time = '';
    this.person_initiated_id = -1;
    this.enabled = true;
  }

  load() {
    console.log('Loading DashboardItem', this.id, this.object_type_id);
    if (this.object_type_id < 0 || this.operation_type_id < 0 || this.status_id < 0) {
      return;
    }
    switch (this.object_type_id) {
      case consts.DashboardObjectTypes.METHOD: {
        this.objectTypeName = 'Metoda badawcza';
        // this.object = methodsModule.methodById(this.object_id);
        console.log('Loading METHOD', this.object_id, this.object);
        (this.object as Method).load();
        this.operation_type = consts.MethodOperationType.byId(this.operation_type_id);
        this.status = consts.MethodActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.TOOL: {
        //console.log("Loading TOOL", this.object_id);
        this.objectTypeName = 'Urządzenie badawcze';
        //this.object = toolsModule.toolById(this.object_id);
        (this.object as Tool).load();
        this.operation_type = consts.ToolOperationType.byId(this.operation_type_id);
        this.status = consts.ToolActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.PERSONEL: {
        //console.log("Loading PERSONEL", this.object_id);
        this.objectTypeName = 'Pracownik';
        //this.object = usersModule.userById(this.object_id);
        (this.object as User).load(false);
        this.operation_type = consts.PersonelOperationType.byId(this.operation_type_id);
        this.status = consts.PersonelActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.SAMPLE: {
        console.log('Loading SAMPLE', this.object_id);
        this.objectTypeName = 'Próbka';
        (this.object as Sample).load(false);
        this.operation_type = consts.SampleOperationType.byId(this.operation_type_id);
        this.status = consts.SampleStatus.byId(this.status_id);
        break;
      }
      default: {
        console.log('Loading DEFAULT', this.object_id);
        break;
      }
    }
    //this.person_initiated = usersModule.userById(this.person_initiated_id);
  }
}

export class Message {
  id: number;
  title: string;
  content: string;
  created_date_time: string;
  group_id: number; // 0 - BADANIA, 1 - METROLOGIA
  message_type: number; // 0 - message, 1 - warning, 2 - error
  sender_id?: number; // id usera ktory wyslal wiadomosc jezeli to nie jest wiadomosc systemowa
  hash?: string;
  object_id?: number;
  object_type_id?: number;
  is_read?: boolean;
}

export class DocTemplate {
  id?: number;
  attachement: File;
  attachement_id: number;

  limited_sample_count: boolean;
  maximum_sample_count: number;

  symbol: string;
  name: string;
  active: boolean;
  version: string;

  accreditation: boolean;
  client_type: number;

  object: object;
  source: object;
  object_type: object;

  object_id: number;
  source_id: number;
  object_type_id: number;

  guid: string;

  constructor() {
    this.object = this.object_id = null;
    this.source = this.source_id = null;
    this.object_type = this.object_type_id = null;
    this.limited_sample_count = false;
    this.client_type = 0;
  }

  getAsFilterData() {
    const data = {
      sample_object_id: this.object_id,
      object_type_id: this.object_type_id,
      source_id: this.source_id,
    };

    for (const [key, val] of Object.entries(data)) {
      if (!val) {
        delete data[key];
      }
    }

    const clientType = this.client_type;
    if (clientType === consts.docTemplateClientType.EXTERNAL_CLIENT) {
      data['client_id__gt'] = consts.CLIENT_ID_ELEKTROWNIA;
    } else if (clientType === consts.docTemplateClientType.INTERNAL_CLIENT) {
      data['client_id'] = consts.CLIENT_ID_ELEKTROWNIA;
    }

    console.log('generating data for filter', data);

    return data;
  }
}
export class File {
  id?: number;
  path?: string;
  related_model?: string;
  related_id?: number;
  create_date_time?: string;
  changed_date_time?: string;
  name?: string;
  guid?: string; // id usera ktory wgral plik
}

export class Dict {
  id: number;
  type: number;
  subtype?: number;
  value: string;
  symbol?: string;
  active: boolean;
  value2?: string;
  related_id?: number;
  parent_id?: number;
  description?: string;
  create_date_time: string;
  changed_date_time: string;
}

export class DictToSave {
  active: boolean;
  type: number;
  symbol?: string;
  value: string;
  value2?: string;
}

export class Structure {
  id?: number;
  object_id?: number;
  source_id?: number;
  object_type_id?: number;
  take_method_id?: number;
  take_place_id?: number;
  prepare_norm_id?: number;
  recalculate_norm_id?: number;
  area_test_id?: number;
}

export interface DashboardExternalOrder {
  number: string;
  client__name: string;
}

export interface DashboardReport {
  number: string;
  date: string;
}

export interface DashboardMethod {
  name: string;
  number: string;
  method_usage_amount: number;
  norm: string;
}

export interface DashboardLaborant {
  test_laborant__first_name: string;
  test_laborant__last_name: string;
  number_of_tests: number;
  fullName?: string;
}

export type DashboardStatistics =
  | DashboardExternalOrder[]
  | DashboardReport[]
  | DashboardMethod[]
  | DashboardLaborant[];

export type DashboardStatisticName =
  | 'last_external_orders'
  | 'last_reports'
  | 'most_used_methods'
  | 'samples_done_today'
  | 'samples_in_exam'
  | 'top_laborants'
  | 'additional_samples';

export class ReadingsDefinition extends DashboardEntity {
  object_type?: string;
  take_place?: string;
  hour?: number;
  minute?: number;

  created_date_time?: string;
  changed_date_time?: string;

  constructor() {
    super();
    this.object_type = '';
    this.take_place = '';
    this.hour = 0;
    this.minute = 0;
  }

  load(copy = true) {
    console.log(this);
  }
}

export class Activity {
  name: string;
  type: number;
  subtype: number;
  symbol?: string;
  user_id: number;
  value_int?: number;
  value_str?: string;
}
