

































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ToolModal from '@/views/laborant/administration/ToolModal.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericList from '@/components/spec/GenericList.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as consts from '@/consts';
import * as helpers from '@/helpers';
import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '@/consts';
import {
  AppView,
  Exam,
  DashboardItem,
  User,
  DashboardAction,
  Sample,
  ExtendedParam,
  Tool,
  StandardMultiselectEngine,
} from '@/types';
import { multiselectMixin } from 'vue-multiselect';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
    ToolModal,
  },
})
export default class ToolList extends Vue {
  @Ref() readonly modalFilter: Modal;
  params = new Array<ExtendedParam>();
  @Ref() readonly modal: Modal;
  @Ref() readonly toolModal: ToolModal;
  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  object = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;

  processLoadedElement(object: any) {
    console.log(object, 'loaded');
    object.status = consts.ToolStatus.byId(object.status_id);
  }

  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }

  showModal(object: any) {
    this.object = object;
    this.modal.showModal();
  }

  isNew = false;
  editedTool: Tool = new Tool();

  showToolModal(tool: Tool) {
    if (Object.entries(tool).length !== 0) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }

    this.toolModal.showModal(tool, this.isNew);
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, e);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  workingNode = {
    value: 'Sprawne',
    status_id: 1,
    neighs: [],
  };

  newNode = {
    value: 'Nowe',
    status_id: 0,
    neighs: [],
  };

  notWorkingNode = {
    value: 'Niesprawne',
    status_id: 2,
    neighs: [],
  };

  retiredNode = {
    value: 'Wycofane',
    status_id: 3,
    neighs: [],
  };

  statusIdNode: Array<any> = [];

  async created() {
    const response = await API.fetchModel('Tool');
    const allToolGroups = response.data.objs.map(el => {
      return { id: el.id, name: el.name };
    });

    this.newNode.neighs = [{ node: this.workingNode, operationName: 'Wdrożenie' }];
    this.workingNode.neighs = [
      { node: this.notWorkingNode, operationName: 'Awaria' },
      { node: this.retiredNode, operationName: 'Wycofaj' },
    ];

    this.notWorkingNode.neighs = [
      { node: this.workingNode, operationName: 'Przywróć' },
      { node: this.retiredNode, operationName: 'Wycofaj' },
    ];
    this.retiredNode.neighs = [{ node: this.workingNode, operationName: 'Przywróć' }];

    this.statusIdNode = [this.newNode, this.workingNode, this.notWorkingNode, this.retiredNode];

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'code_number',
        headerName: 'Numer kodowy',
      },
      {
        fieldType: 'input',
        fieldName: 'fabric_number',
        headerName: 'Number fabryczny',
      },
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa wyposażenia',
      },
      {
        fieldType: 'input',
        fieldName: 'tool_type',
        headerName: 'Typ narzędzia',
        headerCss: 'col-1 p-0',
      },
      {
        fieldType: 'input',
        fieldName: 'producer',
        headerName: 'Producent',
        headerCss: 'col-1 p-0',
      },
      {
        headerName: 'Status',
        fieldType: 'multiselect',
        options: consts.ToolStatus.array.map(el => {
          return { id: el.id, name: el.name };
        }),
        engine: new StandardMultiselectEngine('status_id', 'id'),
        fieldName: 'status',
        multiSelectTrack: 'name',
      },
    ];
    //----------wyswietlane---------------
    this.params = [
      {
        value: '$code_number',
        headerName: 'NUMER KODOWY',
        columnCss: 'col-1 p-0 text-left',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        value: '$name',
        headerName: 'NAZWA WYPOSAŻENIA',
        headerCss: 'col-2 p-0',
        columnCss: 'col-2 p-0 text-left',
      },
      {
        value: '$fabric_number',
        headerName: 'NUMER FABRYRCZNY',
        headerCss: 'col-2 p-0',
        columnCss: 'col-2 p-0',
      },
      {
        value: '$tool_type',
        headerName: 'TYP NARZĘDZIA',
        headerCss: 'col-2 p-1',
        columnCss: 'col-2 p-1 text-center',
      },
      {
        value: '$producer',
        headerName: 'PRODUCENT',
        headerCss: 'col-2 p-0',
        fieldCss: 'col-2 p-0 text-left',
      },
      {
        fieldType: 'badge',
        badgeVariant: '$status__badge',
        sortField: 'status_id',
        headerName: 'STATUS',
        value: '$status__name',
      },
      {
        headerName: 'Operacje',

        fieldType: 'ntexts',
        href: {
          getValues: (obj: Tool) => {
            if (!obj) return;
            const node = this.statusIdNode[obj.status_id];

            return node.neighs.map(el => {
              return { text: el.operationName, css: 'font-12 clickable-text', status_id: el.node.status_id };
            });
          },

          click: async (obj: Tool, href: any) => {
            console.log(obj, href, 'METODA');
            obj.status_id = href.status_id;
            obj.status = consts.ToolStatus.byId(obj.status_id);
            const response = await helpers.saveObjectModel(obj, 'Tool');
            if (response) {
              helpers.info('Wprowadzono zmianę statusu.');
            } else {
              helpers.error('Wystąpił nieoczekiwany błąd.');
            }
          },
        },

        value: 'tool_group__name',
      },
    ];
    //-------------------------------------
  }
}
