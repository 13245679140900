























































































































































import moment from 'moment';
import { Component, Vue, Ref } from 'vue-property-decorator';
import * as API from '../../store/api';
import * as consts from '../../consts';
import * as types from '../../types';
import TopPanel from '../../components/dummy/TopPanel.vue';
import draggable from 'vuedraggable';
import ModalSample from '../../components/spec/ModalSample.vue';
import ImportSamplesCSV from '../../components/spec/ImportSamplesCSV.vue';
import ModalSampleEdit from '../../components/spec/ModalSampleEdit.vue';
import ModalSamplePrepare from '../../components/spec/ModalSamplePrepare.vue';
import GenericForm from '../../components/spec/GenericForm.vue';
import GenericList from '../../components/spec/GenericList.vue';
import Modal from '../../components/spec/Modal.vue';
import { ExtendedParam } from '../../types';
import * as helpers from '../../helpers';

@Component({
  components: {
    TopPanel,
    ModalSample,
    draggable,
    ImportSamplesCSV,
    GenericList,
    GenericForm,
    Modal,
    ModalSampleEdit,
    ModalSamplePrepare,
  },
})
export default class SamplesBoard extends Vue {
  @Ref() readonly modalSample: ModalSample;
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly modalSampleEdit: ModalSampleEdit;
  @Ref() readonly modalSamplePrepare: ModalSamplePrepare;
  CLIENT_INTERNAL = 1;

  cols: types.DashboardColumn[] = [];
  fullscreen = false;
  cInvalidate = 1;
  colWitdth = '0';
  invalidate = 0;
  lastMove = {
    oldIndex: -1,
    oldStatusId: -1,
    oldStatus: null,
    newStatusId: -1,
    newIndex: -1,
    newStatus: null,
  };

  pageSize = 20;
  listView = false;
  objects = [];
  listParams = new Array<ExtendedParam>();
  filterObject = {};
  copyFilterObject = {};
  listFilterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;

  dateSelectOptions = [
    {
      name: 'Dzisiaj',
      from: this.getDateString(new Date()),
      to: this.getDateString(new Date()),
    },
    {
      name: 'Wczoraj',
      from: this.getDateString(new Date(new Date().setDate(new Date().getDate() - 1))),
      to: this.getDateString(new Date(new Date().setDate(new Date().getDate() - 1))),
    },
    {
      name: 'Ten tydzień',
      from: this.getDateString(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1))),
      to: this.getDateString(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7))),
    },
    {
      name: 'Zeszły tydzień',
      from: this.getDateString(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 6))),
      to: this.getDateString(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))),
    },
    {
      name: 'Ten miesiąc',
      from: this.getDateString(new Date(new Date().setDate(1))),
      to: this.getDateString(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)),
    },
    {
      name: 'Zeszły miesiąc',
      from: this.getDateString(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)),
      to: this.getDateString(new Date(new Date().getFullYear(), new Date().getMonth(), 0)),
    },
  ];

  sampleTests = [];

  getColWidth() {
    return ((this.$refs.contentBox as HTMLFormElement).clientWidth - 100) / this.cols.length - 10;
  }

  getColByStatusId(statusId: number): types.DashboardColumn {
    return this.cols.find(col => col.statusId === statusId);
  }

  get sampleTakeMethods() {
    return (sample: types.Sample) => {
      let str = '';
      sample['take_methods'].forEach(tm => {
        str += tm.value + ', ';
      });
      return str;
    };
  }

  get sampleTakePlaces() {
    return (sample: types.Sample) => {
      let str = '';
      sample['take_places'].forEach(tp => {
        str += tp.value + ', ';
      });
      return str;
    };
  }

  emitFilter() {
    this.$emit(consts.ListEvents.FILTER);
  }
  emitPageSize() {
    this.$emit(consts.ListEvents.PAGE_SIZE, this.pageSize);
  }

  async fillColsItems() {
    const processedFilterParam = {};
    for (const [key, value] of Object.entries(this.filterObject)) {
      if (key === 'META') {
        continue;
      }
      let newKey = key;
      if (typeof value === typeof '') {
        newKey += '__icontains';
      }
      processedFilterParam[newKey] = value;
    }
    let filteredSamples = [];
    const response = await API.fetchFilterdModel('Sample', processedFilterParam, { field: 'id', dir: 1 }, 1, 2000);
    filteredSamples = response.data.objs;

    let tmpSamples = [];
    const tmpResponse = await API.fetchSamples();
    if (tmpResponse.data.success) {
      tmpSamples = tmpResponse.data.samples;
    }

    const samples = [];
    for (const filteredSample of filteredSamples) {
      samples.push(tmpSamples.find(tmpSample => filteredSample.id === tmpSample.id));
    }

    this.cols = this.cols.map(col => {
      col.items = [];
      samples
        .filter(s => s.status === col.statusId)
        .forEach((sample, index) => {
          sample.index = index;
          sample.columnStatus = col.statusId;
          this.$set(col.items, index, sample);
        });
      return col as types.DashboardColumn;
    });
    console.log('This cols', this.cols);
  }

  sortColumn(index) {
    this.cols = this.cols.map(col => {
      if (col.index == index) {
        const samples = col.items.reverse();
        col.items = [];
        samples.forEach((sample, index) => {
          sample.index = index;
          this.$set(col.items, index, sample);
        });
      }
      return col as types.DashboardColumn;
    });
  }

  change(event) {
    console.log('change');
  }
  move(event) {
    console.log('move', event);
    return true;
  }
  add(event) {
    console.log('add', event);
  }
  startDrag(event: any) {
    console.log('startDrag', event);
  }
  async endDrag(event: any) {
    console.log('endDrag', event, event.oldIndex, event.from.id, ' => ', event.to.id, event.newIndex);
    if (event.from.id !== event.to.id) {
      const oldCol: types.DashboardColumn = this.getColByStatusId(parseInt(event.from.id));
      console.log('OLD COL:', oldCol, oldCol.items);
      const newCol: types.DashboardColumn = this.getColByStatusId(parseInt(event.to.id));
      console.log('NEW COL:', newCol, newCol.items);

      this.lastMove = {
        oldIndex: event.oldIndex,
        oldStatusId: parseInt(event.from.id),
        oldStatus: oldCol.status,
        newStatusId: parseInt(event.to.id),
        newIndex: event.newIndex,
        newStatus: newCol.status,
      };

      const newItem: any = newCol.items[parseInt(event.newIndex)];
      if (oldCol.index === 0 && newCol.index === 1) {
        await this.samplePrepareModal({ sample: newItem as types.Sample, lastMove: this.lastMove });
      } else {
        newItem.status_id = newCol.statusId;
        console.log('New ITEM', newItem);
        API.updateSampleStatus(newItem.id, newCol.statusId);
        //this.modalAction.showModal({ item: newItem, lastMove: this.lastMove });
        console.log('showModal');
        this.cInvalidate++;
      }
    }
  }
  get dragOptions() {
    return {
      animation: 150,
      disabled: false,
      ghostClass: 'ghost',
      handle: '.tile-handle',
    };
  }
  sampleModal(sample: types.Sample) {
    this.modalSample.showModal(sample);
  }
  modalSampleOK(params: object) {
    console.log('modalSampleOK', params);
  }

  sampleEditModal(sample: types.Sample) {
    this.modalSampleEdit.showModal(sample);
  }
  modalSampleEditOK(params: object) {
    console.log('modalSampleEditOK', params);
  }
  async samplePrepareModal(params: { sample: types.Sample; lastMove: any }) {
    console.log('samplePrepareModal', params);
    await this.modalSamplePrepare.showModal(params);
  }
  modalSamplePrepareOK(params: { sample: types.Sample; lastMove: any }) {
    console.log('modalSamplePrepareOK', params);
    params.sample.status_id = params.lastMove.newStatusId;
    console.log('New ITEM', params.sample);
    API.updateSampleStatus(params.sample.id, params.sample.status_id);
    //this.modalAction.showModal({ item: newItem, lastMove: this.lastMove });
    console.log('showModal');
    this.cInvalidate++;
  }
  modalSamplePrepareCancel(params: { sample: types.Sample; lastMove: any }) {
    console.log('modalSamplePrepareCancel', params);
    if (!params['save']) {
      const newCol: types.DashboardColumn = this.getColByStatusId(this.lastMove.newStatusId);
      const oldCol: types.DashboardColumn = this.getColByStatusId(this.lastMove.oldStatusId);
      console.log('Revert to old col', this.lastMove.newIndex, newCol, this.lastMove);
      oldCol.items.splice(this.lastMove.oldIndex, 0, newCol.items[this.lastMove.newIndex]);
      oldCol.items.forEach((t, index) => (t.index = index));
      console.log('removing ', this.lastMove, newCol);
      newCol.items = newCol.items.filter(t => t.index !== this.lastMove.newIndex);
      newCol.items.forEach((t, index) => (t.index = index));
    }
  }
  colsSAMPLES() {
    this.cols = [
      new types.DashboardColumn(consts.SampleStatus.REGISTERED, 0),
      new types.DashboardColumn(consts.SampleStatus.IN_EXAM, 1),
      new types.DashboardColumn(consts.SampleStatus.DONE, 2, true),
      new types.DashboardColumn(consts.SampleStatus.CORRECT, 3),
    ];
  }
  examCardLink(id: number) {
    this.$router.push({
      path: 'lab/samplesboard/examcard/',
      name: 'examcard',
      params: {
        id: id.toString(),
      },
    });
  }
  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    this.clear();
  }
  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
    this.fillColsItems();
  }
  processLoaded(object: any) {
    object.status = consts.SampleStatus.byId(object.status);
  }
  async created() {
    console.log('%c*********************** Samples creating ***********************', 'color:red');
    this.colsSAMPLES();
    console.log('%c*********************** Samples created ***********************', 'color:red');
    this.listParams = [
      {
        headerName: 'NUMER',
        value: '$number',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'DATA REJESTRACJI',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
        sortField: 'create_date_time',
        value: (obj: any) => {
          const parsed = moment(obj.create_date_time, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
          return parsed;
        },
      },
      {
        headerName: 'OBIEKT',
        value: '$sample_object__value',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'POCHODZENIE',
        value: '$source__value',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'TYP OBIEKTU',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
        value: '$object_type__value',
      },
      {
        headerName: 'OBIEKT',
        value: '$client__name',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        fieldType: 'badge',
        value: '$status__name',
        sortField: 'status',
        badgeVariant: '$status__badge',
        headerName: 'STATUS',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'OPERACJE',

        fieldType: 'ntexts',
        href: {
          getValues: (obj: any) => {
            if (!obj) return;
            return [
              { text: 'Edytuj', css: 'font-12 clickable-text', oper: obj },
              { text: 'Karta badań', css: 'font-12 clickable-text', oper: obj },
            ];
          },
          click: (obj: any, href: any) => {
            if (href.text === 'Edytuj') {
              console.log(obj, href, 'METODA', this);
              this.sampleEditModal(obj);
            } else {
              this.$router.push({
                path: 'lab/samplesboard/examcard/',
                name: 'examcard',
                params: {
                  id: obj.id.toString(),
                },
              });
            }
          },

          // (obj: any, href: any) => {
          //   console.log(obj, href, 'METODA');
        },
        value: '',
      },
    ];

    this.listFilterParams = [
      {
        fieldType: 'multiselect',
        value: '$sample_object_id',
        headerName: 'Obiekt',
        action: (e: any, obj: any) => {
          obj.sample_object_id = e.id;
        },
        options: await helpers.getDictAsArray(1),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$source_id',
        headerName: 'Pochodzenie',
        action: (e: any, obj: any) => {
          obj.source_id = e.id;
        },
        options: await helpers.getDictAsArray(6),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$object_type_id',
        headerName: 'Typ obiektu',
        action: (e: any, obj: any) => {
          obj.object_type_id = e.id;
        },
        options: await helpers.getDictAsArray(4),
        multiSelectTrack: 'value',
      },
      {
        headerName: 'Status',
        fieldType: 'multiselect',
        options: consts.SampleStatus.array.map(el => {
          return { id: el.id, name: el.name };
        }),
        fieldName: 'status',
        multiSelectTrack: 'name',
        action: (e: any, object: any) => {
          object.status = e.id;
        },
      },
      {
        fieldType: 'multiselect',
        value: '$take_method_id',
        headerName: 'Metoda pobrania',
        action: (e: any, obj: any) => {
          obj.take_method_id = e.id;
        },
        options: await helpers.getDictAsArray(7),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$area_test_id',
        headerName: 'Obszar',
        action: (e: any, obj: any) => {
          obj.area_test_id = e.id;
        },
        options: await helpers.getDictAsArray(11),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        headerName: 'Sekcja testów',
        action: (e: any, obj: any) => {
          const testsFromSection = this.sampleTests.filter(test => test.section_id === e.id);
          const allowedIds = testsFromSection.map(test => test.sample_id);
          obj.id__in = allowedIds;
        },
        options: await helpers.getDictAsArray(3),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'input',
        fieldName: 'number',
        headerName: 'Numer',
      },
      {
        fieldType: 'multiselect',
        headerName: 'Data rejestracji',
        action: (e: any, object: any) => {
          const dtFrom = moment(e.from, consts.DATE_FORMAT);
          object.create_date_time__gte = dtFrom.format(consts.DB_DATE_FORMAT);
          object.date_rej_from = undefined;

          const dtTo = moment(e.to, consts.DATE_FORMAT);
          object.create_date_time__lte = dtTo.format(consts.DB_DATE_FORMAT);
          object.date_rej_to = undefined;

          const els = this.getDateInputEls();
          els[0].value = e.from;
          els[1].value = e.to;
        },
        multiSelectTrack: 'name',
        options: this.dateSelectOptions,
      },
      {
        fieldType: 'date',
        value: 'date_rej_from',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.create_date_time__gte = dt.format(consts.DB_DATE_FORMAT);
          object.date_rej_from = undefined;
        },
        headerName: 'Data rejestracji OD',
      },
      {
        fieldType: 'date',
        value: 'date_rej_to',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.create_date_time__lte = dt.format(consts.DB_DATE_FORMAT);
          object.date_rej_to = undefined;
        },
        headerName: 'Data rejestracji DO',
      },
      {
        fieldType: 'multiselect',
        headerName: 'Data pobrania',
        action: (e: any, object: any) => {
          const dtFrom = moment(e.from, consts.DATE_FORMAT);
          object.sample_dates__date_from__gte = dtFrom.format(consts.DB_DATE_FORMAT);
          object.date_from = undefined;

          const dtTo = moment(e.to, consts.DATE_FORMAT);
          object.sample_dates__date_to__lte = dtTo.format(consts.DB_DATE_FORMAT);
          object.date_to = undefined;

          const els = this.getDateInputEls();
          els[2].value = e.from;
          els[3].value = e.to;
        },
        multiSelectTrack: 'name',
        options: this.dateSelectOptions,
      },
      {
        fieldType: 'date',
        value: 'date_from',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.sample_dates__date_from__gte = dt.format(consts.DB_DATE_FORMAT);
          object.date_from = undefined;
        },
        headerName: 'Data pobrania OD',
      },
      {
        fieldType: 'date',
        value: 'date_to',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.sample_dates__date_to__lte = dt.format(consts.DB_DATE_FORMAT);
          object.date_to = undefined;
        },
        headerName: 'Data pobrania DO',
      },
    ];
  }
  async mounted() {
    this.colWitdth = this.getColWidth() + 'px';
    this.fillColsItems();
    this.getSampleTests();

    if (Object.keys(this.$route.query).length > 0) {
      this.listView = true;
      setTimeout(() => {
        this.filterObject = { ...this.$route.query };
      }, 1000);
    }
  }
  get start() {
    console.log('%c---------- Start of Samples template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of Samples template -----------', 'color:green;font-weight:600');
    return '';
  }
  getDateString(date: Date) {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }
  getDateInputEls() {
    const parent = document.getElementById('bv-modal-method___BV_modal_body_').children[0];
    const els = [];
    for (let i = 8; i <= 12; i++) {
      if (i == 10) {
        i++;
      }
      els.push(parent.children[i].children[0].children[0].children[1].children[0]);
    }
    return els;
  }
  async getSampleTests() {
    const response = await API.fetchSampleTests();
    this.sampleTests = response.data.sample_tests;
  }
}
