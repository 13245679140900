var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-3"},[_c('date-input',{ref:"postingDateInput",staticClass:"col-6",attrs:{"title":'Data nadania',"date":_vm.postingDateSync},on:{"date_changed":function (value) {
        _vm.postingDateSync = _vm.datetimeToDate(value);
        _vm.$forceUpdate();
      }}}),_c('div',{staticClass:"col-6"},[_c('b-form-group',{attrs:{"label":"Nazwa kopalni:","label-for":"input-mine-name"}},[_c('b-input',{staticClass:"input-border",attrs:{"placeholder":"<wpisz>"},model:{value:(_vm.mineNameSync),callback:function ($$v) {_vm.mineNameSync=$$v},expression:"mineNameSync"}})],1)],1),_c('date-input',{ref:"arrivalDateInput",staticClass:"col-6 mt-2",attrs:{"title":'Data przybycia',"date":_vm.arrivalDatetimeSync},on:{"date_changed":function (value) {
        _vm.arrivalDate = _vm.datetimeToDate(value);
        _vm.setArrivalDateTime();
        _vm.$forceUpdate();
      }}}),_c('time-input',{ref:"arrivalTimeInput",staticClass:"col-6 mt-2",attrs:{"title":'Godzina przybycia',"enabled":true,"time":_vm.arrivalDatetimeSync},on:{"time_changed":function (value) {
        this$1.arrivalTime = value;
        _vm.setArrivalDateTime();
      }}}),_c('div',{staticClass:"col-6 mt-2"},[_c('b-form-group',{attrs:{"label":"Klasa deklarowana:","label-for":"input-declared-class"}},[_c('b-input',{staticClass:"input-border",attrs:{"placeholder":"<wpisz>"},model:{value:(_vm.declaredClassSync),callback:function ($$v) {_vm.declaredClassSync=$$v},expression:"declaredClassSync"}})],1)],1),_c('div',{staticClass:"col-6 mt-2"},[_c('b-form-group',{attrs:{"label":"Numer wagonu:","label-for":"input-wagon-number"}},[_c('b-input',{staticClass:"input-border",attrs:{"placeholder":"<wpisz>"},model:{value:(_vm.wagonNumberSync),callback:function ($$v) {_vm.wagonNumberSync=$$v},expression:"wagonNumberSync"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }