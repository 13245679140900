import * as API from '@/store/api';
import { assert, expect } from 'chai';
import * as types from '@/types';
import * as consts from '@/consts';
import { AxiosResponse } from 'axios';

const orderJSON = {
  client_id: 3589,
  client_name: 'Klient numer 1',
  client_type: 1,
  client_address_id: 1205,
  contract_id: 1,
  contract_name: '5/12/2020',
  contract_number: '',
  cplace_id: 76,
  cplace_name: 'Budowa 1',
  id: 0,
  status_id: 0,
};

export async function createNewAddress() {
  const addr = new types.Address();
  addr.city = 'Krakow';
  addr.postal_code = '31-456';
  addr.correspond = 'Adres korespondencyjny';
  addr.street_and_house_no = 'Ugorek 12/35';

  const response = await API.saveAddress(addr);
  return Object.assign(new types.Address(), response.data.address);
}

export async function deleteAddress(address: types.Address) {
  await API.deleteAddress(address);
}

export async function createNewClient() {
  const client = new types.Client();
  client.active = false;
  client.name = 'Firma Bud1';

  const address = new types.Address();
  address.city = 'Krakow';
  address.postal_code = '31-456';
  address.correspond = 'Adres korespondencyjny';
  address.street_and_house_no = 'Ugorek 12/35';
  client.address = address;

  client.code = '50000';
  client.nip = '945-199-79-97';
  client.contact_email = 'a@b.pl';
  client.contact_person_name = 'Piotr';
  client.contact_phone = '606-461-855';
  client.correspond_address = '';

  const response = await API.saveClient(client);
  if (!response.data.success) {
    console.log('Error while creating client', response.data);
  }
  return Object.assign(new types.Client(), response.data.client);
}

export async function deleteClient(client: types.Client) {
  client.active = false;
  await API.deleteClient(client);
}
export async function deleteClientByCode(code: string) {
  await API.deleteClientByCode(code);
}
export function generateTemporaryId() {
  return Math.round(Math.random() * 1000000 + 1000000);
}
