


























































































































































































































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import * as API from '../../../store/api';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import GenericField from '../../../components/spec/GenericField.vue';
import Modal from '../../../components/spec/Modal.vue';
import { capitalizeFirstLetter } from '../../../helpers';
import { ExtendedParam, Dict, DictToSave, Structure, StandardMultiselectEngine } from '../../../types';
import { DictType, DictTypeTitles } from '../../../consts';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    GenericField,
    Header,
    Modal,
    Footer,
  },
})
export default class DictList extends Vue {
  @Ref() readonly modalFilter: Modal;
  params = [];
  @Ref() readonly modal: Modal;
  modalParams = new Array<ExtendedParam>();
  show = true;
  object = this.defaultObject;
  filterObject = {};
  copyFilterObject = {};
  url = '';
  filterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;
  @Ref() relationForm: GenericForm;
  listKey = 0;
  dictsArray: Array<Dict> = [];
  structuresArray: Array<Structure> = [];
  relationTypes = [DictType.OBJECT, DictType.SOURCE, DictType.OBJECT_TYPE, DictType.TAKE_METHOD];
  relationsObject: Structure = this.defaultRelationsObject;
  relationParams: Array<ExtendedParam> = [];
  blankDict: Dict;
  loaded = false;
  dicts = [];
  isEditing = false;
  visibleObject = false;
  visibleSource = false;
  visibleObjectType = false;
  visibleTakeMethod = false;
  visibleTakePlace = false;
  multiObject = false;
  multiSource = false;
  multiObjectType = false;
  multiTakeMethod = false;
  multiTakePlace = false;

  areaKey = 0;
  objectKey = 0;
  structure = [];
  relatedStructure = [];
  structureObject = {
    parents: [], // separate children array for each parent
    children: [],
    object: {},
    area_test: {},
    prepare_norm: {},
    recalculate_norm: {},
  };
  get emptyStructureObject() {
    return {
      parents: [],
      children: [],
      object: this.object,
      area_test: {},
      prepare_norm: {},
      recalculate_norm: {},
    };
  }
  get dictTypes() {
    return DictType;
  }

  selectedObject: any = {};
  objects = [];
  selectObject(e: any) {
    const p = this.structureObject.parents.find(p => p.id === e.id);
    if (p) {
      this.selectedObject = p;
      this.selectedObjectTypeList = this.structureObject.children[this.structureObject.parents.indexOf(p)];
    }
    this.modal.$forceUpdate();
  }
  /***** SOURCES *****/
  sources = [];
  selectedSource: Dict = this.defaultObject;
  selectedSourceList = [];
  selectSource(e: any) {
    if (this.multiSource) {
      this.selectedSourceList.push(e);
    } else {
      const p = this.structureObject.parents.find(p => p.id === e.id);
      if (p) {
        this.selectedSource = p;
        this.selectedTakeMethodList = this.structureObject.children[this.structureObject.parents.indexOf(p)];
      }
    }
  }
  removeSource(e: any) {
    this.selectedSourceList = this.selectedSourceList.filter(s => s.id != e.id);
  }

  /**** OBJECT TYPES ****/
  objectTypes = [];
  selectedObjectType: any = {};
  selectedObjectTypeList = [];
  selectObjectType(e: any) {
    if (this.multiObjectType) {
      this.selectedObjectTypeList.push(e);
      const parentIndex = this.structureObject.parents.findIndex(p => p.id === this.selectedObject.id);
      if (parentIndex >= 0) {
        this.structureObject.children[parentIndex] = this.selectedObjectTypeList;
      }
    } else {
      const p = this.structureObject.parents.find(p => p.id === e.id);
      if (p) {
        this.selectedObjectType = p;
        this.selectedTakePlaceList = this.structureObject.children[this.structureObject.parents.indexOf(p)];
      }
    }
  }
  removeObjectType(e: any) {
    this.selectedObjectTypeList = this.selectedObjectTypeList.filter(s => s.id != e.id);
    const parentIndex = this.structureObject.parents.findIndex(p => p.id === this.selectedObject.id);
    if (parentIndex >= 0) {
      this.structureObject.children[parentIndex] = this.selectedObjectTypeList;
    }
  }
  /********************* TAKE METHOD **********************/
  takeMethods = [];
  selectedTakeMethod = {};
  selectedTakeMethodList = [];
  selectTakeMethod(e: any) {
    if (this.multiTakeMethod) {
      this.selectedTakeMethodList.push(e);
      const parentIndex = this.structureObject.parents.findIndex(p => p.id === this.selectedSource.id);
      if (parentIndex >= 0) {
        this.structureObject.children[parentIndex] = this.selectedTakeMethodList;
      }
    } else {
      const p = this.structureObject.parents.find(p => p.id === e.id);
      if (p) {
        this.selectedTakeMethod = p;
      }
    }
  }
  removeTakeMethod(e: any) {
    this.selectedTakeMethodList = this.selectedTakeMethodList.filter(s => s.id != e.id);
    const parentIndex = this.structureObject.parents.findIndex(p => p.id === this.selectedSource.id);
    if (parentIndex >= 0) {
      this.structureObject.children[parentIndex] = this.selectedTakeMethodList;
    }
  }

  /********************* TAKE PLACE **********************/
  takePlaces = [];
  selectedTakePlaceList = [];
  selectTakePlace(e: any) {
    this.selectedTakePlaceList.push(e);
    const parentIndex = this.structureObject.parents.findIndex(p => p.id === this.selectedObjectType.id);
    if (parentIndex >= 0) {
      this.structureObject.children[parentIndex] = this.selectedTakePlaceList;
    }
  }
  removeTakePlace(e: any) {
    this.selectedTakePlaceList = this.selectedTakePlaceList.filter(s => s.id != e.id);
    const parentIndex = this.structureObject.parents.findIndex(p => p.id === this.selectedObjectType.id);
    if (parentIndex >= 0) {
      this.structureObject.children[parentIndex] = this.selectedTakePlaceList;
    }
  }
  /********************* AREA **********************/
  selectedArea = {};
  areas = [];
  get areaValue() {
    return this.selectedArea;
  }
  selectArea(e: KeyboardEvent) {
    this.selectedArea = e;
  }
  fillAreas() {
    this.areas = this.dicts.filter(d => d.type === DictType.AREA);
  }
  /********************* NORMS **********************/
  prepareNorms = [];
  recalculateNorms = [];
  fillNorms() {
    this.prepareNorms = this.dicts.filter(d => d.type === DictType.PREPARE_NORM);
    this.recalculateNorms = this.dicts.filter(d => d.type === DictType.CALC_NORM);
    this.selectPrepareNorm(this.prepareNorms[0]);
    this.selectRecalculateNorm(this.recalculateNorms[0]);
  }
  selectedPrepareNorm = {};
  prepareNormKey = 0;

  get prepareNormValue() {
    return this.selectedPrepareNorm;
  }
  selectPrepareNorm(e: KeyboardEvent) {
    this.selectedPrepareNorm = e;
  }

  selectedRecalculateNorm = {};
  recalculateNormKey = 0;

  get recalculateNormValue() {
    return this.selectedRecalculateNorm;
  }
  selectRecalculateNorm(e: KeyboardEvent) {
    this.selectedRecalculateNorm = e;
  }

  async loadDicts() {
    const response = await API.fetchDicts();
    this.dicts = response.data.dicts;
  }

  async loadStructure() {
    const response = await API.fetchModel('Structure');
    this.structure = response.data.objs;
  }

  get listTileSuffix() {
    if (this.dictType !== 0) {
      return ' - ' + DictTypeTitles[this.dictType];
    }
    return '';
  }

  get defaultRelationsObject(): Structure {
    return {
      id: null,
      object_id: null,
      source_id: null,
      object_type_id: null,
      take_method_id: null,
      take_place_id: null,
      prepare_norm_id: null,
      recalculate_norm_id: null,
      area_test_id: null,
    };
  }

  get defaultObject(): Dict {
    return {
      id: 0,
      type: 0,
      value: '',
      active: false,
      create_date_time: '',
      changed_date_time: '',
    };
  }

  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }

  processLoadedElement(object: any) {
    // console.log(object);
  }

  clearSelected() {
    this.selectedObject = {};
    this.selectedSource = this.defaultObject;
    this.selectedSourceList = [];
    this.selectedObjectType = {};
    this.selectedObjectTypeList = [];
    this.selectedTakeMethod = {};
    this.selectedTakeMethodList = [];
    this.selectedTakePlaceList = [];
  }

  parseStructure() {
    this.relatedStructure = this.structure.filter(s => {
      for (const el in s) {
        if (s[el] === this.object.id && el !== 'id') {
          return true;
        }
      }
      return false;
    });
    if (this.object.type === DictType.OBJECT) {
      for (const s of this.relatedStructure) {
        if (!this.selectedSourceList.some(sour => sour.id === s.source.id)) {
          this.selectedSourceList.push(s.source);
        }
      }
    }
    if (this.object.type === DictType.SOURCE) {
      this.parseType('source', 'object', 'object', 'object_type');
    }
    if (this.object.type === DictType.OBJECT_TYPE) {
      this.parseType('objectType', 'source', 'source', 'take_method');
    }
    if (this.object.type === DictType.TAKE_METHOD) {
      this.parseType('takeMethod', 'objectType', 'object_type', 'take_place');
    }
  }

  parseType(dictType: string, parentType: string, parentTypeCamel: string, childTypeCamel: string) {
    // SOURCE    parent = object  dict = source  child = object_type
    for (const s of this.relatedStructure) {
      if (!this.structureObject.parents.some(p => p.id === s[parentTypeCamel + '_id'])) {
        //  adding parents
        this.structureObject.parents.push(s[parentTypeCamel]);
        this.structureObject.children.push([]);
      }

      const pIndex = this.structureObject.parents.findIndex(p => p.id === s[parentTypeCamel].id);

      if (
        !this.structureObject.children[pIndex].some(c => c.id === s[childTypeCamel + '_id']) &&
        s[childTypeCamel + '_id'] > 0
      ) {
        this.structureObject.children[pIndex].push(s[childTypeCamel]);
      }
      if (dictType === 'source') {
        if (s.area_test_id) {
          this.selectArea(s.area_test);
        }
        if (s.prepare_norm_id) {
          this.selectPrepareNorm(s.prepare_norm);
        }
        if (s.recalculate_norm_id) {
          this.selectRecalculateNorm(s.recalculate_norm);
        }
      }
    }
    this[parentType + 's'] = this.structureObject.parents;
    this['select' + capitalizeFirstLetter(parentType)](this[parentType + 's'][0]);
  }

  showDictModal(object: any = {}) {
    this.structureObject = this.emptyStructureObject;
    this.clearSelected();
    if (object.id > 0) {
      this.isEditing = true;
      this.object = { ...object };
      this.parseStructure();
    } else {
      this.isEditing = false;
      this.object = { ...this.defaultObject };
    }
    this.disableAll();
    this.toggleAll(object.type);
    console.log('SHOW MODAL');
    this.modalParams[0].disabled = this.isEditing;
    this.modal.showModal();
    this.modal.$forceUpdate();
  }
  cancelModal() {
    console.log('CANCEL MODAL');
  }
  async saveModal(e: any) {
    this.structureObject.object = this.object;
    if (this.relationTypes.includes(this.object.type)) {
      if (this.object.type === DictType.OBJECT) {
        this.structureObject.children = this.selectedSourceList;
      }
      if (this.object.type === DictType.SOURCE) {
        this.structureObject.area_test = this.selectedArea;
        this.structureObject.prepare_norm = this.selectedPrepareNorm;
        this.structureObject.recalculate_norm = this.selectedRecalculateNorm;
      }
    }
    console.log('SAVE:', this.structureObject);
    await API.saveDictWithStructure(this.structureObject);
    await this.setup();
    //API.saveDict(this.object, this.relationsObject);
  }

  clear() {
    this.copyFilterObject = {};
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  sendFilterQuery(e: any) {
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  parseRouteParams() {
    if (this.$route.params.type !== undefined) {
      this.dictType = parseInt(this.$route.params.type);
      this.filterObject['type'] = this.dictType;
    } else {
      this.dictType = 0;
      delete this.filterObject['type'];
    }
  }

  get dictArray() {
    // array for generic params
    const arr = [];
    for (let i = 1; i < DictTypeTitles.length; i++) {
      arr.push({ name: DictTypeTitles[i], id: i });
    }
    return arr;
  }

  async setup() {
    this.parseRouteParams();
    await this.loadDicts();
    await this.loadStructure();
    this.objects = this.dicts.filter(d => d.type === DictType.OBJECT);
    this.sources = this.dicts.filter(d => d.type === DictType.SOURCE);
    this.objectTypes = this.dicts.filter(d => d.type === DictType.OBJECT_TYPE);
    this.takeMethods = this.dicts.filter(d => d.type === DictType.TAKE_METHOD);
    this.takePlaces = this.dicts.filter(d => d.type === DictType.TAKE_PLACE);
    this.fillAreas();
    this.fillNorms();
  }
  dictType = 0;
  async created() {
    await this.setup();

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'value',
        headerName: 'Wartość główna',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: this.dictArray,
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('type', 'id'),
        headerName: 'Typ słownika',
      },
    ];

    this.params = [
      {
        fieldType: 'badge',
        badgeVariant: (object, param) => {
          const id = object.type - 1;
          const variants = [
            'success',
            'info',
            'secondary',
            'danger',
            'warning',
            'primary',
            'primary',
            'light',
            'danger',
            'success',
          ];
          return variants[id];
        },
        value: (object, param) => {
          const id = object.type;
          const texts = DictTypeTitles;
          return texts[id];
        },
        headerName: 'RODZAJ SŁOWNIKA',
        sortField: 'type',
        columnCss: 'col-2 p-0 font-16',
        headerCss: 'col-2 p-0',
      },
      {
        value: '$value',
        headerName: 'WARTOSC',
        columnCss: 'col-5 p-0 text-left font-16',
        headerCss: 'col-5 p-0 text-center',
      },
      {
        value: '$symbol',
        headerName: 'SYMBOL',
        columnCss: 'col-2 p-0 text-center font-16',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'boolean',
        value: 'active',
        columnCss: 'col-1 p-0 text-center font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'Operacje',
        fieldType: 'rec',
        hideHeader: true,
        childParams: [
          {
            hideHeader: true,
            value: 'Edycja',
            css: 'clickable-text font-12',
          },
          {
            hideHeader: true,
            value: 'Usuń',
            css: 'clickable-text font-12',
            action: async (obj: any) => {
              await API.deleteObjectModelById(obj.id, 'Dict');
              await this.setup();
              this.$forceUpdate();
            },
          },
        ],
      },
    ];

    this.modalParams = [
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: this.dictArray,
        multiSelectTrack: 'name',
        disabled: this.isEditing,
        value: (object, param) => {
          const id = object.type;
          const texts = DictTypeTitles;
          return texts[id];
        },
        action: (e, object) => {
          object.type = e.id;
          this.objects = this.dicts.filter(d => d.type === DictType.OBJECT);
          this.sources = this.dicts.filter(d => d.type === DictType.SOURCE);
          this.objectTypes = this.dicts.filter(d => d.type === DictType.OBJECT_TYPE);
          this.takeMethods = this.dicts.filter(d => d.type === DictType.TAKE_METHOD);
          this.takePlaces = this.dicts.filter(d => d.type === DictType.TAKE_PLACE);
          this.$forceUpdate();
        },
        load: (object, param) => {
          return { id: object.type, name: DictTypeTitles[object.type] };
        },
        headerName: 'Typ słownika',
      },
      {
        headerName: 'Wartość główna słownika',
        fieldType: 'input',
        fieldName: 'value',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Symbol',
        fieldType: 'input',
        fieldName: 'symbol',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Wartość dodatkowa',
        fieldType: 'input',
        fieldName: 'value2',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'checkbox',
        fieldName: 'active',
        mainCss: 'mt-2',
      },
    ];

    API.fetchDicts().then(res => {
      this.dictsArray = [...res.data.dicts];

      for (const dict of this.dictsArray) {
        if (dict.value === '-') {
          this.blankDict = { ...dict };
        }
      }

      API.fetchStructures().then(res => {
        this.structuresArray = [...res.data.structures];

        if (this.dictsArray && this.structuresArray) {
          this.loaded = true;
        }
      });
    });
  }
  disableAll() {
    this.visibleObject = false;
    this.visibleSource = false;
    this.visibleObjectType = false;
    this.visibleTakeMethod = false;
    this.visibleTakePlace = false;
    this.multiObject = false;
    this.multiSource = false;
    this.multiObjectType = false;
    this.multiTakeMethod = false;
    this.multiTakePlace = false;
  }
  toggleAll(newVal) {
    if (newVal === DictType.OBJECT) {
      this.visibleSource = true;
      this.multiSource = true;
    }
    if (newVal === DictType.SOURCE) {
      this.visibleObject = true;
      this.multiObject = false;
      this.visibleObjectType = true;
      this.multiObjectType = true;
    }
    if (newVal === DictType.OBJECT_TYPE) {
      this.visibleSource = true;
      this.multiSource = false;
      this.visibleTakeMethod = true;
      this.multiTakeMethod = true;
    }
    if (newVal === DictType.TAKE_METHOD) {
      this.visibleObjectType = true;
      this.multiObjectType = false;
      this.visibleTakePlace = true;
      this.multiTakePlace = true;
    }
  }
  @Watch('$route')
  onRouteDictTypeChanged() {
    this.parseRouteParams();
    this.listKey++;
  }
  @Watch('object.type')
  inDictTypeChanged(newVal, old) {
    this.structureObject = this.emptyStructureObject;
    this.clearSelected();
    this.disableAll();
    this.toggleAll(newVal);
    this.parseStructure();
  }
}
