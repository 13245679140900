


























































import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import MinimalGenericList from '@/components/spec/MinimalGenericList.vue';
import GenericField from '@/components/spec/GenericField.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as helpers from '@/helpers';

@Component({
  components: {
    TopPanel,
    MinimalGenericList,
    GenericForm,
    GenericField,
    Header,
    Modal,
    Footer,
  },
})
export default class ModalSettings extends Vue {
  @Ref() minList: MinimalGenericList;
  @Ref() dateField: GenericField;
  buttonText = '+ Dodaj';

  show = false;
  settingsGroups = [];
  settingsGroupsUser = [];
  settingsGroupsGlobal = [];
  settingsGroupsAll = [];
  settings = [];
  userId = null;
  btnSaveKey = 1;

  async showModal() {
    this.show = true;
    await this.$forceUpdate();
  }

  hideModal() {
    this.show = false;
  }

  async saveModal() {
    let saveSettings = [];
    this.settingsGroupsAll.forEach(group => saveSettings.push(group.settings));
    saveSettings = [].concat(...saveSettings);
    console.log('saveSettings', saveSettings);
    const response = await API.saveSettings(saveSettings, this.userId);
    if (response.data.success) {
      helpers.info('Pomyślnie zapisano ustawienia');
    } else {
      helpers.error('Wystąpił błąd. Skontaktuj się z administratorem systemu');
      this.btnSaveKey++;
    }
    this.show = false;
  }

  async created() {
    const user = this.$store.state.currentUser;
    this.userId = user.id;
    const response = await API.fetchSettings();
    this.settings = response.data.settings;
    this.settings.forEach(setting => {
      if (!this.settingsGroups.includes(setting.group)) this.settingsGroups.push(setting.group);
    });
    console.log('settings', this.settings);
    this.settingsGroupsUser = this.settingsGroups.map(group => {
      return { name: group, settings: this.settings.filter(s => s.group === group && s.user_id === user.id) };
    });
    console.log('settingsGroupsUser', this.settingsGroupsUser);
    const userSettingsSymbols = this.settingsGroupsUser.flatMap(sg => sg.settings).map(s => s.symbol);
    const globalSettings = this.settings.filter(s => s.user_id === null && !userSettingsSymbols.includes(s.symbol));
    console.log('globalSettings', globalSettings);
    this.settingsGroupsGlobal = this.settingsGroups.map(group => {
      return { name: group, settings: globalSettings.filter(s => s.group === group) };
    });
    console.log('settingsgroupsGlobal', this.settingsGroupsGlobal);
    this.settingsGroupsAll = this.settingsGroupsUser.concat(this.settingsGroupsGlobal);
    this.settingsGroupsAll = this.settingsGroups.map(settingsGroup => {
      return {
        name: settingsGroup,
        settings: this.settingsGroupsAll.filter(sg => sg.name === settingsGroup).flatMap(sg => sg.settings),
      };
    });
    console.log('settingsgroupsAll', this.settingsGroupsAll);
  }
}
