





























































































import { Component, Vue, Ref } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import ExamCardHeader from '../../../components/spec/ExamCardHeader.vue';
import ModalResultCard from '../../../components/spec/ModalResultCard.vue';
import Footer from '../../../components/dummy/Footer.vue';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import * as API from '../../../store/api';
import * as consts from '../../../consts';

@Component({
  components: { TopPanel, ExamCardHeader, Footer, VueHtml2pdf, ModalResultCard },
})
export default class ExamCard extends Vue {
  @Ref() readonly protocolpdf: VueHtml2pdf;
  @Ref() readonly modalResultCard: ModalResultCard;

  currStageIndex = 1;
  exam_id = -1;
  exam = null;
  sample = null;
  tests = [];
  currentTest = null;
  changeKey = 0;
  sampleNorm = {};
  stateNorm = {};
  sampleNormId = null;
  stateNormId = null;
  sampleNormValue = '';
  stateNormValue = '';
  structures = [];
  dicts = [];
  source = null;
  enabled = true;

  get start() {
    console.log('%c---------- Start of Samples template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of Samples template -----------', 'color:green;font-weight:600');
    return '';
  }
  finalResult(test) {
    console.log('TEST finalResult', test);
    const finalParam = test.sampleTestParams.find(stp => stp.is_final_result);
    return finalParam;
  }
  isOneFinalResult(test) {
    return this.getTestFinalParams(test).length <= 1;
  }
  getTestFinalParams(test) {
    return test.sampleTestParams.filter(stp => {
      return stp.is_final_result;
    });
  }
  async resultCardShow(test: any) {
    test.dropped = false;
    this.currentTest = test;
    console.log('CURRTEST', this.currentTest);
    const params = new Object();
    params['currentTest'] = this.currentTest;
    this.modalResultCard.showModal(params);
  }
  async modalResultCardOK(params: any) {
    console.log('modalOK', params, params.savedTest, this.tests);
    const ind = this.tests.findIndex(t => t.id === params.savedTest.id);
    this.tests[ind] = params['savedTest'];
    console.log('HIDE OK', params['savedTest']);
    this.changeKey++;
    this.$forceUpdate();
  }
  async modalResultCardCancel(params: object) {
    console.log('modalCancel');
    const ind = this.tests.findIndex(t => t.id === params['savedTest'].id);
    this.tests[ind] = params['savedTest'];
    console.log('HIDE CANCEL', params['savedTest']);
    this.changeKey++;
    this.$forceUpdate();
  }
  dropRow(test) {
    const drp = test.dropped;
    test.dropped = !drp;
    this.tests.find(t => t.id === test.id).dropped = !drp;
    console.log('dropped', this.tests);
    console.log(test);
    this.$forceUpdate();
  }
  get status() {
    return consts.SampleStatus.byId(this.sample.status);
  }
  async created() {
    console.log('######### ID', this.$route.params['id']);
    const id = this.$route.params['id'];
    const response = await API.fetchSampleAndTests(parseInt(id));
    if (response.data.success) {
      this.sample = response.data.sample;
      this.tests = response.data.sample_tests;
      this.tests.forEach(t => {
        t.dropped = false;
      });
      this.currentTest = this.tests[0];
      console.log('################## SAMPLE', this.sample);
      console.log('################## TESTS', this.tests);
    }
    const responseDict = await API.fetchDicts();
    if (responseDict.data.success) {
      this.dicts = responseDict.data.dicts;
      this.source = this.dicts.filter(d => d.type === consts.DictType.SOURCE && d.value === this.sample.source);
    }
    const responseNormId = await API.fetchStructures();
    if (responseNormId.data.success) {
      this.structures = responseNormId.data.structures;
      this.sampleNormId = this.structures.filter(s => s.source_id === this.source[0].id)[0].prepare_norm_id;
      this.stateNormId = this.structures.filter(s => s.source_id === this.source[0].id)[0].recalculate_norm_id;
    }
    if (responseDict.data.success) {
      this.sampleNorm = this.dicts.filter(d => d.type === consts.DictType.PREPARE_NORM && d.id === this.sampleNormId);
      this.stateNorm = this.dicts.filter(d => d.type === consts.DictType.CALC_NORM && d.id === this.stateNormId);
      this.sampleNormValue = this.sampleNorm[0].value;
      this.stateNormValue = this.stateNorm[0].value;
    }
  }
}
