


























































import { Component, Vue, Ref, Watch, PropSync } from 'vue-property-decorator';
import * as API from '../../store/api';
import moment from 'moment';
import * as consts from '../../consts';
import * as types from '../../types';
import TimeInput from '../../components/dummy/TimeInput.vue';
import * as helpers from '../../helpers';
import DateInput from '../../components/dummy/DateInput.vue';

@Component({
  components: {
    DateInput,
    TimeInput,
  },
})
export default class RailDeliveryForm extends Vue {
  @PropSync('mineName', { type: String }) mineNameSync!: string;
  @PropSync('declaredClass', { type: String }) declaredClassSync!: string;
  @PropSync('wagonNumber', { type: String }) wagonNumberSync!: string;
  @PropSync('arrivalDatetime', { type: String }) arrivalDatetimeSync!: string;
  @PropSync('postingDate', { type: String }) postingDateSync!: string;
  @Ref() readonly arrivalDatetimeInput: DateInput;
  @Ref() readonly postingDateInput: DateInput;

  arrivalDate: string;
  arrivalTime: string;

  setArrivalDateTime() {
    this.arrivalDatetimeSync = `${this.arrivalDate} ${this.arrivalTime}`;
  }

  datetimeToDate(datetime: string) {
    const date = moment(datetime, consts.DB_DATE_TIME_FORMAT).format(consts.DB_DATE_FORMAT);
    return date;
  }

  reset() {
    this.arrivalDatetimeInput.reset();
    this.postingDateInput.reset();
    this.mineNameSync = '';
    this.declaredClassSync = '';
    this.postingDateSync = '';
    this.arrivalDatetimeSync = '';
    this.wagonNumberSync = '';
  }
}
