








































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import { EventBus, BusEvents } from '@/helpers/eventbus';
import { error } from '@/helpers';
import GenericForm from '@/components/spec/GenericForm.vue';
import * as consts from '../../consts';
import * as tools from '../../tests/model/tools';
import { DashboardItem, DashboardAction, User } from '../../types';
import * as API from '../../store/api';
import moment from 'moment';
import { Test } from 'mocha';
import { create, all } from 'mathjs';
const math = create(all);

@Component({
  components: {
    GenericForm,
  },
})
export default class SampleParamsEditor extends Vue {
  @Prop({ type: Object, required: true, default: null }) sample;
  @Prop({ type: Object, required: true, default: null }) testProp;
  @Prop({ type: Boolean, required: false, default: false }) useFinal;
  @Prop({ required: false, default: false }) isEditable;
  @Prop({ required: false, default: false }) methodForm;
  @Prop({ required: false, default: false }) resultCard;
  test = undefined;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;
  isEditing = false;
  isFinalEditing = false;
  sampleTestParamsTree = [];
  isEditingFinalParam = false;
  idArray = [];
  enabled = true;
  editedSampleTestParam = null;
  editedFinalParam = null;
  isEditingSampleTestParam = false;
  deletedParams = [];

  get sampleTestParamsTemplate() {
    return this.arrayFromTree().filter(stp => {
      return !stp.is_final_result;
    });
  }
  get finalParams() {
    if (!this.useFinal) return [];
    if (this.test.sampleTestParams.constructor === Array) {
      return this.test.sampleTestParams.filter(stp => {
        return stp.is_final_result;
      });
    } else {
      if (this.test.sampleTestParams.is_final_result) {
        return this.test.sampleTestParams;
      } else {
        return [];
      }
    }
  }
  setup() {
    this.test = this.testProp;
    console.log('setup test', this.test);
    if (this.methodForm === true) {
      if (this.test.test_params !== undefined) {
        if (this.test.test_params.constructor === Array) {
          this.test.sampleTestParams.push(...this.test.test_params);
        } else {
          this.test.sampleTestParams.push(this.test.test_params);
        }
      }
    }
    console.log('setup stp', this.test.sampleTestParams);
  }
  mounted() {
    EventBus.$on(BusEvents.CLEAR, () => {
      console.log('clear');
    });
  }
  controlLeftClick(stp) {
    stp.hover = false;
    function arr(paramArr) {
      paramArr.forEach(s => {
        s.level--;
        arr(s.children);
      });
    }
    if (stp.level > 0) {
      stp.level--;
      arr(stp.children);
      this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
      this.treeFromParams();
      if (stp.level === 0) {
        stp.parent_id = null;
        stp.index = this.sampleTestParamsTree.length;
        this.test.sampleTestParams.push(stp);
        this.sampleTestParamsTree.push(stp);
      } else {
        const parent = this.test.sampleTestParams.find(s => stp.parent_id === s.id);
        const parentParent = this.test.sampleTestParams.find(s => parent.parent_id === s.id);
        const levelParams = this.test.sampleTestParams.filter(
          s => stp.level === s.level && s.parent_id === parentParent.id
        );
        stp.parent_id = parentParent.id;
        console.log('PARENT PARENT', parentParent);
        stp.index = levelParams.length;
        this.test.sampleTestParams.push(stp);
        parentParent.children.push(stp);
      }
      console.log(stp.name, stp.level, stp.index);
      this.treeFromParams();
      this.sortTree();
      //this.$forceUpdate();
    }
  }
  controlRightClick(stp) {
    console.log('RIGHT CLICK PARAM', stp);
    console.log('RIGHT CLICK STPS', this.test.sampleTestParams);
    stp.hover = false;
    function arr(paramArr) {
      paramArr.forEach(s => {
        if (s.level < consts.MAX_LEVELS) {
          s.level++;
        }
        arr(s.children);
      });
    }
    function arrCheckIfTooManyLevels(paramArr) {
      paramArr.forEach(s => {
        if (s.level >= consts.MAX_LEVELS) {
          return false;
        }
        if (!arrCheckIfTooManyLevels(s.children)) {
          return false;
        }
      });
      return true;
    }
    if (stp.level < consts.MAX_LEVELS) {
      if (arrCheckIfTooManyLevels(stp.children)) {
        let parent = this.test.sampleTestParams.find(
          s => stp.parent_id === s.parent_id && stp.index - 1 === s.index && stp.is_final_result === s.is_final_result
        );
        if (parent === undefined) {
          parent = this.test.sampleTestParams.find(
            s => stp.parent_id === s.parent_id && stp.index - 2 === s.index && stp.is_final_result === s.is_final_result
          );
        }
        console.log('parent', parent);
        if (parent) {
          stp.level++;
          arr(stp.children);
          this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
          this.treeFromParams();
          const levelParams = this.test.sampleTestParams.filter(
            s => stp.level === s.level && s.parent_id === parent.id && stp.is_final_result === s.is_final_result
          );
          stp.parent_id = parent.id;
          console.log('PARENT PARENT', parent);
          stp.index = levelParams.length;
          this.test.sampleTestParams.push(stp);
          parent.children.push(stp);

          console.log(stp.name, stp.level, stp.index);
          this.treeFromParams();
          this.sortTree();
          console.log('RIGHT CLICK ARRAY', this.arrayFromTree());
          console.log('RIGHT CLICK ARRAY stp', this.test.sampleTestParams);
          //this.$forceUpdate();
        }
      }
    }
  }
  controlDownClick(stp) {
    this.sortTree();
    console.log('TREE', this.sampleTestParamsTree);
    const levelParams = this.test.sampleTestParams.filter(
      s => stp.level === s.level && s.parent_id === stp.parent_id && s.is_final_result === stp.is_final_result
    );
    console.log('LEVEL PARAMS', levelParams.length);
    if (stp.index < levelParams.length - 1) {
      const lower = this.test.sampleTestParams.find(
        s =>
          s.parent_id === stp.parent_id &&
          s.index === stp.index + 1 &&
          s.level === stp.level &&
          s.is_final_result === stp.is_final_result
      );
      stp.index++;
      lower.index--;
      stp.hover = false;
      this.sortTree();
      //this.$forceUpdate();
      console.log(stp.name, stp.level, stp.index);
      console.log('DOWN TO ARRAY tree', this.arrayFromTree());
      console.log('DOWN TO ARRAY stp', this.test.sampleTestParams);
    }
  }
  controlUpClick(stp) {
    this.sortTree();
    if (stp.index > 0) {
      const higher = this.test.sampleTestParams.find(
        s =>
          s.parent_id === stp.parent_id &&
          s.index === stp.index - 1 &&
          s.level === stp.level &&
          s.is_final_result === stp.is_final_result
      );
      console.log('HIGHER', higher);
      stp.index--;
      if (higher) {
        higher.index++;
      }
      stp.hover = false;
      this.sortTree();
      //this.$forceUpdate();
      console.log(stp.name, stp.level, stp.index);
      console.log('UP TO ARRAY tree', this.arrayFromTree());
      console.log('UP TO ARRAY stp', this.test.sampleTestParams);
    }
  }
  controlDeleteClick(stp) {
    function arr(context, param) {
      const paramArr = param.children;
      paramArr.forEach(s => {
        context.deletedParams.push(context.test.sampleTestParams.find(a => a.id === s.id));
        context.test.sampleTestParams = context.test.sampleTestParams.filter(a => a.id != s.id);
        arr(context, s);
      });
    }
    if (stp.is_final_result) {
      if (this.test.sampleTestParams.filter(p => p.is_final_result === true).length > 1) {
        this.deletedParams.push(this.test.sampleTestParams.find(a => a.id === stp.id));
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        for (let i = 0; i < this.finalParams.length; i++) {
          if (this.finalParams[i].id === stp.id) {
            this.finalParams.splice(i, 1);
          }
        }
        this.treeFromParams();
      } else {
        this.deletedParams.push(stp);
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        this.finalParams.shift();
        this.treeFromParams();
      }
    } else {
      if (this.test.sampleTestParams.filter(p => p.is_final_result === false).length > 1) {
        arr(this, stp);
        this.deletedParams.push(this.test.sampleTestParams.find(a => a.id === stp.id));
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        this.treeFromParams();
      } else {
        this.deletedParams.push(stp);
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        this.treeFromParams();
      }
    }
    this.test.deletedParams = this.deletedParams;
    console.log('deleted', this.test.deletedParams);
  }

  defaultChildAddRC = {
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: false,
  };

  defaultChildAddMF = {
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: false,
    id: tools.generateTemporaryId(),
    parent_id: null,
  };

  defaultChildAddFinalRC = {
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: true,
  };

  defaultChildAddFinalMF = {
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: true,
    id: tools.generateTemporaryId(),
    parent_id: null,
  };

  addTopParamRC() {
    const newObj = Object.assign({}, this.defaultChildAddRC);
    this.test.sampleTestParams.push(newObj);
    this.treeFromParams();
  }

  addTopParamMF() {
    const newObj = Object.assign({}, this.defaultChildAddMF);
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.forEach(s => this.idArray.push(s.id));
    } else {
      this.idArray.push(this.test.sampleTestParams.id);
    }
    if (this.idArray) {
      if (this.idArray.includes(newObj.id)) {
        newObj.id =
          Math.max(
            ...this.test.sampleTestParams.map(s => {
              return s.id;
            })
          ) + 1;
      }
    }
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.push(newObj);
    } else {
      this.test.sampleTestParams = [this.test.sampleTestParams, newObj];
    }
    this.treeFromParams();
  }

  addTopFinalParamRC() {
    const newObj = Object.assign({}, this.defaultChildAddFinalRC);
    this.finalParams.push(newObj);
    this.treeFromParams();
    this.test.sampleTestParams.push(newObj);
  }

  addTopFinalParamMF() {
    const newObj = Object.assign({}, this.defaultChildAddFinalMF);
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.forEach(s => this.idArray.push(s.id));
    } else {
      this.idArray.push(this.test.sampleTestParams.id);
    }
    if (this.idArray) {
      if (this.idArray.includes(newObj.id)) {
        newObj.id =
          Math.max(
            ...this.test.sampleTestParams.map(s => {
              return s.id;
            })
          ) + 1;
      }
    }
    this.finalParams.push(newObj);
    this.treeFromParams();
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.push(newObj);
    } else {
      this.test.sampleTestParams = [this.test.sampleTestParams, newObj];
    }
  }

  controlCopyClick(stp) {
    function arr(param, context) {
      const paramArr = param.children;
      paramArr.forEach(s => {
        s.id = tools.generateTemporaryId();
        s.parent_id = param.id;
        context.test.sampleTestParams.push(s);
        arr(s, context);
      });
    }
    stp.hover = false;
    if (stp.level != 0) {
      const parent = this.test.sampleTestParams.find(s => s.id === stp.parent_id);
      const coppied = JSON.parse(JSON.stringify(stp));

      parent.children
        .filter(s => s.index > coppied.index)
        .forEach(el => {
          el.index++;
        });
      coppied.index++;
      coppied.id = tools.generateTemporaryId();
      this.test.sampleTestParams.push(coppied);
      parent.children.push(coppied);
      arr(coppied, this);
    } else {
      const coppied = JSON.parse(JSON.stringify(stp));
      this.sampleTestParamsTree
        .filter(s => s.index > coppied.index)
        .forEach(el => {
          el.index++;
        });
      coppied.index++;
      coppied.id = tools.generateTemporaryId();
      if (coppied.is_final_result) {
        this.finalParams.push(coppied);
      } else {
        this.sampleTestParamsTree.push(coppied);
      }
      this.test.sampleTestParams.push(coppied);

      arr(coppied, this);
    }
    console.log('TEST PARAMS', this.test.sampleTestParams);
    this.sortTree();
    //this.$forceUpdate();
  }
  hoverOn(stp) {
    stp['hover'] = true;
    this.$forceUpdate();
  }
  hoverOff(stp) {
    stp['hover'] = false;
    this.$forceUpdate();
  }
  editParamClickSample(stp) {
    console.log('edit sample click', stp);
    this.isEditingSampleTestParam = true;
    this.editedSampleTestParam = stp;
  }
  editParamClickFinal(stp) {
    console.log('edit final click', stp);
    this.isEditingFinalParam = true;
    this.editedFinalParam = stp;
  }
  editParamStop() {
    this.isEditingSampleTestParam = false;
    this.isEditingFinalParam = false;
  }
  toggleEditing() {
    this.treeFromParams();
    this.isEditing = !this.isEditing;
  }
  toggleFinalEditing() {
    this.treeFromParams();
    this.isFinalEditing = !this.isFinalEditing;
  }

  arrayFromTree() {
    const a = [];
    function arr(paramArr) {
      paramArr.forEach(stp => {
        a.push(stp);
        arr(stp.children);
      });
    }
    arr(this.sampleTestParamsTree);
    const paramsArray = a;

    return paramsArray;
  }

  treeFromParams() {
    console.log('TREE FROM PARAMS start');
    this.sampleTestParamsTree = [];
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.forEach(param => {
        param.children = [];
      });
      for (let lvl = 0; lvl <= 5; lvl++) {
        const levelParams = this.test.sampleTestParams.filter(stp => stp.level === lvl && !stp.is_final_result);
        if (lvl === 0) {
          this.sampleTestParamsTree.push(...levelParams);
        } else {
          levelParams.forEach(levelParam => {
            const parent = this.test.sampleTestParams.find(stp => stp.id === levelParam.parent_id);
            if (parent) {
              parent.children.push(levelParam);
            }
          });
        }
      }
      console.log('TREE FROM PARAMS stpTree before sort', this.sampleTestParamsTree);
      this.sortTree();
    } else {
      if (!this.test.sampleTestParams.is_final_result) {
        this.sampleTestParamsTree.push(this.test.sampleTestParams);
        this.sortTree();
      }
    }
    console.log('TREE', this.sampleTestParamsTree);
  }
  sortTree() {
    function arr(paramArr) {
      if (paramArr.filter(s => s.index === 0).length != 1) {
        let ind = 0;
        paramArr.forEach(el => {
          el.index = ind;
          ind++;
        });
      }
      if (paramArr.length === 1) {
        paramArr[0].index = 0;
      }
      paramArr.forEach(stp => {
        stp.children.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index);
        });
        arr(stp.children);
      });
    }
    function arrIndexFix(paramArr) {
      let ind = 0;
      paramArr.forEach(el => {
        el.index = ind;
        ind++;
      });

      if (paramArr.length === 1) {
        paramArr[0].index = 0;
      }
      paramArr.forEach(stp => {
        stp.children.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index);
        });
        arr(stp.children);
      });
    }
    this.sampleTestParamsTree.sort((a, b) => {
      return parseInt(a.index) - parseInt(b.index);
    });
    arr(this.sampleTestParamsTree);
    arrIndexFix(this.sampleTestParamsTree);
    console.log('SORTED TREE:', this.test.sampleTestParams);
    this.update(null);
  }

  //  FORMULAS

  update(e: any, param: any = null, isFormula = false, isSymbol = false) {
    console.log(e);
    if (isSymbol) {
      if (String(e).match(/[$,.=+\-<>?:;*%]/g)) {
        error('Błędny zapis symbolu');
        param.symbol = '';
        return;
      }
    }
    if (isFormula) {
      if (String(e).match(/[$]/g) !== null) {
        error('Błędny zapis formuły - brak "$"');
        param.formula = '';
        return;
      }
    }

    if (e && this.resultCard === true) {
      if (math.hasNumericValue(e.toString().replace(',', '.')) === false && isSymbol === false && isFormula === false) {
        error('Błędny format danych!');
        return;
      }
      this.updateFormulas(e, param, isFormula, isSymbol);
    }
    this.$emit('update', this.test);
  }

  updateFormulas(e: any, param: any, isFormula: boolean, isSymbol: boolean) {
    console.log('update value', e, param);
    const paramsWithSymbol = this.test.sampleTestParams.filter(stpt => stpt.symbol != '');
    const paramsWithFormula = this.test.sampleTestParams.filter(stpt => stpt.formula != '');
    let paramsToUpdate = paramsWithFormula;
    console.log(
      'all to update',
      paramsToUpdate.map(ptu => ptu.formula)
    );
    if (isSymbol || param.symbol) {
      // eslint-disable-next-line
      const re = new RegExp('\\$' + param.symbol + '[^A-Za-z0-9_]', 'g');
      console.log('REGEX', re);
      paramsToUpdate = paramsToUpdate.filter(ptu => ptu.formula.match(re));
      console.log(
        'all to update SYMBOL',
        paramsToUpdate.map(ptu => ptu.formula)
      );
    } else if (isFormula) {
      paramsToUpdate = paramsToUpdate.filter(ptu => ptu.id === param.id);
      console.log(
        'all to update FORMULA',
        paramsToUpdate.map(ptu => ptu.formula)
      );
    } else {
      return;
    }

    const symbolObj = {};
    for (const pws of paramsWithSymbol) {
      symbolObj['$' + pws.symbol] = parseFloat(String(pws.value).replace(',', '.'));
    }
    console.log('symbols object', symbolObj);
    console.log(
      'formulas to update',
      paramsToUpdate.map(ptu => ptu.formula)
    );

    for (const ptu of paramsToUpdate) {
      ptu.value = math.evaluate(ptu.formula, symbolObj);
      this.update(ptu.value, ptu);
    }
  }

  async created() {
    this.setup();
    this.enabled =
      this.sample.status === consts.SampleStatus.IN_EXAM.id || this.sample.status === consts.SampleStatus.CORRECT.id;
    this.treeFromParams();
    console.log('created stptempl', this.sampleTestParamsTemplate);
  }
}
