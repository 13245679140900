






















































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ModalExamFilter from '@/components/spec/ModalExamFilter.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericList from '@/components/spec/GenericList.vue';
import MinimalGenericList from '@/components/spec/MinimalGenericList.vue';
import * as helpers from '@/helpers';
import Modal from '@/components/spec/Modal.vue';
import FileManager from '@/components/spec/FileManager.vue';
import * as API from '@/store/api';
import * as consts from '@/consts';
import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '@/consts';
import {
  AppView,
  Exam,
  DashboardItem,
  User,
  DashboardAction,
  Sample,
  ExtendedParam,
  Tool,
  StandardMultiselectEngine,
} from '@/types';
import { Guid } from 'guid-typescript';

@Component({
  components: {
    TopPanel,
    GenericList,
    FileManager,
    GenericForm,
    Header,
    Modal,
    Footer,
    MinimalGenericList,
  },
})
export default class TrainingList extends Vue {
  @Ref() readonly modalFilter: Modal;
  params = [];
  @Ref() readonly modal: Modal;
  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  object = {};
  training: any = {};
  trainingParams = new Array<any>();
  trainedParams = new Array<any>();
  filterObject = {};
  guidValue = '';
  modalTitle = '';
  copyFilterObject = {};
  url = '';
  guid: any;
  filterParams = new Array<ExtendedParam>();

  currFiles = [];

  @Ref() filterForm: GenericForm;

  processLoadedElement(object: any) {
    console.log(object, 'Loaded training...');
  }

  async showModal(object: any) {
    this.training = Object.assign({}, object);
    if (!this.training.id) {
      this.guid = Guid.create();
      this.guidValue = this.guid.value;
      this.modalTitle = 'Dodaj nowe szkolenie';
    } else {
      this.modalTitle = 'Edycja szkolenia';
      const response = await API.fetchFiles(this.training.id, 'Training');
      this.currFiles = response.data.files;
      console.log(this.currFiles, 'Fetched files...');
    }
    this.modal.showModal();
  }
  async saveModal(e: any) {
    const repsonse = await helpers.saveObjectModel(this.training, 'Training');
    if (repsonse.data.success) {
      const object = repsonse.data.object;
      if (!this.training.id) {
        await API.moveFilesFromTemp(this.guid.value, object.id);
      }

      helpers.info('Pomyślnie zapisano.');
    }
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }
  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, e);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    this.trainedParams = [
      {
        headerName: 'Nazwisko osoby przeszkolonej',
        value: '$fullName',
        headerCss: 'col-8',
      },
      {
        headerName: 'Data',
        headerCss: 'col-2',
        value: (object: any, param: any) => {
          const dt = moment(object.date_finished, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
          return dt;
        },
      },
      {
        headerCss: 'col-1',
        headerName: 'Załącznik',
        fieldType: 'icon',
        icon: 'file-pdf',
        size: 'lg',
        value: 'user',
        css: 'pdf-icon',
        action: async (object: any, e: any) => {
          this.url = process.env.VUE_APP_API_URL + 'media/' + object.download_localization;
          await this.$forceUpdate();
          document.getElementById('download').click();
        },
        visible: (obj: any) => {
          return true;
        },
      },
    ];
    this.trainingParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szkolenia',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'user_oranganiser',
        options: await helpers.getModelAsArray('UserData').then(result =>
          result.map(el => {
            el.fullName = el.user.first_name + ' ' + el.user.last_name;
            return el;
          })
        ),
        multiSelectTrack: 'fullName',
        engine: new StandardMultiselectEngine('user_organiser_id'),
        headerName: 'Organizator',
      },
      {
        fieldType: 'input',
        fieldName: 'description',
        headerName: 'Opis',
      },
      {
        fieldType: 'date',
        headerName: 'Data',
        fieldCss: 'w-50',
        fieldName: 'date',
        value: 'empty',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.date = dt.format(consts.DB_DATE_FORMAT);
          delete object['empty'];
        },
      },
      {
        fieldType: 'multiselect',
        engine: new StandardMultiselectEngine('type'),
        fieldName: 'type',
        options: [
          { name: 'Zewnętrzny', id: 1 },
          { name: 'Wewnętrzny', id: 0 },
        ],
        css: 'w-25',
        multiSelectTrack: 'name',
        headerName: 'Rodzaj',
      },
    ];

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szkolenia',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: [
          { name: 'ZEWNĘTRZNY', id: 1 },
          { name: 'WEWNĘTRZNY', id: 0 },
        ],
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('type', 'id'),
        headerName: 'Rodzaj',
      },
    ];

    this.params = [
      {
        value: '$name',
        headerName: 'NAZWA',
        columnCss: 'col-6 p-0 text-left font-16',
        headerCss: 'col-6  p-0 text-center',
      },
      {
        fieldType: 'badge',
        badgeVariant: (object, param) => {
          const id = object.type;
          const variants = ['success', 'info'];
          return variants[id];
        },
        value: (object, param) => {
          const id = object.type;
          const texts = ['WEWNĘTRZNE', 'ZEWNĘTRZNE'];
          return texts[id];
        },
        headerName: 'RODZAJ',
        sortField: 'type',
        headerCss: 'col-3 p-0',
      },
      {
        fieldType: 'icon',
        headerName: 'ZAŁĄCZNIK',
        icon: object => {
          return 'file-pdf';
        },
        size: 'lg',
        key: 0,
        css: 'pdf-icon',
        visible: (object: any) => {
          return true;
        },
        action: async (object: any, e: any) => {
          this.url = process.env.VUE_APP_API_URL + 'media/' + object.document.path;
          await this.$forceUpdate();
          document.getElementById('download').click();
        },
      },
      {
        headerName: 'Operacje',
        fieldType: 'rec',
        hideHeader: true,
        childParams: [
          {
            hideHeader: true,
            value: 'Aktywne',
            fieldCss: 'clickable-text font-12',
            action: async (obj, param) => {
              obj.active = true;
              const repsonse = await helpers.saveObjectModel(obj, 'Training');
              if (repsonse.data.success) {
                helpers.info('Pomyślnie aktywowano metodę.');
              }
            },
          },
          {
            hideHeader: true,
            value: 'Niekatywne',
            fieldCss: 'clickable-text font-12',
            action: async (obj, param) => {
              obj.active = false;
              const repsonse = await helpers.saveObjectModel(obj, 'Training');
              if (repsonse.data.success) {
                helpers.info('Pomyślnie zdezaktywowano metodę.');
              }
            },
          },
        ],
      },
    ];
  }
}
