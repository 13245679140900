






import Vue from 'vue';
import Component from 'vue-class-component';
import MainMenu from '../../components/dummy/MainMenu.vue';
import * as consts from '../../consts';

@Component({
  components: { MainMenu },
})
export default class LaborantMenu extends Vue {
  wide: boolean;

  constructor() {
    super();
    this.wide = false;
  }

  get height() {
    return 'height: ' + window.innerHeight + 'px';
  }

  get items() {
    return [
      {
        name: 'dashboard',
        title: 'PULPIT',
        icon: 'home',
        link: 'dashboard',
        module: 'lab',
      },
      {
        name: 'register',
        title: 'DODAJ PRÓBKĘ',
        icon: 'plus',
        link: 'register',
        module: 'lab',
        style: 'font-weight: 700;',
      },
      {
        name: 'samplesBoard',
        title: 'TABLICA PRÓBEK',
        icon: 'diagnoses',
        link: 'samplesboard',
        module: 'lab',
      },
      {
        name: 'readingsBoard',
        title: 'TABLICA ODCZYTÓW',
        icon: 'diagnoses',
        link: 'readingsboard',
        module: 'lab',
      },
      {
        name: 'docs',
        title: 'DOKUMENTY',
        icon: 'file-pdf',
        items: [
          {
            name: 'reports',
            title: 'Sprawozdania i Raporty',
            module: 'lab',
          },
          {
            title: 'Szablony sprawozdań',
            name: 'doctemplate',
            module: 'lab',
          },
          {
            name: 'result_docs',
            title: 'Kreator Sprawozdań',
            module: 'lab',
          },
          {
            name: 'internal_docs',
            title: 'Dokumenty wewnętrzne',
            module: 'lab',
          },
        ],
      },
      {
        name: 'administer',
        title: 'SYSTEM ZARZĄDZANIA',
        icon: 'user-cog',
        items: [
          {
            name: 'methodlist',
            title: 'Metody badawcze',
            module: 'lab',
          },
          {
            name: 'personellist',
            title: 'Personel',
            module: 'lab',
          },
          {
            name: 'toollist',
            title: 'Urządzenia',
            module: 'lab',
          },
          {
            name: 'readingsdefinitionlist',
            title: 'Definicja odczytów',
            module: 'lab',
          },
          {
            name: 'trainings',
            title: 'Szkolenia',
            module: 'lab',
          },
          {
            name: 'envconditionlist',
            title: 'Warunki środowiskowe',
            module: 'lab',
          },
        ],
      },
      /*
        OBJECT = 1,
  METHOD = 2,
  SECTION = 3,
  OBJECT_TYPE = 4,
  MATERIAL = 5,
  SOURCE = 6,
  TAKE_METHOD = 7,
  TAKE_PLACE = 8,
  PREPARE_NORM = 9,
  CALC_NORM = 10,
  AREA = 11,
  ROOM = 12,
      */
      {
        name: 'dictionaries',
        title: 'SŁOWNIKI',
        icon: 'list-alt',
        items: [
          {
            name: 'clientlist',
            title: 'Klienci',
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.OBJECT,
            title: consts.DictTypeTitles[consts.DictType.OBJECT],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.OBJECT_TYPE,
            title: consts.DictTypeTitles[consts.DictType.OBJECT_TYPE],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.ROOM,
            title: consts.DictTypeTitles[consts.DictType.ROOM],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.SOURCE,
            title: consts.DictTypeTitles[consts.DictType.SOURCE],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.TAKE_METHOD,
            title: consts.DictTypeTitles[consts.DictType.TAKE_METHOD],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.TAKE_PLACE,
            title: consts.DictTypeTitles[consts.DictType.TAKE_PLACE],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.PREPARE_NORM,
            title: consts.DictTypeTitles[consts.DictType.PREPARE_NORM],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.CALC_NORM,
            title: consts.DictTypeTitles[consts.DictType.CALC_NORM],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.AREA,
            title: consts.DictTypeTitles[consts.DictType.AREA],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.METHOD,
            title: 'Metody analiz',
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.SECTION,
            title: 'Sekcje',
            module: 'lab',
          },
          {
            name: 'packagelist',
            title: 'Pakiety',
            module: 'lab',
          },
          {
            name: 'dicts',
            title: 'Wszystkie słowniki',
            module: 'lab',
          },
          {
            name: 'admin',
            title: 'Administrator',
            module: 'lab',
            superuser: true,
          },
        ],
      },
    ];
  }
}
