






































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ModalExamFilter from '@/components/spec/ModalExamFilter.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericList from '@/components/spec/GenericList.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as consts from '@/consts';
import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '@/consts';
import ReadingDefinitionModal from './ReadingDefinitionModal.vue';

import {
  AppView,
  Exam,
  DashboardItem,
  ReadingsDefinition,
  DashboardAction,
  Sample,
  ExtendedParam,
  StandardMultiselectEngine,
} from '@/types';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    ReadingDefinitionModal,
    Modal,
    Footer,
  },
})
export default class ReadingDefinitionList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly readingDefinitionModal: ReadingDefinitionModal;
  @Ref() readonly list: GenericList;
  params = new Array<ExtendedParam>();

  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  editedDefinition = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;
  dicts = [];
  takePlaces = [];
  objectTypes = [];
  hours = [];

  isEditing = false;

  reload() {
    this.list.fillObjects();
  }

  openReadingDefinitionModal(el: ReadingsDefinition) {
    if (Object.entries(el).length == 0) {
      this.isEditing = false;
    } else this.isEditing = true;
    const copyOfDefinition = Object.assign({}, el);
    this.editedDefinition = copyOfDefinition as ReadingsDefinition;
    this.readingDefinitionModal.showModal(this.editedDefinition as ReadingsDefinition);
  }

  get getModalTitle() {
    if (this.isEditing) return 'Edycja definicji odczytu';
    else return 'Nowy odczyt definicji';
  }

  processLoaded(object: any) {
    const singleNumber = 1;
    if (String(object.minute).length === singleNumber) object.time = object.hour + ':0' + object.minute;
    else object.time = object.hour + ':' + object.minute;
    object.formatted_changed_date_time = moment(object.changed_date_time).format(DATE_TIME_FORMAT);
    object.formatted_create_date_time = moment(object.create_date_time).format(DATE_TIME_FORMAT);
    object.active = object.object_type.active;
    object.value = object.object_type.value;
    //console.log(object.objects);
  }

  showModal(object: ReadingsDefinition) {
    this.editedDefinition = object;
    this.readingDefinitionModal.showModal(this.editedDefinition as ReadingsDefinition);
  }
  saveModal(e: any) {
    console.log('123');
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }
  sendFilterQuery(e: any) {
    console.log('OK', this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async loadDicts() {
    const response = await API.fetchDicts();
    this.dicts = response.data.dicts;
    //console.log('DICTS', this.dicts);
  }

  async created() {
    await this.loadDicts();

    this.takePlaces = this.dicts.filter(d => d.type === consts.DictType.TAKE_PLACE);
    this.objectTypes = this.dicts.filter(d => d.type === consts.DictType.OBJECT_TYPE);
    this.hours = consts.Hours;
    this.filterParams = [
      {
        headerName: 'Miejsce pobrania',
        fieldType: 'multiselect',
        options: this.takePlaces,
        engine: new StandardMultiselectEngine('take_place_id', 'id'),
        multiSelectTrack: 'value',
      },
      {
        headerName: 'Typ obiektu',
        fieldType: 'multiselect',
        options: this.objectTypes,
        engine: new StandardMultiselectEngine('object_type_id', 'id'),
        multiSelectTrack: 'value',
      },
      {
        headerName: 'Godzina odczytu',
        fieldType: 'multiselect',
        options: this.hours,
        engine: new StandardMultiselectEngine('hour', 'value'),
        multiSelectTrack: 'name',
        value: object => {
          console.log(object);
          const id = object.value;
          return consts.Hours[id];
        },
        action: (e, object) => {
          object.hour = e.value;
          this.$forceUpdate();
        },
      },
    ];

    this.params = [
      {
        headerName: 'MIEJSCE',
        value: '$take_place__value',
        sortField: 'take_place__value',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'TYP OBIEKTU',
        value: '$object_type__value',
        sortField: 'object_type__value',
        columnCss: 'col-2 p-0 text-left',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'GODZINA',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
        value: '$time',
        sortField: 'hour',
      },
      {
        headerName: 'DATA MODYFIKACJI',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
        value: '$formatted_changed_date_time',
        sortField: 'changed_date_time',
      },
      {
        headerName: 'DATA UTWORZENIA',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
        value: '$formatted_create_date_time',
        sortField: 'create_date_time',
      },
    ];

    this.modalParams = [
      {
        headerName: 'Miejsce',
        fieldType: 'input',
        fieldName: 'takeplace',
      },
      {
        fieldType: 'input',
        headerName: 'Typ obiektu',
        fieldName: 'objecttype',
      },
      {
        headerName: 'Godzina',
        fieldType: 'input',
        fieldName: 'time',
      },
    ];
    console.log(this.objects, 'fetched objects');
  }
}
