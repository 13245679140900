











































































































































import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import * as helpers from '../../../helpers';
import { ExtendedParam, ReadingsDefinition, StandardMultiselectEngine } from '../../../types';
import { DictType, DictTypeTitles, Hours } from '../../../consts';
import * as API from '../../../store/api';
import Modal from '../../../components/spec/Modal.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericField from '../../../components/spec/GenericField.vue';
import MinimalGenericList from '../../../components/spec/MinimalGenericList.vue';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';

@Component({
  components: {
    GenericForm,
    ModalQuestion,
    MinimalGenericList,
    GenericField,
  },
})
export default class ReadingDefinitionModal extends Vue {
  @Prop() readonly isNew: boolean;
  @Prop() readonly title: string;

  editedDefinition: ReadingsDefinition = null;
  mainSectionParams: Array<ExtendedParam> = [];
  newMainSectionParams: Array<ExtendedParam> = [];
  loaded = false;
  tabs: Array<any>;
  selectedTool = {};
  selectedMethod = {};
  modalVisible = false;
  show = false;
  dicts = [];
  testParams = [];
  testAnalyses = [];
  takePlaces = [];
  objectTypes = [];
  hours = [];
  checked = false;
  timeSelect = 0;
  takePlaceSelect = 3;

  chosenAnalyse = null;

  availableParams = [];
  chosenParam = [];
  isReading = true;
  chosenParams = [];
  frequency = '';

  async showModal(editedDefinition: ReadingsDefinition) {
    this.editedDefinition = editedDefinition;
    this.chosenParams = [];
    if (Object.keys(this.editedDefinition).length !== 0) {
      await this.loadTestParam();
    }

    this.modalVisible = true;
  }

  get isAdditionalSamples() {
    return this.editedDefinition['isAdditionalSamples'];
  }

  hideModal() {
    this.modalVisible = false;
  }
  async save() {
    const analysis = this.chosenParams.map(p => p['analyse']);
    console.log('Analysis', analysis);
    this.editedDefinition['tests'] = [...new Map(analysis.map(item => [item['id'], item])).values()];
    this.editedDefinition['tests'].forEach(t => {
      t['params'] = this.chosenParams.filter(p => p.analyse.id === t.id).map(pa => pa.param);
    });

    const response = await API.saveReadingDefinition(this.editedDefinition);

    if (response.data.success) {
      helpers.info('Pomyślnie zapisano');
      this.hideModal();
      this.$emit('REQUEST_RELOAD');
    } else {
      helpers.error('Nieoczekiwany błąd po stronie');
    }
  }

  analysis = [];

  analyseSelected = {};
  async selectAnalyse(e: KeyboardEvent) {
    console.log('E', e);
    this.availableParams = e['params'];
  }

  addParam() {
    const newParam = Object.assign({}, this.chosenParam);
    newParam['is_reading'] = this.isReading;
    newParam['frequency'] = this.frequency;

    for (let i = 0; i < this.chosenParams.length; i++) {
      if (
        this.chosenParams[i]['analyse']['id'] === this.chosenAnalyse['id'] &&
        this.chosenParams[i]['param']['id'] === newParam['id'] &&
        this.chosenParams[i]['param']['is_reading'] === newParam['is_reading'] &&
        this.chosenParams[i]['param']['frequency'] === newParam['frequency']
      ) {
        return;
      }
    }

    this.chosenParams.push({
      analyse: this.chosenAnalyse,
      param: newParam,
    });
  }

  deselectAnalysis(param) {
    this.chosenParams = this.chosenParams.filter(
      a => a.param.id != param.param.id || a.param.is_reading != param.param.is_reading
    );
  }

  async loadDicts() {
    const response = await API.fetchDicts();
    this.dicts = response.data.dicts;
  }

  async loadTestParam() {
    const response = await API.fetchTestParam(this.editedDefinition);
    this.testAnalyses = response.data.analyse;
    this.testParams = response.data.param;
    for (let i = 0; i < this.testAnalyses.length; i++) {
      this.chosenParams.push({
        analyse: this.testAnalyses[i],
        param: this.testParams[i],
      });
    }
  }

  async created() {
    await this.loadDicts();

    this.takePlaces = this.dicts.filter(d => d.type === DictType.TAKE_PLACE);
    this.objectTypes = this.dicts.filter(d => d.type === DictType.OBJECT_TYPE);
    this.hours = Hours;

    this.mainSectionParams = [
      {
        headerName: 'Godzina odczytu',
        fieldType: 'multiselect',
        options: this.hours,
        engine: new StandardMultiselectEngine('hour', 'value'),
        multiSelectTrack: 'name',
        value: object => {
          const id = object.value;
          return Hours[id];
        },
        action: (e, object) => {
          object.hour = e.value;
          this.$forceUpdate();
        },
      },
      {
        headerName: 'Aktywny',
        fieldType: 'checkbox',
        fieldName: 'active',
      },
      {
        headerName: 'Miejsce pobrania',
        fieldType: 'multiselect',
        options: this.takePlaces,
        engine: new StandardMultiselectEngine('take_place_id', 'id'),
        multiSelectTrack: 'value',

        value: object => {
          const id = object.id;
          const texts = DictTypeTitles;
          return texts[id];
        },
        action: (e, object) => {
          object.take_place = e;
          this.$forceUpdate();
        },
      },
      {
        headerName: 'Typ obiektu',
        fieldType: 'multiselect',
        options: this.objectTypes,
        engine: new StandardMultiselectEngine('object_type_id', 'id'),
        multiSelectTrack: 'value',
        value: (object, param) => {
          const id = object.id;
          const texts = DictTypeTitles;
          return texts[id];
        },
        action: (e, object) => {
          object.object_type = e;
          this.$forceUpdate();
        },
      },
      {
        headerName: '',
        fieldType: 'hr',
        fieldName: '',
        mainCss: 'my-3 light-gray',
      },
    ];

    this.newMainSectionParams = [
      {
        headerName: 'Godzina odczytu',
        fieldType: 'multiselect',
        options: this.hours,
        engine: new StandardMultiselectEngine('hour', 'value'),
        multiSelectTrack: 'name',
        value: object => {
          const id = object.value;
          return Hours[id];
        },
        action: (e, object) => {
          object.hour = e.value;
          this.$forceUpdate();
        },
        disabled: false,
      },
      {
        headerName: 'Dodatkowe próbki?',
        fieldType: 'checkbox',
        fieldName: 'additional_samples',
        action: (e, object) => {
          if (e === true) {
            this.newMainSectionParams[this.timeSelect].disabled = true;
            this.newMainSectionParams[this.takePlaceSelect].disabled = true;
            object.take_place = this.takePlaces[this.takePlaces.length - 1];
          } else {
            this.newMainSectionParams[this.timeSelect].disabled = false;
            this.newMainSectionParams[this.takePlaceSelect].disabled = false;
            object.take_place = null;
          }
          this.$forceUpdate();
        },
      },
      {
        headerName: 'Aktywny',
        fieldType: 'checkbox',
        fieldName: 'active',
      },
      {
        headerName: 'Miejsce pobrania',
        fieldType: 'multiselect',
        options: this.takePlaces,
        engine: new StandardMultiselectEngine('take_place_id', 'id'),
        multiSelectTrack: 'value',

        value: object => {
          const id = object.id;
          const texts = DictTypeTitles;
          return object;
        },
        action: (e, object) => {
          object.take_place = e;
          this.$forceUpdate();
        },
        disabled: false,
      },
      {
        headerName: 'Typ obiektu',
        fieldType: 'multiselect',
        options: this.objectTypes,
        engine: new StandardMultiselectEngine('object_type_id', 'id'),
        multiSelectTrack: 'value',
        value: (object, param) => {
          const id = object.id;
          const texts = DictTypeTitles;
          return texts[id];
        },
        action: (e, object) => {
          object.object_type = e;
          this.$forceUpdate();
        },
      },
    ];

    const response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
      this.analyseSelected = this.analysis[0];
    }

    this.loaded = true;
  }
}
