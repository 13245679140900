import store from '@/store';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'app',
  store,
  dynamic: true,
})
class AppModule extends VuexModule {
  isMobile = false;
  appVersion = '1.0.1';
  config = {
    days_to_ctb_visit: 2,
    default_visit_duration: 60,
    home_route_link: 'calendarvisits',
  };

  get getAppVersion() {
    return this.appVersion;
  }

  get getDaysToCTBVisit() {
    return this.config.days_to_ctb_visit;
  }
  get defaultVisitDuration() {
    return this.config.default_visit_duration;
  }

  @Mutation
  setMobile(isMobile: boolean): any {
    this.isMobile = isMobile;
  }

  @Mutation
  setVersion(version: string): any {
    this.appVersion = version;
  }
  @Mutation
  changeConfigValue(param: string, value: string) {
    this.config[param] = value;
  }

  /*
  @Mutation
  loadAppFromConfig(app: App): any {
    this.isMobile = true;
    this.appVersion = app.appVersion;
    this.config = app.config;
    console.log("Store module AppModule loaded...");
  }
  */
}

export default getModule(AppModule);
