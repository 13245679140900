




























































































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import * as helpers from '../../helpers';
import moment from 'moment';
import Modal from '../../components/spec/Modal.vue';
import io from 'socket.io-client';

@Component({
  components: {
    Modal,
  },
})
export default class ModalMessages extends Vue {
  MessageGroup = consts.MessageGroup;
  @Ref() readonly userModal: Modal;
  show = false;
  contactsExpanded = true;
  groupsExpanded = true;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  showCreate = false;
  groupName = '';
  modalUserSelectedUser = {};
  localVersion = 0;
  messages = null;
  allUsers = [];
  groups = [];
  selectedUserIds = [];
  deletingUsers = false;
  initialFetchDone = false;
  currentUser;
  currentMessages = [];
  secondUser = null;
  selectedGroup = null;
  isGroup = false;
  messageContent = '';
  revalidateMessages = 0;
  messageBox;
  fetchingMessages = false;
  socket = null;
  messageData = null;

  get getMessageDate() {
    return message => {
      if (message.create_date_time !== '') {
        const create = message.create_date_time;
        const m = moment(create, consts.DB_DATE_TIME_FORMAT).format('HH:mm');
        return m;
      } else {
        return '-';
      }
    };
  }

  get wsServerURL() {
    //return 'http://localhost:4000/';
    console.log('VUE_APP_WS_URL', process.env.VUE_APP_WS_URL);
    return process.env.VUE_APP_WS_URL;
  }

  get getAllUnreadMessagesCount() {
    let count = 0;
    this.revalidateMessages++;
    this.allUsers.forEach(el => {
      count += this.getUnreadMessages(el);
    });
    return count;
  }

  get getRecentUsers() {
    if (!this.allUsers) {
      return [];
    }
    if (!this.messages || this.messages.length === 0) {
      return this.allUsers;
    }
    const users = this.allUsers.sort((a, b) => {
      try {
        if (!a) return -1;
        if (!b) return 1;
        const a_id = this.messages[a.id].length !== 0 ? this.messages[a.id][this.messages[a.id].length - 1]['id'] : 0;
        const b_id = this.messages[b.id].length !== 0 ? this.messages[b.id][this.messages[a.id].length - 1]['id'] : 0;
        return b_id - a_id;
      } catch (error) {
        return 0;
      }
    });
    return users;
  }

  get userById() {
    return id => {
      return this.allUsers.find(el => el.id === id);
    };
  }

  get getUnreadMessages() {
    return user => {
      this.revalidateMessages;
      const id = user.user_id;
      let messages = [];
      if (this.messages && this.messages[id] !== undefined) {
        messages = this.messages[id];
      } else {
        return 0;
      }
      return messages.filter(el => !el.is_read && el.sender_id !== this.currentUser.id).length;
    };
  }

  get canLaterMessagesBeFetched() {
    return !this.currentMessages[0].is_conversation_first_message;
  }

  get getRecentMessageContent() {
    return user => {
      const id = user.user_id;
      let messages = [];
      if (this.messages && this.messages[id] !== undefined) {
        messages = this.messages[id];
      }
      if (messages.length === 0) {
        return { content: '', create_date_time: '' };
      } else {
        return messages[messages.length - 1];
      }
    };
  }

  async addUserToGroup() {
    const data = { users: [] };
    const records = data['users'];
    this.selectedUserIds.forEach(el => {
      const record = {};
      record['user_id'] = el;
      record['group_id'] = this.selectedGroup.group_id;
      record['rights'] = 0;
      records.push(record);
    });

    this.socket.emit('add_user_to_group', JSON.stringify(data));
  }

  showUserAdditionModal(deletingUsers) {
    this.deletingUsers = deletingUsers;
    this.userModal.showModal();
  }

  createGroup() {
    this.showCreate = false;
    const group_data = {};
    group_data['name'] = this.groupName;
    this.socket.emit('add_group', JSON.stringify(group_data));
  }

  deleteUserFromGroup(user) {
    this.socket.emit('delete_user_from_group', JSON.stringify({ user, group: this.selectedGroup }));
  }

  async sendMessage() {
    this.messageContent = this.messageContent.trim();
    if (this.messageContent === '' || this.messageContent === ' ') {
      this.messageContent = '';
      return;
    }
    if (!this.currentUser || this.currentUser.id <= 0) {
      helpers.error('Proszę sie zalogować ponownie, sesja wygasła');
    }
    const message = {
      receiver_id: this.secondUser ? this.secondUser.id : undefined,
      sender_id: this.currentUser.id,
      content: this.messageContent,
      is_read: false,
      is_conversation_first_message: false,
      is_group_message: !!this.selectedGroup,
      is_admin_message: false,
      group_receive_id: this.selectedGroup ? this.selectedGroup['group_id'] : undefined,
    };

    this.messageContent = '';
    console.log('sendMessage', message);
    this.socket.emit('new_message', JSON.stringify(message));
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async scrollToBottom() {
    const messageBox = document.getElementById('message-box');
    await this.sleep(100);
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  }

  selectGroup(group) {
    this.isGroup = true;
    console.log(group, 'switching to group');
    this.currentMessages = group['messages'];
    this.secondUser = null;
    this.selectedGroup = group;
  }

  async processKeys(e) {
    if (e.keyCode === 13) {
      if (this.secondUser || this.selectedGroup) this.sendMessage();
    }
  }

  async loadChatWithUser(user: any) {
    console.log(user, 'loading converation with user...');
    if (this.messages && this.messages[user.id] !== undefined) {
      this.currentMessages = this.messages[user.id];
      this.splitIntoSections(this.currentMessages);
    } else {
      this.currentMessages = [];
    }

    const to_update = [];
    for (let i = this.currentMessages.length - 1; i >= 0; i--) {
      const curr_mess = this.currentMessages[i];
      if (!curr_mess.is_read && curr_mess.sender_id !== this.currentUser.id) {
        curr_mess.is_read = true;
        to_update.push(curr_mess.id);
      }
    }
    this.selectedGroup = null;
    this.secondUser = user;
    this.scrollToBottom();
    document.getElementById('message-box').addEventListener('scroll', this.processScroll);
    this.revalidateMessages++;
    this.socket.emit('read_messages', JSON.stringify({ to_update }));
  }

  processScroll(event) {
    if (this.$refs.messageBox && this.canLaterMessagesBeFetched) {
      if (this.$refs.messageBox['scrollTop'] === 0) {
        this.requestNewMessages();
      }
    }
  }

  async requestNewMessages() {
    const filtered = this.currentMessages.map(el => el.id);
    const last_id = Math.min(...filtered);
    const message_amount = 20;

    if (!this.secondUser) {
      console.log('Requesting messages...');
      this.fetchingMessages = true;
      this.socket.emit(
        'request_next_messages',
        JSON.stringify({ last_id, message_amount, group_id: this.selectedGroup['id'], is_group: true })
      );
    } else {
      const second_user = this.secondUser;
      console.log('Requesting messages...');
      this.fetchingMessages = true;
      this.socket.emit('request_next_messages', JSON.stringify({ last_id, message_amount, second_user }));
    }
  }

  async showModal() {
    this.show = true;
    await this.$forceUpdate();
    if (this.currentMessages.length === 0 && this.getRecentUsers.length !== 0) {
      this.loadChatWithUser(this.getRecentUsers[0]);
    }
  }

  saveModal() {
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }

  fetchInitialMessages(data_raw) {
    const data = JSON.parse(data_raw);
    this.messageData = data;
    this.messages = this.messageData['users'];
    this.groups = this.messageData['groups'];
    this.initialFetchDone = true;
  }

  async dragScrollDown(lastHeight) {
    await this.sleep(20);
    const messageBox = document.getElementById('message-box');
    const currentHeight = messageBox.scrollHeight;
    messageBox.scrollTop = Math.abs(currentHeight - lastHeight);
  }

  async fetchLaterMessages(data_raw) {
    const data = JSON.parse(data_raw);
    const messages = data['messages'];
    const messageBox = document.getElementById('message-box');
    const lastHeight = messageBox.scrollHeight;
    messages.reverse();
    this.splitIntoSections(messages);
    const toUpdate = [];
    while (messages.length > 0) {
      const lastElement = messages.pop();
      if (!lastElement.is_read && lastElement.sender_id !== this.currentUser.id) {
        toUpdate.push(lastElement.id);
      }
      this.currentMessages.unshift(lastElement);
    }

    await this.$forceUpdate();
    this.dragScrollDown(lastHeight);
    this.fetchingMessages = false;
    this.socket.emit('read_messages', JSON.stringify({ to_update: toUpdate }));
  }

  async newMessage(data_raw) {
    const data = JSON.parse(data_raw);
    if (data['receiver_id']) {
      if (data['sender_id'] !== this.currentUser.id) {
        this.messages[data['sender_id']].push(data);
      } else {
        this.currentMessages.push(data);
        await this.$forceUpdate();
        this.scrollToBottom();
      }
    } else {
      if (this.selectedGroup && data['group_receive_id'] === this.selectedGroup.group_id) {
        this.currentMessages.push(data);
        await this.$forceUpdate();
        this.scrollToBottom();
      } else {
        const group = this.groups.find(el => {
          return el.group_id === data['group_receive_id'];
        });
        group['messages'].push(data);
      }
    }
  }

  async createEventListeners() {
    this.socket.on('connect', function () {
      console.log('connected:', this.socket);
    });
    this.socket.on('disconnect', function () {
      console.log('disconnect', this.socket);
    });
    this.socket.on('connect_error', err => {
      console.log(`connect_error due to ${err.message}`, err);
    });
    this.socket.on('reconnect_error', err => {
      console.log(`reconnect_error due to ${err.message}`, err);
    });
    this.socket.emit('register_browser', JSON.stringify(this.currentUser));
    this.socket.on('new_message', this.newMessage);
    this.socket.on('fetch_later_messages', this.fetchLaterMessages);
    this.socket.on('added_to_group', async data_raw => {
      this.socket.emit('register_browser', JSON.stringify(this.currentUser));
    });

    this.socket.on('fetch_initial_messages', this.fetchInitialMessages);
  }

  showNewMessageNotification(messageData: any) {
    const sender = this.allUsers.find(el => {
      return messageData['sender_id'] === el.id;
    });
    helpers.messageInfo(sender.full_name, messageData['content']);
  }

  splitIntoSections(messages, callback?) {
    for (let i = 0; i < messages.length; i++) {
      if (i !== 0) {
        const lastMessage = messages[i - 1];
        const lastDate = moment(lastMessage.create_date_time, consts.DB_DATE_TIME_FORMAT);
        const currentMesage = messages[i];
        const currentMessageDate = moment(messages[i].create_date_time, consts.DB_DATE_TIME_FORMAT);

        if (lastDate.day() !== currentMessageDate.day()) {
          currentMesage.isDaySectionOpeningMessage = true;

          const dateToday = moment();
          const diff = dateToday.diff(currentMessageDate, 'days');
          const specificDiffCaptions = ['Dzisiaj', 'Wczoraj'];

          if (specificDiffCaptions[diff]) {
            currentMesage.daySectionCaption = specificDiffCaptions[diff];
          } else if (diff <= 7) {
            currentMesage.daySectionCaption = helpers.translateDate(currentMessageDate.format('dddd'));
          } else {
            currentMesage.daySectionCaption = helpers.translateDate(currentMessageDate.format('DD MMMM YYYY'));
          }
        }
      }
      if (callback) {
        callback(messages[i]);
      }
    }
  }

  async mounted() {
    this.currentUser = Object.assign({}, this.$store.state.currentUser);
    this.currentUser.full_name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
    document.addEventListener('keydown', this.processKeys);
    this.allUsers = await helpers.getModelAsArray('UserData');
    this.allUsers.forEach(el => {
      el.full_name = el.user.first_name + ' ' + el.user.last_name;
    });

    this.socket = io(this.wsServerURL, {
      timeout: 10000,
      transports: ['websocket'],
    });

    await this.createEventListeners();
    console.log('Socket IO connected on', this.socket);
  }
}
