import Vue from 'vue';
import filters from './helpers/filters';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import * as API from '@/store/api';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { EventBus, BusEvents } from '@/helpers/eventbus';
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faCaretUp,
  faAngleUp,
  //faAnglesUp,
  faCog,
  faEnvelope,
  faFilter,
  faHome,
  faListAlt,
  faPlus,
  faQuestion,
  faShoppingBasket,
  faSort,
  faUserCircle,
  faUserCog,
  faUserSecret,
  faVial,
  faPlusSquare,
  faMinusSquare,
  faTrashAlt,
  faHandshake,
  faFilePowerpoint,
  faTimes,
  faCheck,
  faSignature,
  faEdit,
  faEye,
  faHistory,
  faRedoAlt,
  faShieldAlt,
  faPlayCircle,
  faSignOutAlt,
  faPowerOff,
  faTable,
  faWindowMaximize,
  faWindowMinimize,
  faTools,
  faExclamationTriangle,
  faExclamationCircle,
  faSave,
  faFilePdf,
  faClock,
  faDiagnoses,
  faArrowsAlt,
  faMinusCircle,
  faClone,
  faFile,
  faFileExcel,
  faTrain,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import smoothscroll from 'smoothscroll-polyfill';

export async function loadFromDB() {
  console.log('%%%%%%%%%%%%%%%%%% loading from DB to store %%%%%%%%%%%%%%%%%%%%%');
}

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);
Vue.component('VueSlider', VueSlider);

library.add(
  faUserSecret,
  faHome,
  faCalendarAlt,
  faShoppingBasket,
  faUserCog,
  faListAlt,
  faEnvelope,
  faCog,
  faUserCircle,
  faQuestion,
  faVial,
  faChevronRight,
  faPlus,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faCaretUp,
  faAngleUp,
  //faAnglesUp,
  faFilter,
  faSort,
  faPlusSquare,
  faMinusSquare,
  faTrashAlt,
  faHandshake,
  faFilePowerpoint,
  faTimes,
  faCheck,
  faSignature,
  faEdit,
  faEye,
  faHistory,
  faRedoAlt,
  faShieldAlt,
  faPlayCircle,
  faSignOutAlt,
  faPowerOff,
  faTable,
  faWindowMaximize,
  faWindowMinimize,
  faTools,
  faExclamationTriangle,
  faExclamationCircle,
  faSave,
  faFilePdf,
  faClock,
  faDiagnoses,
  faArrowsAlt,
  faMinusCircle,
  faClone,
  faFile,
  faFileExcel,
  faTrain
);

Vue.prototype.$sync = function (key: string, value: any) {
  console.log('Sync key', key, 'value', value);
  this.$emit(`update:${key}`, value);
};

filters.forEach(f => {
  Vue.filter(f.name, f.execute);
});

Vue.prototype.window = window;
Vue.prototype.events = new Vue();

smoothscroll.polyfill();

console.log('Deps loaded');
