












































































































































































































































































































































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import MinimalGenericList from '@/components/spec/MinimalGenericList.vue';
import GenericField from '@/components/spec/GenericField.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as helpers from '@/helpers';
import * as consts from '@/consts';
import {
  User,
  Sample,
  ExtendedParam,
  Tool,
  StandardMultiselectEngine,
  TemplateItem,
  ToolTemplate,
  ToolService,
  ToolTest,
  ToolElement,
  ToolMaintenance,
  ToolComment,
  Method,
} from '@/types';
import { BButton } from 'bootstrap-vue';

@Component({
  components: {
    TopPanel,
    MinimalGenericList,
    GenericForm,
    GenericField,
    Header,
    Modal,
    Footer,
  },
})
export default class ToolModal extends Vue {
  modalParams = new Array<ExtendedParam>();
  methodParams = new Array<ExtendedParam>();

  @Ref() dateField: GenericField;
  @Ref() dateFieldToolService: GenericField;
  @Ref() dateFieldToolTemplateStart: GenericField;
  @Ref() dateFieldToolTemplateEnd: GenericField;
  @Ref() dateFieldToolComment: GenericField;
  @Ref() dateFieldToolElementStart: GenericField;
  @Ref() dateFieldToolElementEnd: GenericField;
  @Ref() dateFieldToolMaintenance: GenericField;

  modalTitle = '';
  buttonText = '+ Dodaj';
  buttonFunction = this.addElement;
  buttonAddToolComment = this.addElementToolComment;
  buttonAddToolElement = this.addElementToolElement;
  buttonAddToolMaintenance = this.addElementToolMaintenance;
  buttonAddToolTest = this.addElementToolTest;
  buttonAddToolService = this.addElementToolService;
  buttonAddToolTemplate = this.addElementToolTemplate;

  newListObj = {
    id: 0,
    name: '',
    date: '',
  };

  modalVisible = false;

  tool: Tool = null;

  isNew = false;

  newListObjToolComment = new ToolComment();
  newListObjToolTest = new ToolTest();
  toolTemplateInput = new ToolTemplate();
  newListObjToolService = new ToolService();
  newListObjToolElement = new ToolElement();
  toolMaintenanceInput = new ToolMaintenance();

  initialToolValue: Tool;

  change = false;
  elements = [];
  users = [];
  allMethods = [];

  allTemplates: Array<TemplateItem> = [];

  addElementToolTest() {
    const copy = Object.assign({}, this.newListObjToolTest);
    copy.tool_id = this.tool.id;

    this.tool.tool_tests.push(copy);

    this.dateField.reset();
    this.newListObjToolTest = new ToolTest();
  }

  addElementToolTemplate() {
    const copy = Object.assign(new ToolTemplate(), this.toolTemplateInput);
    copy.tool_id = this.tool.id;

    this.tool.tool_templates.push(copy);

    this.dateFieldToolTemplateStart.reset();
    this.dateFieldToolTemplateEnd.reset();

    this.toolTemplateInput = new ToolTemplate();

    console.log('ToolTemplate', this.tool.tool_templates);
  }

  addElementToolMaintenance() {
    console.log('ToolMaintenance');
    const copy = Object.assign({}, this.toolMaintenanceInput);
    copy.tool_id = this.tool.id;

    this.tool.tool_maintenances.push(copy);
    this.dateFieldToolMaintenance.reset();

    this.toolMaintenanceInput = new ToolMaintenance();
  }

  addElementToolService() {
    console.log('toolServiceObj');
    const copy = Object.assign({}, this.newListObjToolService);

    copy.tool_id = this.tool.id;

    this.tool.tool_services.push(copy);

    this.dateFieldToolService.reset();

    this.newListObjToolService = new ToolService();
  }

  addElementToolElement() {
    console.log('ToolElement');
    const copy = Object.assign({}, this.newListObjToolElement);

    copy.user = this.$store.state.currentUser;
    copy.tool_id = this.tool.id;

    this.tool.tool_elements.push(copy);
    this.dateFieldToolElementStart.reset();
    this.dateFieldToolElementEnd.reset();
    this.newListObjToolElement = new ToolElement();
  }

  addElementToolComment() {
    console.log('ToolComment');

    const copy = Object.assign({}, this.newListObjToolComment);
    copy.user = this.$store.state.currentUser;
    copy.tool_id = this.tool.id;

    this.tool.tool_comments.push(copy);
    this.dateFieldToolComment.reset();
  }

  addElement() {
    const copy = Object.assign(new ToolElement(), this.newListObj);
    copy.tool_id = this.tool.id;
    copy.user = this.$store.state.currentUser;

    this.elements.push(copy);
    this.dateField.reset();
    this.newListObj = { id: this.elements.length, name: '', date: '' };
  }

  toolCommentList = new Array<ExtendedParam>();
  toolTestList = new Array<ExtendedParam>();
  toolElementList = new Array<ExtendedParam>();
  toolMaintenanceList = new Array<ExtendedParam>();
  toolServiceList = new Array<ExtendedParam>();
  toolTemplateList = new Array<ExtendedParam>();
  numberToolTemplate = new Array<ExtendedParam>();

  timeParam = {
    fieldType: 'date',
    headerName: 'Data',
    value: 'date',
  };
  toolServiceTimeParam = {
    fieldType: 'date',
    headerName: 'Data',
    value: 'execution_date',
  };
  toolMaintenanceTimeParam = {
    fieldType: 'date',
    headerName: 'Data',
    value: 'execution_date',
  };
  toolCommentTimeParam = {
    fieldType: 'date',
    headerName: 'Data dodania',
    value: 'comment_date',
  };
  toolElementStartTimeParam = {
    fieldType: 'date',
    headerName: 'Data rozpoczęcia stosowania',
    value: 'start_use_date',
  };
  toolElementEndTimeParam = {
    fieldType: 'date',
    headerName: 'Data zakończenia stosowania',
    value: 'end_use_date',
  };
  toolTemplateStartTimeParam = {
    fieldType: 'date',
    headerName: 'Data zakończenia stosowania',
    value: 'start_date_time',
  };
  toolTemplateEndTimeParam = {
    fieldType: 'date',
    headerName: 'Data zakończenia stosowania',
    value: 'end_date_time',
  };

  hideModal() {
    this.modalVisible = false;
  }

  newElements: Array<any> = [];
  deletedElements: Array<any> = [];

  detectDifferencesOnArray(initialArray: Array<any>, newArray: Array<any>, model: string) {
    newArray.forEach(element => {
      if (!element.id) {
        this.newElements.push({ element, model });
      }
    });

    initialArray.forEach(element => {
      const id = element.id;
      const index = newArray.findIndex(el => el.id === id);
      if (index === -1) {
        this.deletedElements.push({ element, model });
      }
    });
  }

  save() {
    const watchedArrays = {
      tool_tests: 'ToolTest',
      tool_services: 'ToolService',
      tool_templates: 'ToolTemplate',
      tool_maintenances: 'ToolMaintenance',
      tool_comments: 'ToolComment',
      tool_elements: 'ToolElement',
    };

    for (const [array, model] of Object.entries(watchedArrays)) {
      this.detectDifferencesOnArray(this.initialToolValue[array], this.tool[array], model);
    }

    const response = API.saveTool(this.tool, this.newElements, this.deletedElements);
    if (response) {
      helpers.requestSucess();
    } else {
      helpers.error('Wystąpił błąd po stronie serwera.');
    }

    this.hideModal();
  }

  loadTool() {
    this.tool.load(this.allMethods, this.allTemplates);
    this.initialToolValue = Object.assign(new Tool(), this.tool);
  }

  async showModal(tool: Tool, isNew: boolean) {
    this.isNew = isNew;
    this.modalVisible = true;

    const awaitFunction = () => {
      if (!this.fetchedAll) {
        setTimeout(awaitFunction, 10);
      } else {
        this.tool = Object.assign(new Tool(), tool);
        this.loadTool();
      }
    };

    setTimeout(awaitFunction, 10);
  }

  fetchedAll = false;

  async created() {
    this.fetchedAll = false;

    const method = await API.fetchModel('Test');

    method.data.objs.forEach(element => {
      this.allMethods.push(element);
    });

    const template = await API.fetchModel('TemplateItem');
    template.data.objs.forEach(element => {
      this.allTemplates.push(element);
    });

    const response = await API.fetchModel('UserData');
    response.data.objs.forEach(element => {
      element.fullName = element.user.first_name + ' ' + element.user.last_name;
      this.users.push(element);
    });

    this.toolCommentList = [
      {
        headerName: 'Treść uwagi'.toUpperCase(),
        headerCss: 'col-3',
        value: '$description',
        columnCss: 'col-3 text-center',
      },
      {
        headerName: 'Data dodania'.toUpperCase(),
        headerCss: 'col-3',
        value: (obj: ToolComment, _) => {
          return moment(obj.comment_date).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-3 text-center',
      },
      {
        headerName: 'WPROWADZIŁ/A',
        headerCss: 'col-3',
        value: (object: ToolComment, param) => {
          const user = this.users.find(el => el.id === object.user_id);
          return user.fullName;
        },
        columnCss: 'col-3 text-center',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-2 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            if (href.text === 'Usuń') {
              this.tool.tool_comments = this.tool.tool_comments.filter(el => el.id !== obj.id);
            }
          },
        },
      },
    ];
    this.methodParams = [
      {
        fieldType: 'multiselect',
        value: 'name',
        options: this.allMethods,
        action: (value: Method, toolMethod: ToolTest, _) => {
          toolMethod.test = value;
          toolMethod.test_id = value.id;
        },
        multiSelectTrack: 'name',
        headerName: 'Wybierz metodę',
      },
      {
        fieldType: 'input',
        fieldName: 'spec',
        headerName: 'Specyfikacja',
        value: 'specyf',
      },
    ];
    this.numberToolTemplate = [
      {
        fieldType: 'multiselect',
        value: 'template_item',
        options: this.allTemplates,
        action: (value: TemplateItem, toolTemplate: ToolTemplate, _) => {
          toolTemplate.template = value;
          toolTemplate.template_id = value.id;
        },
        multiSelectTrack: 'code_number',
        headerName: 'Numer kodowy wzorca',
      },
    ];
    this.toolTestList = [
      {
        headerName: 'nazwa/metoda badawcza'.toUpperCase(),
        headerCss: 'col-6',
        value: '$test__name',
        columnCss: 'col-6',
      },
      {
        headerName: 'norma/procedura'.toUpperCase(),
        headerCss: 'col-3',
        value: '$specification',
        columnCss: 'col-3 text-center',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-2 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            console.log(obj, href);
            if (href.text === 'Usuń') {
              this.tool.tool_tests = this.tool.tool_tests.filter(el => el.id !== obj.id);
            }
          },
        },
      },
    ];
    this.toolElementList = [
      {
        headerName: 'element wyposażenia'.toUpperCase(),
        headerCss: 'col-3',
        value: '$name',
        columnCss: 'col-3',
      },
      {
        headerName: 'data rozpoczęcia stosowania'.toUpperCase(),
        headerCss: 'col-2',
        value: (obj: ToolElement, _) => {
          return moment(obj.start_use_date).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 text-center',
      },
      {
        headerName: 'data zakończenia stosowania'.toUpperCase(),
        headerCss: 'col-2',
        value: (obj: ToolElement, _) => {
          return moment(obj.end_use_date).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 text-center',
      },
      {
        headerName: 'wprowadził/a'.toUpperCase(),
        headerCss: 'col-2',
        value: (object: ToolElement, param) => {
          const user = this.users.find(el => el.id === object.user_id);
          return user.fullName;
        },
        columnCss: 'col-2',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-2 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            console.log(obj, href);
            if (href.text === 'Usuń') {
              this.tool.tool_elements = this.tool.tool_elements.filter(el => el.id !== obj.id);
            }
          },
        },
      },
    ];
    this.toolMaintenanceList = [
      {
        headerName: 'wykonane czynności'.toUpperCase(),
        headerCss: 'col-4',
        value: '$description',
        columnCss: 'col-4',
      },
      {
        headerName: 'data'.toUpperCase(),
        headerCss: 'col-2',
        value: (obj: ToolMaintenance, _) => {
          return moment(obj.execution_date).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 text-center',
      },
      {
        headerName: 'wykonawca'.toUpperCase(),
        headerCss: 'col-3',
        value: '$executor',
        columnCss: 'col-3 text-center',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-2 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            console.log(obj, href);
            if (href.text === 'Usuń') {
              this.tool.tool_maintenances = this.tool.tool_maintenances.filter(el => el.id !== obj.id);
            }
          },
        },
      },
    ];
    this.toolServiceList = [
      {
        headerName: 'data'.toUpperCase(),
        headerCss: 'col-2',
        value: (obj: ToolService, _) => {
          return moment(obj.execution_date).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 text-center',
      },
      {
        headerName: 'wykonawca'.toUpperCase(),
        headerCss: 'col-3',
        value: '$executor',
        columnCss: 'col-3 text-center',
      },
      {
        headerName: 'opis usterki'.toUpperCase(),
        headerCss: 'col-4',
        value: '$description',
        columnCss: 'col-4',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-2 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            if (href.text === 'Usuń') {
              this.tool.tool_services = this.tool.tool_services.filter(el => el.id !== obj.id);
            }
          },
        },
      },
    ];
    this.toolTemplateList = [
      {
        headerName: 'numer kodowy wzorca'.toUpperCase(),
        headerCss: 'col-2',
        value: '$template__code_number',
        columnCss: 'col-2',
      },
      {
        headerName: 'nazwa wzorca'.toUpperCase(),
        headerCss: 'col-3',
        value: '$template__name',
        columnCss: 'col-3',
      },
      {
        headerName: 'data rozpoczęcia stosowania'.toUpperCase(),
        headerCss: 'col-2',
        value: (obj: ToolTemplate, _) => {
          return moment(obj.start_date_time).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 text-center',
      },
      {
        headerName: 'data zakończenia stosowania'.toUpperCase(),
        headerCss: 'col-2',
        value: (obj: ToolTemplate, _) => {
          return moment(obj.end_date_time).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 text-center',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-2 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            if (href.text === 'Usuń') {
              this.tool.tool_templates = this.tool.tool_templates.filter(el => el.id !== obj.id);
            }
          },
        },
      },
    ];
    //---------------------------------------

    this.modalParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa wyposażenia',
      },
      {
        fieldType: 'input',
        fieldName: 'tool_type',
        headerName: 'Typ',
      },
      {
        fieldType: 'input',
        fieldName: 'fabric_number',
        headerName: 'Numer fabryczny',
      },
      {
        headerName: 'Producent wyposażenia',
        fieldName: 'producer',
        fieldType: 'input',
      },
      {
        headerName: 'Charakterystyka wyposażenia',
        fieldName: 'characteristic',
        fieldType: 'input',
      },
      {
        headerName: 'Przeznaczenie wyposażenia',
        fieldName: 'destination',
        fieldType: 'input',
      },
      {
        headerName: 'Lokalizacja wyposażenia',
        fieldName: 'localization',
        fieldType: 'input',
      },
      {
        fieldType: 'date',
        value: 'receive_date_time',
        headerName: 'Data otrzymania wyposażenia',
        css: 'w-50',
      },
      {
        fieldType: 'date',
        value: 'start_date_time',
        headerName: 'Data włączenia do eksploatacji',
        css: 'w-50',
      },
      {
        fieldType: 'input',
        fieldName: 'service_location',
        headerName: 'Lokalizacja serwisu techicznego',
      },
      {
        fieldType: 'radiobuttons',
        value: 'w_check',
        css: 'subpart w-50 p-3',
        headerName: 'Podlega sprawdzeniu',
        getValues: () => {
          return [
            {
              text: 'Tak',
              value: true,
            },
            {
              text: 'Nie',
              value: false,
            },
          ];
        },
      },
      {
        fieldType: 'radiobuttons',
        value: 'w_templated',
        css: 'subpart w-50 p-3',
        headerName: 'Podlega wzorcowaniu',
        getValues: () => {
          return [
            {
              text: 'Tak',
              value: true,
            },
            {
              text: 'Nie',
              value: false,
            },
          ];
        },
      },
      {
        fieldType: 'radiobuttons',
        value: 'w_calibration',
        css: 'subpart w-50 p-3',
        headerName: 'Podlega kalibracji',
        getValues: () => {
          return [
            {
              text: 'Tak',
              value: true,
            },
            {
              text: 'Nie',
              value: false,
            },
          ];
        },
      },
      {
        fieldType: 'input',
        fieldName: 'tool_doc',
        headerName: 'Dokumentacja wyposażenia',
      },
      {
        fieldType: 'input',
        fieldName: 'environmental_conditions',
        headerName: 'Warunki środowiskowe',
      },
      {
        fieldType: 'multiselect',
        value: 'user',
        options: this.users,
        multiSelectTrack: 'fullName',
        engine: new StandardMultiselectEngine('user_id', 'id'),
        headerName: 'Osoba odpowiedzialna za wyposażenie',
      },
    ];
    this.fetchedAll = true;
  }
}
