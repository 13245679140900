









































import moment from 'moment';
import { Component, Ref, Vue, Prop } from 'vue-property-decorator';
import { DATE_TIME_FORMAT } from '../../consts';
import * as API from '../../store/api';
import Modal from './Modal.vue';
import * as helpers from '../../helpers';

const PARA_NASYCONA = 'PARA NASYCONA';
const PARA_WTORNA = 'PARA WTÓRNA';
const BLOCK_BIO_NUMBER = 9;

@Component({
  components: {
    Modal,
  },
})
export default class ImportReadingsCSV extends Vue {
  @Prop(Array) takePlaces: [];
  @Prop(Boolean) btnDisabled: true;
  @Ref() readonly modal: Modal;

  uploading = false;
  analysis = [];
  allLinesCount = 0;
  counter = 0;
  failedCounter = 0;
  modalMessage = '';
  dates = [];
  dots = '';
  dotsInterval;
  terminate = false;
  regex = /;\r\n|;\n/;
  mappingArray = [
    [/kondensat/g, 'Kondensat'],
    [/WZ za ZZ/g, 'WODA ZASILAJĄCA ZA ZWZ'],
    [/WZ za XW/g, 'WODA ZASILAJĄCA ZA XW'],
    [/woda kot≥owa/g, 'Woda kotłowa'],
    [/;;/g, ';'],
  ];

  mappingParam = [
    { dbSymbol: 'y₂₅pkk', csvSymbol: 'ypkk' },
    { dbSymbol: 'y₂₅zkk', csvSymbol: 'yzkk' },
    { dbSymbol: 'TPom', csvSymbol: 'T' },
    { dbSymbol: 'O₂', csvSymbol: 'O2' },
    { dbSymbol: 'NH₄', csvSymbol: 'NH4' },
    { dbSymbol: 'SIO₂', csvSymbol: 'SiO2' },
    { dbSymbol: 'H₂', csvSymbol: 'czyst. H2' },
  ];

  disabledOkButton = true;

  Kondensat = ['KONDENSAT', 'T', 'ypkk', 'yzkk', 'O2', 'Na', 'pH'];
  WZzaZZ = ['WODA ZASILAJĄCA ZA ZWZ', 'ypkk', 'ykk', 'O2', 'pH', 'T'];
  WZzaXW = ['WODA ZASILAJĄCA ZA XW', 'T', 'pH'];
  WodaKotlowa = ['WODA KOTŁOWA', 'SiO2', 'Cl', 'T', 'pH', 'ypkk', 'yzkk'];
  PnPW = [PARA_NASYCONA, 'T', 'ypkk', 'SiO2']; // tu wyjątek - Para nasycona dla 1-7, wtorna dla 9
  Destylat = ['DESTYLAT', 'T', 'ydest.'];
  Generator = ['WODÓR W GEN.', 'czyst. H2'];
  PŚ = ['PARA ŚWIEŻA', 'ypkk', 'SiO2']; // para swieza

  allObjectTypes = [
    this.Kondensat,
    this.WZzaZZ,
    this.WZzaXW,
    this.WodaKotlowa,
    this.PnPW,
    this.Destylat,
    this.Generator,
    this.PŚ,
  ];

  bloki = [
    ['I', 1],
    ['II', 2],
    ['III', 3],
    ['IV', 4],
    ['V', 5],
    ['VI', 6],
    ['VII', 7],
    ['IX', 9],
  ];

  /*
  Kondensat = ['T1', 'ypkk', 'yzkk', 'O2', 'Na', 'pH1'];
  WZzaZZ = ['ypkk2', 'ykk2', 'O2', 'pH2', 'T2'];
  WZzaXW = ['T3', 'pH3'];
  WodaKotlowa = ['SiO2', 'Cl', 'T4', 'pH4', 'ypkk4', 'yzkk4'];
  PnPW = ['T5', 'ypkk5', 'SiO2(2)'];
  Destylat = ['T6', 'ydest.'];
  Generator = ['czyst. H2'];
  PŚ = ['ypkk6', 'SiO2'];
*/

  test() {
    const csv = `;;;;kondensat;;;;;;WZ za ZZ;;;;;WZ za XW;;woda kotłowa;;;;;;PN/PW;;;Destylat;;Generator;PŚ;
Nr bloku;Data;Moc bloku;Ciśnienie;T1;ypkk;yzkk;O2;Na;pH1;ypkk2;ykk2;O2;pH2;T2;T3;pH3;SiO2;Cl;T4;pH4;ypkk4;yzkk4;T5;ypkk5;SiO2(2);T6;ydest.;czyst. H2;ypkk6;SiO2;;
I;29-wrz-21 13:25:58;135;13,9;26,3;2,6;0,2;88,9;12,1;9,10;0,89;0,27;10,99;9,01;26,60;29,10;9,15;223;117;29,1;9,18;8,44;Tag not found;29;0,18;(PN);38,75;2,22;99,15;;
II;29-wrz-21 13:26:41;225;15,3;25,4;2,8;0,4;2,0;17,4;8,96;3,14;0,36;6,20;8,88;26,10;28,70;9,24;85;106;30,6;9,17;5,96;5,82;29,3;0,20;(PN);39,24;2,66;98,00;;
III;28-wrz-21 07:11:20;0;0,1;24,4;10,1;2,0;Bad Input;0,6;7,21;2,79;1,47;Bad Input;Bad Input;27,60;24,60;9,86;-3;48;24,9;9,24;5,82;4,35;50,7;1,99;(PN);22,76;Bad Input;Bad Input;;
IV;29-wrz-21 13:26:41;215;15,3;22,4;2,3;0,6;56,5;3,6;8,73;1,81;0,54;10,02;8,78;24,80;26,50;8,91;Bad Input;Bad Input;25,6;9,04;5,33;0,71;26,0;0,30;(PN);39,76;2,57;97,83;;
V;29-wrz-21 13:18:01;222;14,7;26,1;2,5;0,3;113,4;0,2;8,84;3,49;0,14;Bad Input;9,09;26,00;28,65;9,16;63;107;28,7;9,08;4,83;1,91;28,1;0,34;(PN);39,82;1,41;98,46;;
VI;29-wrz-21 13:25:56;199;14,8;24,0;3,3;0,4;36,2;1,5;9,05;2,21;0,33;9,53;9,19;23,90;26,40;9,30;122;106;27,8;9,09;8,58;7,99;27,9;0,17;(PN);39,93;3,04;97,90;;
VII;29-wrz-21 13:26:25;199;14,7;22,2;2,7;0,3;135,0;0,2;8,99;3,10;0,16;6,35;9,11;25,60;25,40;9,11;95;54;25,7;9,36;11,39;4,47;25,1;0,15;(PN);39,17;2,52;98,00;;
IX;29-wrz-21 13:26:13;220;12,9;25,3;3,2;0,2;131,2;3,8;9,14;2,30;0,15;2,53;8,90;24,30;;9,26;;;;;;;(PW);0,15;6,81;39,94;2,56;98,20;0,16;6,86
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
`;
    this.parseCSVLines(csv);
  }

  get findBlockNo() {
    return (csvValue: string): number => {
      for (let i = 0; i < this.bloki.length; i++) {
        if (this.bloki[i][0] === csvValue) {
          return parseInt(this.bloki[i][1].toString());
        }
      }
      return 0;
    };
  }

  get shiftHour() {
    return (tpp, dt) => {
      const shiftHours = [];
      (tpp['shifts'] as []).forEach(shift => {
        if (
          shiftHours.find(
            sh =>
              sh.year === shift['year'] &&
              sh.month === shift['month'] &&
              sh.day === shift['day'] &&
              sh.hour === shift['hour'] &&
              sh.minute === shift['minute']
          ) === undefined
        ) {
          if (shift['year'] === dt.year() && shift['month'] === dt.month() + 1 && shift['day'] === dt.date()) {
            shiftHours.push({
              month: shift['month'],
              year: shift['year'],
              day: shift['day'],
              hour: shift['hour'],
              minute: shift['minute'],
            });
          }
        }
      });
      console.log('ShiftHours', shiftHours);
      let foundYear = 0;
      let foundMonth = 0;
      let foundDay = 0;
      let foundHour = 0;
      let foundMinute = 0;
      for (let s = shiftHours.length - 1; s >= 0; s--) {
        console.log('ShiftHours', shiftHours, ' s', s, 'hour:', dt.hour(), 'minute:', dt.minutes());
        if (shiftHours[s]['hour'] <= dt.hour() && shiftHours[s]['minute'] <= dt.minutes()) {
          foundYear = shiftHours[s]['year'];
          foundMonth = shiftHours[s]['month'];
          foundDay = shiftHours[s]['day'];
          foundHour = shiftHours[s]['hour'];
          foundMinute = shiftHours[s]['minute'];
          break;
        }
      }
      const foundTime = {
        foundYear: foundYear,
        foundMonth: foundMonth,
        foundDay: foundDay,
        foundHour: foundHour,
        foundMinute: foundMinute,
      };
      console.log('FoundTime', foundTime);
      return foundTime;
    };
  }

  get findParamObject() {
    return (blockNo, dt, objectName) => {
      console.log('findParm', blockNo, dt.hour(), dt.minutes(), objectName, this.takePlaces);
      const tpp = this.takePlaces.find(tp => parseInt(tp['symbol']) === blockNo);
      if (tpp !== undefined) {
        console.log('findParamObject Found TPP', tpp);

        const foundTime = this.shiftHour(tpp, dt);
        const shift = (tpp['shifts'] as []).find(
          s =>
            s['year'] === foundTime.foundYear &&
            s['month'] === foundTime.foundMonth &&
            s['day'] === foundTime.foundDay &&
            s['hour'] === foundTime.foundHour &&
            s['minute'] === foundTime.foundMinute
        );
        console.log('findParamObject Found Shift', foundTime, foundTime.foundHour, foundTime.foundMinute, shift);
        return (shift['object_types'] as []).find(o => o['object_type_name'] === objectName);
      } else {
        console.log('Couldnt find TPP', blockNo);
        return null;
      }
    };
  }

  parseCSVLines(csv) {
    for (let j = 0; j < this.mappingArray.length; j++) {
      csv = csv.replace(this.mappingArray[j][0], this.mappingArray[j][1]);
    }
    const allLines = csv.split(this.regex);
    console.log('allLines', allLines);

    this.allLinesCount = allLines.length - 1;
    for (let i = 2; i < allLines.length; i++) {
      this.counter++;
      if (allLines[i].split(';')[0] !== '') {
        console.log(
          '#####################################################################################################'
        );
        console.log(
          '#####################################################################################################'
        );
        console.log(
          '################################################ [' +
            i +
            '] ' +
            allLines[i].split(';')[0] +
            ' ##################################################################'
        );
        console.log(
          '####################################################################################################'
        );
        console.log(
          '####################################################################################################'
        );
      }
      if (this.terminate) {
        this.terminate = false;
        this.uploading = false;
        break;
      }

      let line = allLines[i];
      if (line === '') {
        continue;
      }
      line = line.replace(/"/g, '');

      const sampleCsvRow = line.split(';');
      console.log('ROW[' + i + ']:', sampleCsvRow);

      moment.locale('pl');
      const dt = moment(sampleCsvRow[1], 'DD-MMM-YY HH:mm');
      const blockNo = this.findBlockNo(sampleCsvRow[0]);

      if (sampleCsvRow[0] !== '') {
        console.log(
          'Blok:',
          blockNo,
          'Data:',
          dt.format(DATE_TIME_FORMAT),
          'Moc:',
          sampleCsvRow[2],
          'Ciśnienie:',
          sampleCsvRow[3]
        );

        console.log('Lookin for take place', blockNo);
        const tpp = this.takePlaces.find(tp => parseInt(tp['symbol']) === blockNo);
        if (tpp !== undefined) {
          const foundTime = this.shiftHour(tpp, dt);

          const shift = (tpp['shifts'] as []).find(s => {
            console.log(
              's.hour',
              s['year'],
              dt.year(),
              s['month'],
              dt.month() + 1,
              s['day'],
              dt.date(),
              s['hour'],
              foundTime.foundHour,
              s['minute'],
              foundTime.foundMinute
            );
            return (
              s['year'] === dt.year() &&
              s['month'] === dt.month() + 1 &&
              s['day'] === dt.date() &&
              s['hour'] === foundTime.foundHour &&
              s['minute'] === foundTime.foundMinute
            );
          }) as {};
          if (shift !== undefined) {
            console.log('Shift found', shift, tpp['shifts']);
            shift['power'] = sampleCsvRow[2];
            shift['pressure'] = sampleCsvRow[3];

            let indx = 4;
            let objectTypeName = '';
            let paramObject = null;
            let dbParamSymbolText = null;
            let paramSymbol = '';
            for (let objType = 0; objType < this.allObjectTypes.length; objType++) {
              objectTypeName = this.allObjectTypes[objType][0];
              if (blockNo === BLOCK_BIO_NUMBER && objectTypeName === PARA_NASYCONA) {
                objectTypeName = PARA_WTORNA;
              }
              paramObject = this.findParamObject(blockNo, dt, objectTypeName);
              console.log('paramObjectFound', paramObject);

              for (let p = 1; p < this.allObjectTypes[objType].length; p++) {
                dbParamSymbolText = this.mappingParam.find(pp => pp.csvSymbol === this.allObjectTypes[objType][p]);
                if (dbParamSymbolText !== undefined) {
                  paramSymbol = dbParamSymbolText['dbSymbol'];
                } else {
                  paramSymbol = this.allObjectTypes[objType][p];
                }
                if (paramObject !== undefined && 'params' in paramObject) {
                  const param = paramObject.params.find(p => p.symbol_text === paramSymbol);
                  if (param !== undefined) {
                    const prevValue = param.value;
                    param.value = sampleCsvRow[indx];
                    const resp = API.setParamValue({ id: param.id, value: param.value, user: this.currentUser }).catch(
                      function (error) {
                        console.log(error);
                        param.value = prevValue;
                        helpers.error(
                          'Wystąpił błąd podczas aktualizacji parametru, prosimy o zgłoszenie błędu do administratora.\n\n' +
                            error
                        );
                      }
                    );
                  }
                  console.log(
                    'paramSymbol',
                    paramSymbol,
                    ' = ',
                    sampleCsvRow[indx],
                    ' dbParam:',
                    dbParamSymbolText,
                    'PARAM:',
                    param
                  );
                }
                indx++;
              }
            }
          } else {
            console.log('Shif not found');
          }
        }
      }
    }
    this.uploading = false;
    this.disabledOkButton = false;
    clearInterval(this.dotsInterval);
  }

  get currentUser() {
    return this.$store.state.currentUser;
  }

  async importFileChange(e) {
    const response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
    }
    console.log('Import start', e.target.files[0]);
    this.uploading = true;
    const reader = new FileReader();
    this.modal.showModal();

    reader.onload = async function (theFile) {
      console.log('theFile', theFile);
      this.parseCSVLines(reader.result.toString());

      /*
      
      let response = await API.fetchSamples();
      let data = response.data;
      const samples = data.samples;
      response = await API.fetchDicts();
      data = response.data;
      const dicts = data.dicts;

      const func = function () {
        this.dots += '.';
        if (this.dots.length > 3) {
          this.dots = '';
        }
      }.bind(this);
      this.dotsInterval = window.setInterval(func, 500);
      for (let i = 1; i < allLines.length; i++) {
        if (this.terminate) {
          this.terminate = false;
          this.uploading = false;
          break;
        }
        console.log('lp. ', i);
        let line = allLines[i];
        if (line === '') {
          continue;
        }
        line = line.replace(/"/g, '');

        const sampleCsvRow = line.split(';');
        if (samples.find(s => s.number === sampleCsvRow[0])) {
          this.counter++;
          console.log('EXISTS');
        } else if (sampleCsvRow[0] == '') {
          continue;
        } else {
          console.log(sampleCsvRow);

          const NUMBER_COL = 0;
          const HOUR_COL = 2;
          const PLACE_COL = 3;
          const PT_NUMBER_COL = 4;
          const OBJECT_TYPE_ID_COL = 5;
          const IDENTIFIER_COL = 6;
          const VEHICLE_COUNT = 7;
          const TONNAGE_COL = 8;
          const TAKE_PERSON_COL = 9;


          const sample = new types.Sample();
          sample.number = sampleCsvRow[NUMBER_COL];
          const place = dicts.find(d => d.type === 8 && d.value.toLowerCase() === sampleCsvRow[3].toLowerCase());
          if (place) {
            sample.take_place_ids = [{ id: place.id }];
          }

          sample.pt_number = sampleCsvRow[PT_NUMBER_COL];
          sample.object_type_id = dicts.find(
            d => d.type === 4 && d.value.toLowerCase() === sampleCsvRow[OBJECT_TYPE_ID_COL].toLowerCase()
          ).id;
          sample.sample_object_id = dicts.find(d => d.type === 1 && d.value.toLowerCase() === 'biomasa stała').id;
          sample.vehicle_count = parseInt(sampleCsvRow[VEHICLE_COUNT]);
          sample.tonnage = parseFloat(sampleCsvRow[TONNAGE_COL]);
          sample.take_person_string = sampleCsvRow[TAKE_PERSON_COL];
          console.log(this.analysis);
          sample.tests = this.analysis.filter(a => ['132', '134', '135', '138'].includes(a.number));

          const takeHours = sampleCsvRow[HOUR_COL].split(/\r\n|\n/);
          const takeIds = sampleCsvRow[IDENTIFIER_COL].split(/\r\n|\n/);
          sample.sample_dates = [];
          for (let h = 0; h < takeHours.length; h++) {
            const sampleDate = {
              date_from: sampleCsvRow[1],
              time_from: takeHours[h],
              number: takeIds[h],
            };
            sample.sample_dates.push(sampleDate);
          }
          const response = await API.saveSample(sample);
          if (response.data.success) {
            this.counter++;
          } else {
            this.failedCounter++;
          }
        }*/
    }.bind(this);

    reader.readAsText(e.target.files[0], 'UTF-8');
  }
  stopClick() {
    this.disabledOkButton = false;
    this.terminate = true;
  }
}
