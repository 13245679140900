import axios, { AxiosResponse } from 'axios';
import * as types from '@/types';

export const elpolabApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL + 'api/',
  //
});

// Admin
export async function clearDB(): Promise<AxiosResponse> {
  return await elpolabApi.post('/clear_db');
}
export async function log(message: string): Promise<AxiosResponse> {
  return await elpolabApi.post('/log', { message });
}
export async function fireScript(scriptName: string): Promise<AxiosResponse> {
  return await elpolabApi.post('/fire_script', { script_name: scriptName });
}

// Auth
export async function loginUser(user: types.UserSubmit) {
  const response = await elpolabApi.post('/login', { user });
  console.log('api response', response);
  return response;
}
export async function reset(email: string): Promise<any> {
  console.log('BaseURL:', elpolabApi.defaults.baseURL);
  const response = await elpolabApi.post('/reset', { email });
  console.log('Response', response);
  return response;
}
export function setJWT(jwt: string) {
  console.log('JWT set');
  elpolabApi.defaults.headers.common['Authorization'] = `Token ${jwt}`;
}
export function clearJWT() {
  delete elpolabApi.defaults.headers.common['Authorization'];
}

// User
export async function saveUser(user: types.User): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_user', { user });
}
export async function addUserRight(right: types.UserRight): Promise<AxiosResponse> {
  return await elpolabApi.post('/add_user_right', { right });
}
export async function addUserGroup(group: types.UserGroup): Promise<any> {
  return await elpolabApi.post('/add_user_group', { group });
}

export async function fetchUserGroups(): Promise<AxiosResponse> {
  return await elpolabApi.get('/user_groups');
}
export async function getOneUserGroups(userId): Promise<AxiosResponse> {
  return await elpolabApi.get(`/user_groups/${userId}`);
}
export async function fetchUserRights(): Promise<AxiosResponse> {
  return await elpolabApi.get('/user_rights');
}
export async function getOneUserRights(userId: number): Promise<AxiosResponse> {
  return await elpolabApi.get(`/user_rights/${userId}`);
}

export async function fetchUsers(): Promise<AxiosResponse> {
  const response = await elpolabApi.get('/get_users');
  return response;
}
export async function fetchCurrUser(): Promise<AxiosResponse> {
  const response = await elpolabApi.get('/get_curr_user');
  return response;
}
export async function resetPasswordOfUser(user_id: number) {
  const response = await elpolabApi.post('/reset_user_password', { user_id });
  return response;
}

export async function fetchGroups(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_groups');
}

// Client
export async function saveClient(client: types.Client): Promise<AxiosResponse> {
  console.log('API.saveClient', client);
  return await elpolabApi.post('/save_client', {
    client,
  });
}
export async function saveAddress(address: types.Address): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_address', {
    address,
  });
}
export async function deleteAddress(address: types.Address): Promise<AxiosResponse> {
  console.log('API.deleteAddress', address);
  return await elpolabApi.post('/delete_address', {
    address,
  });
}
export async function fetchClients(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_clients');
}
export async function deleteClient(client: types.Client): Promise<AxiosResponse> {
  console.log('API.deleteClient', client);
  return await elpolabApi.post('/delete_client', {
    client,
  });
}
export async function deleteClientByCode(code: string): Promise<AxiosResponse> {
  console.log('API.deleteClientByCode', code);
  return await elpolabApi.post('/delete_client_by_code', {
    code,
  });
}
export async function fetchAddresses(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_addresses');
}

// TO-DO check if needed
// Localization
export async function fetchLocalizations(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_localizations');
}

// Dashboard
export async function fetchStatistics(
  statisticName: types.DashboardStatisticName
): Promise<AxiosResponse<types.DashboardStatistics & { success: boolean }>> {
  return await elpolabApi.get('/get_statistics', { params: { statisticName } });
}

// Samples
export async function saveSample(sample: types.Sample, test?: any, sampleTool?: any): Promise<AxiosResponse> {
  console.log('API.saveSample', { sample, test, sampleTool });
  return await elpolabApi.post('/save_sample', {
    sample,
    test,
    sampleTool,
  });
}
export async function fetchSamples(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_samples');
}

export async function fetchSampleDates(sampleId: number): Promise<AxiosResponse> {
  return await elpolabApi.get(`/get_sample_dates/${sampleId}`);
}
export async function fetchSampleTools(sampleId: number): Promise<AxiosResponse> {
  return await elpolabApi.get(`/get_sample_tools/${sampleId}`);
}
export async function fetchSampleAndTests(id: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_sample_and_tests', id);
}
export async function fetchSampleTests(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_sample_tests');
}
export async function saveSampleTestParams(test: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_sample_test_params', test);
}
export async function savePrepareChecklist(selected: any, sampleId: number): Promise<AxiosResponse> {
  console.log('API.saveSample', { selected, sampleId });
  return await elpolabApi.post('/save_prepare_checklist', { rows: selected, sampleId: sampleId });
}
export async function fetchPrepareChecklist(sampleId: number): Promise<AxiosResponse> {
  return await elpolabApi.get(`/get_prepare_checklist/${sampleId}`);
}
export async function getSampleTest(id: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_sample_test', id);
}
export async function updateSampleStatus(sample_id: number, status_id: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/update_sample_status', { sample_id, status_id });
}

// Packages
export async function fetchPackages() {
  return await elpolabApi.get('/get_packages');
}
export async function fetchPackegeTests(id: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_package_tests', {
    id,
  });
}
export async function savePackage(pckg: any): Promise<AxiosResponse> {
  console.log('API.savePackage', pckg);
  return await elpolabApi.post('/save_package', {
    pckg,
  });
}

// Tests
export async function fetchTests(withParams = true) {
  if (withParams === true) {
    return await elpolabApi.get('/get_tests', { params: { with_params: withParams } });
  } else {
    return await elpolabApi.get('/get_tests', { params: { with_params: withParams } });
  }
}
export async function fetchTest(params: any): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_test', { params: params });
}
export async function fetchTestToolGroups(testId: number): Promise<AxiosResponse> {
  return await elpolabApi.get(`/get_test_tool_groups/${testId}`);
}
// TO-DO  Method->Test in frontend
export async function saveMethod(method: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_test', { method });
}

// EnvCondition
export async function saveEnvCondition(envcond: any): Promise<AxiosResponse> {
  console.log('API.saveEnvCondition', envcond);
  return await elpolabApi.post('/save_envcond', {
    envcond,
  });
}

// TO-DO v2.0 functionality
// Dashboard Action
export async function saveDashboardItem(item: types.DashboardItem): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_dashboard_item', { item });
}
export async function saveDashboardAction(action: types.DashboardAction): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_dashboard_action', { action });
}
export async function saveDashboardItemWithAction(payload: {
  item: types.DashboardItem;
  action: types.DashboardAction;
}): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_dashboard_item_with_action', { payload });
}
export async function fetchDashboardItems(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_dashboard_items');
}
export async function fetchDashboardActions(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_dashboard_actions');
}

// Settings
export async function fetchSettings(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_settings');
}
export async function saveSettings(saveSettings: any, userId: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_settings', { rows: saveSettings, userId: userId });
}

// Tools
export async function saveTool(
  tool: types.Tool,
  new_elements: Array<any>,
  deleted_elements: Array<any>
): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_tool', { tool, deleted_elements, new_elements });
}
export async function fetchTools(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_tools');
}

// Dict
export async function fetchDicts(): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_dicts');
}
export async function fetchStructures(): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_structures');
}
export async function saveDict(dict: {}, structure: {}): Promise<AxiosResponse> {
  console.log('API.saveDict', dict, structure);
  return await elpolabApi.post('/save_dict', {
    dict,
    structure,
  });
}
export async function saveDictWithStructure(dict: {}): Promise<AxiosResponse> {
  console.log('API.saveDict', dict);
  return await elpolabApi.post('/save_dict_with_structure', {
    dict,
  });
}
export async function fetchDictType(dict_type: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_dict', { dict_type });
}
export async function fetchDictsTypes(): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_dicts_types');
}

// File
export async function saveFile(fileData: FormData): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_file', fileData);
}
export async function fetchFiles(id: number, type: string): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_files', { id, type });
}
export async function moveFilesFromTemp(guid: string, id: number) {
  const response = await elpolabApi.post('/move_from_temp', { guid, id });
  return response;
}
export async function removeFile(id: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/remove_file', { id });
}

// Helpers / Utilities
export async function getObject(id: number, model: string): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_object', { id, model });
}
export async function saveObjectModel(object: any, model: string) {
  const response = await elpolabApi.post('/save_model', { object, model });
  return response;
}
export async function fetchModel(model: string): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_full', { model });
}
export async function deleteObjectModelById(id, model: string) {
  const response = await elpolabApi.post('/delete_model_by_id', { id, model });
  return response;
}
export async function fetchFilterdModel(
  model: string,
  filters: object,
  sortParams: object,
  page: number,
  pageSize: number
) {
  return await elpolabApi.post('get_full_filtered', { model, filters, sortParams, page, pageSize });
}

// ResultsDoc
export async function fetchFilteredSamplesResultCreator(filterData: types.FilterData) {
  return await elpolabApi.post('get_samples_result_creator', filterData);
}
export async function generateResult(ids: Array<number>, template: {}, user_id: number): Promise<AxiosResponse> {
  return await elpolabApi.post('/generate_result', { ids, template, user_id });
}
export async function swapFile(fileData: FormData): Promise<AxiosResponse> {
  return await elpolabApi.post('/swap_resultsdoc', fileData);
}
export async function deleteResultsDoc(id: number) {
  return await elpolabApi.post('/delete_results_doc', { id });
}
export async function updateUserSignature(id: number) {
  return await elpolabApi.post('/update_user_signature', { id });
}

// Readings
export async function initiateDay(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/initiate_day', { params });
}

export async function fetchReadings(params: any): Promise<AxiosResponse> {
  return await elpolabApi.get('/readings', { params: params });
}
export async function fetchAdditionalSamples(params: any): Promise<AxiosResponse> {
  return await elpolabApi.get('/additional_samples', { params: params });
}

export async function setParamValue(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/set_param_value', { params });
}
export async function setParamExtendedValue(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/set_param_extended_value', { params });
}

export async function setShiftValue(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/set_shift_value', { params });
}

export async function connectReadingWithSample(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/connect_reading_with_sample', { params });
}

export async function addAdditionalShift(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/add_additional_shift', { params });
}

export async function exportXLS(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/export_xls', { params });
}

export async function checkExportXLS(): Promise<AxiosResponse> {
  return await elpolabApi.get('/check_export_xls');
}

export async function saveReadingDefinition(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_reading_definition', { params });
}

export async function fetchTestParam(params: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/get_test_param', { params });
}

export async function deleteShift(shift_id: number): Promise<AxiosResponse> {
  return await elpolabApi.delete(`/shifts/${shift_id}`);
}

// Personel / Auths
export async function fetchSpecialAuths(params = {}): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_special_auths', params);
}

export async function fetchProcessAuths(params = {}): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_process_auths', params);
}

export async function fetchUserSpecialAuths(params = {}): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_user_special_auths', params);
}

export async function fetchUserProcessAuths(params = {}): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_user_process_auths', params);
}
export async function fetchUserMethodAuths(params = {}): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_user_method_auths', params);
}
export async function fetchUserToolAuths(params = {}): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_user_tool_auths', params);
}
export async function fetchAuths(): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_auths');
}
export async function fetchUserAuths(user_id): Promise<AxiosResponse> {
  return await elpolabApi.get('/get_user_auths', { params: { user_id: user_id } });
}
export async function savePerson(person: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_person', { person });
}

// Activities
export async function addActivity(activity: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/activities/', { activity });
}
export async function fetchActivities(person: any): Promise<AxiosResponse> {
  return await elpolabApi.get('/activities/');
}

// Doc templates
export async function saveDocTemplate(docTemplate: any): Promise<AxiosResponse> {
  return await elpolabApi.post('/save_doc_template', docTemplate);
}
