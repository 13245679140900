

































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import * as API from '@/store/api';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import ModalExamFilter from '../../../components/spec/ModalExamFilter.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import * as helpers from '../../../helpers';
import Modal from '../../../components/spec/Modal.vue';
import * as consts from '../../../consts';
import store from '../../../store';
import * as types from '../../../types';
import { filter } from 'vue/types/umd';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class ResultCreatorList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly list: GenericList;
  params = new Array<types.ExtendedParam>();
  @Ref() readonly modal: Modal;
  objects = [];
  modalParams = new Array<types.ExtendedParam>();
  show = true;
  object = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<types.ExtendedParam>();
  @Ref() filterForm: GenericForm;
  allDicts = [];
  trackedSamples = [];
  templateSelected: any = {};
  checkedAll = false;

  async proxyFilter(filterData: types.FilterData) {
    filterData.additionalData = {
      accreditation: this.templateSelected.accreditation,
    };
    return await API.fetchFilteredSamplesResultCreator(filterData);
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    this.trackedSamples = [];
    for (const object of this.list.objectsVisible) {
      object.$checked = this.checkedAll;
      if (this.checkedAll) {
        this.addCheckedSample(object);
      }
    }
  }

  selectTemplate(e: types.DocTemplate) {
    console.log('selectTemplate', e);

    this.templateSelected = Object.assign(new types.DocTemplate(), e);
    this.filterObject = this.templateSelected.getAsFilterData();

    console.log(this.filterObject, 'loaded Filter object');

    this.trackedSamples = [];
    console.log('selectTemplate::Filter Object', this.filterObject);
  }

  allTemplates: Array<types.DocTemplate> = [];

  samplesOverCap() {
    return (
      this.templateSelected.maximum_sample_count &&
      this.trackedSamples.length == this.templateSelected.maximum_sample_count
    );
  }

  addCheckedSample(sample: any) {
    const foundIndex = this.trackedSamples.findIndex(el => {
      console.log(el.id, sample.id);
      return el.id === sample.id;
    });
    if (foundIndex === -1) {
      console.log('tracking.... ', sample);
      if (this.samplesOverCap()) {
        const shiftedSample = this.trackedSamples.shift();
        shiftedSample.$checked = false;
        this.list.$forceUpdate();
      }

      this.trackedSamples.push(sample);
      sample.$checked = true;
    } else {
      console.log('untracking', sample);
      this.trackedSamples.splice(foundIndex, 1);
      sample.$checked = false;
    }
  }

  processLoaded(object: any) {
    if (this.trackedSamples.includes(object.id)) {
      object.$checked = true;
    } else {
      object.$checked = false;
    }
  }

  showModal(object: any) {
    this.object = object;
    this.modal.showModal();
  }

  async openFilterModal() {
    await this.modalFilter.showModal();
  }

  generateResultsDoc() {
    console.log('generateResultsDoc:', this.trackedSamples);
    store.commit('setResultsDocSampleIds', {
      ids: this.trackedSamples.map(el => el.id),
      template: this.templateSelected,
    });
    this.$router.push({ name: 'resultsDocF10' });
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  sendFilterQuery(e: any) {
    console.log('OK', this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  get filtersF03() {
    const oOdpadPal = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT, consts.Objects.ODPAD_PAL);
    const sSR = helpers.getDictValue(this.allDicts, consts.DictType.SOURCE, consts.Sources.SR);
    const otPopiol = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT_TYPE, consts.ObjectTypes.POPIOL);
    const otZuzel = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT_TYPE, consts.ObjectTypes.ZUZEL);
    const m115 = helpers.getDictValue(this.allDicts, consts.DictType.TAKE_METHOD, consts.TakeMethods.M115);
    const TakePlaces = consts.TakePlaces;
    let takePlaces = [
      TakePlaces.BLOK_1,
      TakePlaces.BLOK_2,
      TakePlaces.BLOK_3,
      TakePlaces.BLOK_4,
      TakePlaces.BLOK_5,
      TakePlaces.BLOK_6,
      TakePlaces.BLOK_7,
    ];
    takePlaces = takePlaces.map(tp => helpers.getDictValue(this.allDicts, consts.DictType.TAKE_PLACE, tp).id);

    return {
      sample_object_id: oOdpadPal.id,
      source_id: sSR.id,
      object_type_id__in: [otPopiol.id, otZuzel.id],
      take_method_ids: [m115.id],
      take_place_ids: takePlaces,
      tests: [consts.Tests.STRATY_PRZY_PRAZENIU],
      client_id: 1,
    };
  }

  useHardCoded = false;

  async created() {
    console.log('%c---------------------------- Creator created start ---------------------', 'color:red');
    const response = await API.fetchDicts();
    this.allDicts = response.data.dicts;
    console.log('Created::allDicts', this.allDicts);

    const sourceNawegl = helpers.getDictValue(this.allDicts, consts.DictType.SOURCE, consts.Sources.NAWEGL);
    const typeBiomasa = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT, consts.Objects.BIOMASA);

    if (!this.useHardCoded) {
      const array = await helpers.getModelAsArray('DocTemplate');
      this.allTemplates = array;
    } else {
      this.allTemplates = [];
    }
    console.log('created all templates', this.allTemplates);
    this.selectTemplate(this.allTemplates[0]);

    this.filterParams = [
      {
        fieldType: 'multiselect',
        value: '$sample_object_id',
        headerName: 'Obiekt',
        options: await helpers.getDictAsArray(consts.DictType.OBJECT),
        engine: new types.StandardMultiselectEngine('sample_object_id'),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$object_type_id',
        headerName: 'Typ obiektu',
        action: (e: any, obj: any) => {
          obj.object_type_id = e.id;
        },
        options: await helpers.getDictAsArray(consts.DictType.OBJECT_TYPE),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Metoda analizy',
        action: (e: any, obj: any) => {
          obj.object_type = e.id;
        },
        options: await helpers.getDictAsArray(2),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Miejsce pobrania',
        action: (e: any, obj: any) => {
          obj.object_type = e.value;
        },
        options: await helpers.getDictAsArray(3),
        multiSelectTrack: 'value',
      },

      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Metoda pobrania',
        action: (e: any, obj: any) => {
          obj.take_method = e.value;
        },
        options: [{ value: 'Ręczna' }, { value: 'Automatyczna' }],
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Pochodzenie',
        action: (e: any, obj: any) => {
          obj.source_id = e.id;
        },
        options: await helpers.getDictAsArray(5),
        multiSelectTrack: 'value',
      },

      {
        fieldType: 'date',
        value: 'date',
        headerName: 'Data rejestracji od',
        fieldCss: 'w-50',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.create_date_time__gte = dt.format(consts.DB_DATE_FORMAT);
          object.date = undefined;
        },
      },

      {
        fieldType: 'date',
        value: 'date',
        headerName: 'Data rejestracji do',
        fieldCss: 'w-50',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.create_date_time__lte = dt.format(consts.DB_DATE_FORMAT);
          object.date = undefined;
        },
      },
    ];

    this.params = [
      {
        headerName: 'NUMER',
        value: '$number',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'TYP OBIEKTU',
        value: '$object_type__value',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'POCHODZENIE',
        value: '$source__value',
      },
      {
        headerName: 'DATA REJESTRACJI',
        value: '$create_date_time',
        evaluate: (obj: any) => {
          return moment(obj.create_datetime).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'DATA POBRANIA',
        value: '$take_date',
        sortField: 'create_date_time',
        evaluate: (obj: any) => {
          return moment(obj.take_date).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerCss: 'col-2',
        headerName: 'Nazwa klienta',
        value: '$client__name',
      },
    ];

    this.modalParams = [
      {
        headerName: 'Imię',
        fieldType: 'input',
        value: 'firstname',
      },
      {
        fieldType: 'input',
        headerName: 'Nazwisko',
        value: 'lastname',
      },
      {
        headerName: 'Pozycja',
        fieldType: 'input',
        value: 'position',
      },
    ];

    console.log(this.objects, 'fetched objects');
    console.log('%c---------------------------- Creator created end---------------------', 'color:red');
  }
}
