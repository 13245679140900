import Vue from 'vue';
import { PageMode } from '@/consts';
import VueRouter, { RouteConfig } from 'vue-router';
import * as consts from '@/consts';

import Client from '../views/Client.vue';

import Login from '../views/Login.vue';
import Laborant from '../views/Laborant.vue';

import LaborantExams from '@/views/laborant/laboratorium/ExamList.vue';

// ADMINISTRATION
import MethodList from '@/views/laborant/administration/MethodList.vue';
import ToolList from '@/views/laborant/administration/ToolList.vue';
import EnvConditionList from '@/views/laborant/administration/EnvConditionList.vue';
import PersonelModal from '@/views/laborant/administration/PersonelModal.vue';
import TrainingList from '@/views/laborant/administration/TrainingList.vue';
import DictList from '@/views/laborant/administration/DictList.vue';
import DocTemplateList from '@/views/laborant/administration/DocTemplateList.vue';
import PersonelList from '@/views/laborant/administration/PersonelList.vue';
import ReadingDefinitionList from '@/views/laborant/administration/ReadingDefinitionList.vue';
import DashboardActions from '@/views/laborant/administration/DashboardActions.vue';
import ResultDocsList from '@/views/laborant/administration/ResultDocsList.vue';
import ExamCard from '@/views/laborant/exams/ExamCard.vue';
import ResultCreatorList from '@/views/laborant/administration/ResultCreatorList.vue';

import ClientList from '@/views/laborant/dicts/ClientList.vue';
import PackageList from '../views/laborant/dicts/PackageList.vue';

import Test from '@/views/Test.vue';

import Dashboard from '@/views/laborant/Dashboard.vue';
import Register from '@/views/laborant/Register.vue';
import SamplesBoard from '@/views/laborant/SamplesBoard.vue';
import ReadingsBoard from '@/views/laborant/ReadingsBoard.vue';

import ResultsDocF10 from '../views/laborant/documents/ResultsDocF10.vue';
import Admin from '@/views/admin/Admin.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    name: 'client',
    path: '/client/',
    component: Client,
    props: true,
    children: [{ path: '', redirect: 'clientoffers' }],
  },
  {
    path: '/lab/',
    component: Laborant,
    props: true,
    children: [
      { path: '', redirect: 'dashboard' },

      {
        path: 'dashboard',
        component: Dashboard,
        props: true,
      },

      {
        path: 'register',
        component: Register,
        props: true,
      },

      {
        path: 'labexams',
        component: LaborantExams,
        props: true,
      },
      {
        path: 'samplesboard',
        component: SamplesBoard,
        props: true,
      },
      {
        path: 'readingsboard',
        component: ReadingsBoard,
        props: true,
      },
      {
        path: '/examcard/:id',
        name: 'examcard',
        component: ExamCard,
        props: true,
      },

      {
        path: 'methodlist',
        component: MethodList,
        props: true,
      },
      {
        path: 'toollist',
        component: ToolList,
        props: true,
      },
      {
        path: 'trainings',
        component: TrainingList,
        props: true,
      },
      {
        path: 'result_docs',
        component: ResultCreatorList,
        props: true,
      },
      {
        path: 'reports',
        component: ResultDocsList,
        props: true,
      },
      {
        path: 'resultsDocF10/:id?',
        name: 'resultsDocF10',
        props: true,
        component: ResultsDocF10,
      },
      {
        path: 'personellist',
        component: PersonelList,
        props: true,
      },
      {
        path: 'readingsdefinitionlist',
        component: ReadingDefinitionList,
        props: true,
      },
      {
        path: 'dashboardactions',
        name: 'dashboardactions',
        component: DashboardActions,
        props: true,
      },

      /************************ DICTS ************************/
      {
        path: 'dicts',
        name: 'DictList',
        component: DictList,
        props: true,
      },
      {
        path: 'dicts/:type',
        name: 'DictList',
        component: DictList,
      },
      {
        path: '/test',
        name: 'Test',
        component: Test,
      },
      {
        path: 'clientlist',
        name: 'ClientList',
        component: ClientList,
      },
      {
        path: 'packagelist',
        name: 'PackageList',
        component: PackageList,
      },
      {
        path: 'envconditionlist',
        name: 'EnvConditionList',
        component: EnvConditionList,
      },
      {
        path: 'doctemplate',
        component: DocTemplateList,
        props: true,
      },
      {
        path: 'admin',
        component: Admin,
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
