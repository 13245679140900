console.log('--- CONSTS FILE LOADING ---');
import { Enumify } from 'enumify';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DB_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';

export const NEW_ID_START = 100000;
export const TEST_ID_FIRST = 200000;
export const TEST_ID_SECOND = 200001;
export const TEST_ID_THIRD = 200002;

export const TEMP_SERVER_ID = 500000;
export const MAX_LEVELS = 4;
export const CLIENT_ID_ELEKTROWNIA = 1;

export const SECTION_DICT_TYPE = 3;

export default interface EnumComplex {
  id: number;
  name: string;
  symbol?: string;
}

export const Months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listapad',
  'Grudzień',
];

export enum ModalEvents {
  CANCEL_EVENT = 'modal_cancel',
  OK_EVENT = 'modal_ok',
  TIME_CHANGED_EVENT = 'time_changed',
  DATE_CHANGED_EVENT = 'date_changed',
}

export enum ListEvents {
  PAGE_SIZE = 'PAGESIZE',
  FILTER = 'FILTER',
}

export enum PageMode {
  PREVIEW = 'preview',
  NEW = 'new',
  EDIT = 'edit',
}

export enum CalendarViewType {
  DAY = 'day',
  MONTH = 'month',
  PERSON_DAY = 'person_day',
  VISIT = 'visit',
}

export enum ClientType {
  INTERNAL = 1,
  EXTERNAL = 2,
}

export enum Clients {
  ENEA = 1,
}

export class OrderStatus extends Enumify {
  static NEW = new OrderStatus(0, 'NOWE', 'NEW');
  static ORDERED = new OrderStatus(1, 'ZŁOŻONE', 'ORD');
  static PENDING = new OrderStatus(2, 'W TRAKCIE', 'PEN');
  static FINISHED = new OrderStatus(3, 'WYKONANE', 'DON');
  static CANCELED = new OrderStatus(4, 'ANULOWANE', 'CNL');
  static _ = OrderStatus.closeEnum();

  private static array = [
    OrderStatus.NEW,
    OrderStatus.ORDERED,
    OrderStatus.PENDING,
    OrderStatus.FINISHED,
    OrderStatus.CANCELED,
  ];
  id: number;
  name: string;
  symbol: string;

  constructor(id: number, name: string, symbol: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
  }

  public static byId(id: number): OrderStatus {
    return this.array[id];
  }
}

export class VisitStatus extends Enumify {
  static PLANNED = new VisitStatus(0, 'ZAPLANOWANA', 'PLN', 'primary');
  static PENDING = new VisitStatus(1, 'W TRAKCIE', 'PEN', 'info');
  static CORRECT = new VisitStatus(2, 'W KOREKCIE', 'CRT', 'warning');
  static CLOSED = new VisitStatus(3, 'ZAMKNIĘTA', 'CLS', 'secondary');
  static VERIFY = new VisitStatus(4, 'SPRAWDZENIE', 'VER', 'primary');
  static VERIFIED = new VisitStatus(5, 'SPRAWDZONA', 'VRD', 'success');
  static CANCELED = new VisitStatus(6, 'ANULOWANA', 'CNL', 'danger');
  static _ = VisitStatus.closeEnum();

  private static array = [
    VisitStatus.PLANNED,
    VisitStatus.PENDING,
    VisitStatus.CORRECT,
    VisitStatus.CLOSED,
    VisitStatus.VERIFY,
    VisitStatus.VERIFIED,
    VisitStatus.CANCELED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): VisitStatus {
    return this.array[id];
  }
}

export enum VisitType {
  MAIN = 1, // pobranie i badanie
  CTB = 2, // Odebranie uformowanych
  COLLECT = 3, // Odebranie - klient uformowal
  CERT = 4, // certyfikacja
}

export enum VisitViewType {
  MAIN = 'main',
  PROTOCOL = 'protocol',
  MIXER = 'mixer',
  COLLECT = 'collect',
  SAMPLE_LIST = 'sample-list',
}

export enum Objects {
  WEGIEL = 'Węgiel kamienny',
  BIOMASA = 'Biomasa stała',
  ODPAD_PAL = 'Odpad paleniskowy',
}

export enum ObjectTypes {
  POPIOL = 'Popiół',
  ZUZEL = 'Żużel',
}

export enum Sources {
  DOSTAWY_K = 'dostawy kolejowe',
  NAWEGL = 'Nawęglanie',
  SR = 'Sekcja ruchowa',
}

export enum TakeMethods {
  SR = 'Sekcja ruchowa',
  M115 = 'metoda 115',
}

export enum TakePlaces {
  BLOK_1 = 'Blok 1',
  BLOK_2 = 'Blok 2',
  BLOK_3 = 'Blok 3',
  BLOK_4 = 'Blok 4',
  BLOK_5 = 'Blok 5',
  BLOK_6 = 'Blok 6',
  BLOK_7 = 'Blok 7',
}

export class Tests {
  static STRATY_PRZY_PRAZENIU = {
    name: 'Oznaczanie strat przy prażeniu (części palnych)',
    norm: 'w oparciu o PN-77/G-04528/02',
  };
}

export enum DictType {
  _,
  OBJECT = 1,
  METHOD = 2,
  SECTION = 3,
  OBJECT_TYPE = 4,
  MATERIAL = 5,
  SOURCE = 6,
  TAKE_METHOD = 7,
  TAKE_PLACE = 8,
  PREPARE_NORM = 9,
  CALC_NORM = 10,
  AREA = 11,
  ROOM = 12,
  SPECIAL_RIGHT = 13,
  PROCESS_RIGHT = 14,
}

export enum ObjectsStr {
  WEGIEL = 'Węgiel kamienny',
  BIOMASA = 'Biomasa stała',
  WODY_TECH = 'Wody technologiczne',
}

export enum SourcesStr {
  SR = 'Sekcja ruchowa',
}

export enum ObjectsIDs {
  WODY_TECH = 2,
}

export const BLOK_POZA = 'Bloki poza grafikiem';

export const DictTypeTitles = [
  '',
  'Obiekty',
  'Metody analiz',
  'Sekcje',
  'Typy obiektów',
  'Materiały',
  'Pochodzenia',
  'Metody pobrania',
  'Miejsca pobrania',
  'Normy przygotowania',
  'Normy przeliczenia',
  'Obszary badań',
  'Pomieszczenia',
  'Upoważnienia specjalne',
  'Upoważnienia procesowe',
];

export const Hours = [
  {
    id: 0,
    name: '7:00',
    value: 7,
  },
  {
    id: 1,
    name: '15:00',
    value: 15,
  },
  {
    id: 2,
    name: '23:00',
    value: 23,
  },
];

export enum OfferStatus {
  PREPARED = 'SAV', // przygotowana, zapisana, jeszcze nie widzi jej klient
  SENT = 'SNT', // oferta wystawiona dla klienta / wyslana
  ACCEPTED = 'ACC', // zaakceptowna przez klienta
  REJECTED = 'RJT', // odrzucona - celowo, nie dotyczy ofert ktore po prostu nie wygraly
}

export enum OfferSettlements {
  LUMP_SUM = 'RYCZ',
  FIX = 'FIX',
  MANY_TESTS = 'MANY',
  SPEC_TESTS = 'SPEC',
  CLIENT_PROTOCOL = 'CLI_PROTO',
}

export enum SortDir {
  ASC,
  DESC,
}

export const INITIAL_SORT_FIELD = 'id';
export const LABORANT_ROUTE_PREFIX = '/laborant';

export enum ExamType {
  CONCRETE,
  GEO,
  CERT,
}

export enum UserRightSymbols {
  VISIT_START = 'VISIT_START',
  VISIT_COORD = 'VISIT_COORD',
  VISIT_TO_CORRECT = 'VISIT_TO_CORRECT',
  VISIT_MAKE_CORRECT = 'VISIT_MAKE_CORRECT',
  VISIT_PREVIEW = 'VISIT_PREVIEW',
  VISIT_EDIT = 'VISIT_EDIT',
  VISIT_AUTHORISE = 'VISIT_AUTHORISE',
  VISIT_START_SIGN = 'VISIT_START_SIGN',
  VISIT_REVERT = 'VISIT_REVERT',
  VISIT_CANCEL = 'VISIT_CANCEL',
  VISIT_VERIFY = 'VISIT_VERIFY',
  VISIT_OPEN_AGAIN = 'VISIT_OPEN_AGAIN',
  VISIT_ACCEPT = 'VISIT_ACCEPT',

  TOOL_DASHBOARD_PREVIEW = 'TOOL_DASHBOARD_PREVIEW',
  TOOL_DASHBOARD_INIT = 'TOOL_DASHBOARD_INIT',
  TOOL_DASHBOARD_ACCEPT = 'TOOL_DASHBOARD_ACCEPT',
  TOOL_DASHBOARD_PROCESS = 'TOOL_DASHBOARD_PROCESS',
}

export enum ProtocolType {
  PIB = 1, // pobrania i badania
  COLLECT_CTB = 2, // odebranie probek uformowanych
  COLLECT = 3, // odebrania probek
}
export enum ProtocolTypeString {
  PIB = 'pib', // pobrania i badania
  COLLECT_CTB = 'ctb', // odebranie probek uformowanych
  COLLECT = 'collect', // odebrania probek
}

export enum TakeExamPlaces {
  BATCHING_PLANT = 'W - węzeł betoniarski', // Węzeł betoniarski
  CONSTRUCTION_SITE = 'B - budowa', // Budowa
}

export enum ConsistencyNorms {
  S = '12350-2',
  F = '12350-5',
  SF = '12350-8',
}

export enum ProtocolNorms {
  TAKE_SAMPLE = '12350-1',
  MIXTURE_DENSITY = '12350-6',
  MIXTURE_AIR_CONTENT = '1250-7',
}

export enum MakeSampleNorms {
  C = '12350-2',
  ALL = 'PN-88',
}

export class CollectExamType extends Enumify {
  static S = new CollectExamType(1, 'Klasa konsystencji S', 'S', 'Opad stożka', 'PN-EN ' + ConsistencyNorms.S);
  static F = new CollectExamType(
    2,
    'Klasa konsystencji F (rozpływ)',
    'F',
    'Metoda stolika rozpływowego',
    'PN-EN ' + ConsistencyNorms.F
  );
  static SF = new CollectExamType(
    3,
    'Klasa konsystencji SF, lepkości VS',
    'SF',
    'Rozpływ stożka i czas rozplywu',
    'PN-EN ' + ConsistencyNorms.SF
  );

  static _ = CollectExamType.closeEnum();

  private static array = [CollectExamType.S, CollectExamType.F, CollectExamType.SF];
  id: number;
  name: string;
  symbol: string;
  method_name: string;
  norm: string;

  constructor(id: number, name: string, symbol: string, method_name: string, norm: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.method_name = method_name;
    this.norm = norm;
  }

  public static byId(id: number): CollectExamType {
    return this.array.find(e => e.id === id);
  }
}

export enum GroupClassSymbols {
  COMPRESSIVE_STREGTH = 'C',
  COMPRESSIVE_STREGTH_LIGHT = 'LC',
  CONSISTENCY = 'S',
  CONSISTENCY_CONE = 'SF',
  CONSISTENCY_TABLE = 'F(rozpływ)',
  CHLORIDE = 'Cl',
  EXPOSURE = 'X',
  VISCOSITY = 'VF',
  FROZEN = 'F',
  WATER = 'W',
}

export enum DestinyGroupCodes {
  COMPRESSIVE_STREGTH = 'C',
  FROZEN = 'F',
  WATER = 'W',
}

export enum ExamComplience {
  Z = 'Zgodny',
  NZ = 'Niezgodny',
}

export enum SlumpTestType {
  OK = 'WŁAŚCIWY',
  NOK = 'ŚCIĘTY',
}

export enum SignatureType {
  VISIT,
  PROTOCOL,
}
export enum ModuleType {
  LAB = '1',
  CLIENT = '2',
  ADMIN = '3', //to pewnie trzeba bedzie zmienic
}
export enum ModuleTypeString {
  LAB = 'lab',
  CLIENT = 'client',
  ADMIN = 'admin', //to pewnie trzeba bedzie zmienic
}

/*************************************** ACTION STATUSES ********************************/

export class ToolActionStatus extends Enumify {
  static NEW = new ToolActionStatus(0, 'NOWE', 'NEW', 'primary');
  static INITIAL = new ToolActionStatus(1, 'ZAINICJOWANA', 'INI', 'info');
  static PENDING = new ToolActionStatus(2, 'W TRAKCIE', 'PEN', 'success');
  static TOACCEPT = new ToolActionStatus(3, 'AKCEPTACJA', 'ACC', 'danger');
  static DONE = new ToolActionStatus(4, 'UKOŃCZONA', 'DON', 'warning');
  static CANCELLED = new ToolActionStatus(5, 'WYCOFANA', 'CNL', 'secondary');
  static _ = ToolActionStatus.closeEnum();

  private static array = [
    ToolActionStatus.INITIAL,
    ToolActionStatus.NEW,
    ToolActionStatus.PENDING,
    ToolActionStatus.DONE,
    ToolActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): ToolActionStatus {
    return this.array[id];
  }
}

export class MethodActionStatus extends Enumify {
  static NEW = new MethodActionStatus(0, 'NOWA', 'NEW', 'primary');
  static INITIAL = new MethodActionStatus(1, 'ZAINICJOWANE', 'INI', 'info');
  static PENDING = new MethodActionStatus(2, 'W TRAKCIE', 'PEN', 'success');
  static TOACCEPT = new MethodActionStatus(3, 'AKCEPTACJA', 'ACC', 'danger');
  static DONE = new MethodActionStatus(4, 'UKOŃCZONE', 'DON', 'warning');
  static CANCELLED = new MethodActionStatus(5, 'WYCOFANE', 'CNL', 'secondary');
  static _ = MethodActionStatus.closeEnum();

  private static array = [
    MethodActionStatus.INITIAL,
    MethodActionStatus.NEW,
    MethodActionStatus.PENDING,
    MethodActionStatus.DONE,
    MethodActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): MethodActionStatus {
    return this.array[id];
  }
}

export class PersonelActionStatus extends Enumify {
  static NEW = new PersonelActionStatus(0, 'NOWY', 'NEW', 'primary');
  static INITIAL = new PersonelActionStatus(1, 'ZAINICJOWANE', 'INI', 'info');
  static PENDING = new PersonelActionStatus(2, 'W TRAKCIE', 'PEN', 'success');
  static TOACCEPT = new PersonelActionStatus(3, 'AKCEPTACJA', 'ACC', 'danger');
  static DONE = new PersonelActionStatus(4, 'UKOŃCZONE', 'DON', 'warning');
  static CANCELLED = new PersonelActionStatus(5, 'WYCOFANE', 'CNL', 'secondary');
  static _ = PersonelActionStatus.closeEnum();

  private static array = [
    PersonelActionStatus.INITIAL,
    PersonelActionStatus.NEW,
    PersonelActionStatus.PENDING,
    PersonelActionStatus.DONE,
    PersonelActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): PersonelActionStatus {
    return this.array[id];
  }
}

export class OfferActionStatus extends Enumify {
  static REQUEST = new OfferActionStatus(1, 'ZAPYTANIA OFERTOWE', 'REQ', 'info');
  static OFFER = new OfferActionStatus(2, 'OFERTA', 'OFR', 'success');
  static TOACCEPT = new OfferActionStatus(3, 'AKCEPTACJA KLIENTA', 'ACC', 'danger');
  static CONTRACT = new OfferActionStatus(4, 'UMOWA', 'DON', 'warning');
  static CANCELLED = new OfferActionStatus(5, 'ANULOWANE', 'CNL', 'secondary');
  static _ = OfferActionStatus.closeEnum();

  private static array = [
    null, //nie ma id
    OfferActionStatus.REQUEST,
    OfferActionStatus.OFFER,
    OfferActionStatus.TOACCEPT,
    OfferActionStatus.CONTRACT,
    OfferActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): OfferActionStatus {
    return this.array[id];
  }
}

export class CertActionStatus extends Enumify {
  static INITIAL = new CertActionStatus(1, 'NOWY DOKUMENT', 'REQ', 'info');
  static AFTER = new CertActionStatus(2, 'DOKUEMNT W EDYCJI', 'AFT', 'success');
  static TOACCEPT = new CertActionStatus(3, 'AKCEPTACJA', 'ACC', 'danger');
  static CERTYFICATE = new CertActionStatus(4, 'ZATWIERDZONY', 'CRT', 'warning');
  static CANCELLED = new CertActionStatus(5, 'ODRZUCONY', 'CNL', 'secondary');
  static _ = CertActionStatus.closeEnum();

  private static array = [
    CertActionStatus.INITIAL,
    CertActionStatus.AFTER,
    CertActionStatus.TOACCEPT,
    CertActionStatus.CERTYFICATE,
    CertActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): CertActionStatus {
    return this.array[id];
  }
}
export class SettlementActionStatus extends Enumify {
  static REQUEST = new SettlementActionStatus(1, 'WYSTAWIONE', 'REQ', 'info');
  static LAB = new SettlementActionStatus(2, 'ZATWIERDZONE PRZEZ LAB', 'LAB', 'success');
  static ACCEPTED = new SettlementActionStatus(3, 'ZAAKCEPTOWANE PRZEZ KLIENTA', 'ACC', 'danger');
  static ACCOUNT_SENT = new SettlementActionStatus(4, 'WYSŁANE DO KSIĘGOWOŚCI', 'SNT', 'warning');
  static INVOICE = new SettlementActionStatus(5, 'Z FAKTURĄ', 'INV', 'secondary');
  static _ = SettlementActionStatus.closeEnum();

  private static array = [
    SettlementActionStatus.REQUEST,
    SettlementActionStatus.LAB,
    SettlementActionStatus.ACCEPTED,
    SettlementActionStatus.ACCOUNT_SENT,
    SettlementActionStatus.INVOICE,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): SettlementActionStatus {
    return this.array[id];
  }
}

/*********************************** OPERATION TYPES **********************************/

export class ToolOperationType extends Enumify {
  static ADD = new ToolOperationType(0, 'Wdrożenie', 'NEW', 'primary');
  static EDIT = new ToolOperationType(1, 'Edycja', 'EDI', 'info');
  static SERVICE = new ToolOperationType(2, 'Awaria', 'SRV', 'success');
  static REJECT = new ToolOperationType(3, 'Odrzucenie', 'RJC', 'danger');
  static REVERT = new ToolOperationType(4, 'Wycofanie', 'RVT', 'secondary');
  static _ = ToolOperationType.closeEnum();

  private static array = [
    ToolOperationType.ADD,
    ToolOperationType.EDIT,
    ToolOperationType.SERVICE,
    ToolOperationType.REJECT,
    ToolOperationType.REVERT,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): ToolOperationType {
    return this.array[id];
  }
}

export const docTemplateClientType = {
  ANY: 0,
  EXTERNAL_CLIENT: 1,
  INTERNAL_CLIENT: 2,
};

export class ReportStatus extends Enumify {
  static DONE = new ReportStatus(0, 'SPORZĄDZONY', 'primary');
  static ACCEPTED = new ReportStatus(1, 'ZAAKCEPTOWANY', 'success');
  static AUTHORISED = new ReportStatus(2, 'ZAUTORYZOWANY', 'info');
  static CLOSED = new ReportStatus(3, 'CLOSED', 'danger');

  static arr = [ReportStatus.DONE, ReportStatus.ACCEPTED, ReportStatus.AUTHORISED];

  id: number;
  name: string;
  badge: string;
  constructor(id, name, badge) {
    super();
    this.id = id;
    this.name = name;
    this.badge = badge;
  }
}

export class ReportOperation extends Enumify {
  static CLOSE = new ReportOperation(0, 'CLOSE', 'Zamknij');
  static OPEN = new ReportOperation(1, 'OPEN', 'Otwórz');
  static SEND = new ReportOperation(2, 'SEND', 'Wyślij');
  static DELETE = new ReportOperation(3, 'DELETE', 'Usuń');

  static arr = [ReportOperation.CLOSE, ReportOperation.OPEN, ReportOperation.SEND, ReportOperation.DELETE];

  id: number;
  name: string;
  title: string;
  constructor(id, name, title) {
    super();
    this.id = id;
    this.name = name;
    this.title = title;
  }
}

export class MethodOperationType extends Enumify {
  static ADD = new MethodOperationType(0, 'Wdrożenie', 'NEW', 'primary');
  static CLONE = new MethodOperationType(2, 'Sklonuj', 'CLO', 'primary');
  static REJECT = new MethodOperationType(3, 'Wycofaj', 'RJC', 'secondary');
  static REVERT = new MethodOperationType(4, 'Przwyróć', 'RVT', 'secondary');
  static _ = MethodOperationType.closeEnum();

  private static array = [
    MethodOperationType.ADD,
    MethodOperationType.CLONE,
    MethodOperationType.REJECT,
    MethodOperationType.REVERT,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): MethodOperationType {
    return this.array[id];
  }
}

export class SampleOperationType extends Enumify {
  static EXAM = new SampleOperationType(0, 'BADANIE', 'EXM', 'primary');
  static _ = SampleOperationType.closeEnum();

  private static array = [SampleOperationType.EXAM];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): SampleOperationType {
    return this.array[id];
  }
}

export class PersonelOperationType extends Enumify {
  static ADD = new PersonelOperationType(0, 'Wdrożenie', 'NEW', 'primary');
  static SET = new PersonelOperationType(1, 'Ustaw', 'EDI', 'info');
  static SUSPEND = new PersonelOperationType(2, 'ZAWIESZENIE', 'SUS', 'success');
  static TRAINING = new PersonelOperationType(3, 'SZKOLENIE', 'TRA', 'warning');
  static REJECT = new PersonelOperationType(4, 'ZWOLNIENIE', 'RJC', 'danger');
  static REVERT = new PersonelOperationType(5, 'Przywróc', 'REV', 'light');
  static VACATION = new PersonelOperationType(3, 'URLOP', 'VAC', 'warning');
  static _ = PersonelOperationType.closeEnum();

  public static array = [
    PersonelOperationType.ADD,
    PersonelOperationType.SET,
    PersonelOperationType.SUSPEND,
    PersonelOperationType.TRAINING,
    PersonelOperationType.REJECT,
    PersonelOperationType.REVERT,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): PersonelOperationType {
    return this.array[id];
  }
}

export class OfferOperationType extends Enumify {
  static ADD = new OfferOperationType(0, 'PROCES OFERTOWY', 'NEW', 'primary');

  static _ = OfferOperationType.closeEnum();

  private static array = [OfferOperationType.ADD];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): OfferOperationType {
    return this.array[id];
  }
}
export class CertOperationType extends Enumify {
  static ADD = new CertOperationType(0, 'PROCES CERTYFIKACJI', 'NEW', 'primary');

  static _ = CertOperationType.closeEnum();

  private static array = [CertOperationType.ADD];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): CertOperationType {
    return this.array[id];
  }
}
export class SettlementOperationType extends Enumify {
  static ADD = new SettlementOperationType(0, 'PROCES CERTYFIKACJI', 'NEW', 'primary');

  static _ = SettlementOperationType.closeEnum();

  private static array = [SettlementOperationType.ADD];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): SettlementOperationType {
    return this.array[id];
  }
}
/****************************************** ENTITY STATUS  ******************************/
export class EntityStatus extends Enumify {
  get getId() {
    return -1;
  }
}

export class MethodStatus extends EntityStatus {
  static NEW = new MethodStatus(0, 'NOWA', 'NEW', 'primary', [MethodOperationType.ADD]);
  static WORKING = new MethodStatus(1, 'W UŻYCIU', 'WRK', 'info', [
    MethodOperationType.CLONE,
    MethodOperationType.REJECT,
  ]);
  static CANCELED = new MethodStatus(2, 'WYCOFANA', 'CNL', 'danger', [MethodOperationType.REVERT]);
  static _ = MethodStatus.closeEnum();

  public static array = [MethodStatus.NEW, MethodStatus.WORKING, MethodStatus.CANCELED];
  id: number;
  name: string;
  symbol: string;
  badge: string;
  opers: MethodOperationType[];

  constructor(id: number, name: string, symbol: string, badge: string, opers: MethodOperationType[]) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
    this.opers = opers;
  }
  get getId() {
    return this.id;
  }
  public static byId(id: number): MethodStatus {
    return this.array[id];
  }

  public getDoneEntityStatus(operType: MethodOperationType) {
    console.log('getDoneStatus', this, operType);
    switch (this.id) {
      case MethodStatus.NEW.id:
        if (operType === MethodOperationType.ADD) {
          return MethodStatus.WORKING;
        }
        break;
      case MethodStatus.WORKING.id:
        // if (operType === MethodOperationType.EDIT) {
        //   return MethodStatus.WORKING;
        // } else if (operType === MethodOperationType.REJECT) {
        //   return MethodStatus.CANCELED;
        // }
        break;
      case MethodStatus.CANCELED.id:
        if (operType === MethodOperationType.REVERT) {
          return MethodStatus.WORKING;
        }
        break;
      default:
        return this;
    }
  }
}

export class ToolStatus extends EntityStatus {
  static NEW = new ToolStatus(0, 'NOWE', 'NEW', 'primary', [ToolOperationType.ADD]);
  static WORKING = new ToolStatus(1, 'SPRAWNE', 'WRK', 'info', [
    ToolOperationType.SERVICE,
    ToolOperationType.EDIT,
    ToolOperationType.REJECT,
  ]);
  static SERVICE = new ToolStatus(2, 'NIESPRAWNE', 'SRV', 'secondary', [
    ToolOperationType.EDIT,
    ToolOperationType.REJECT,
    ToolOperationType.REVERT,
  ]);
  static CANCELED = new ToolStatus(3, 'WYCOFANE', 'CNL', 'danger', [ToolOperationType.EDIT, ToolOperationType.REVERT]);
  static _ = ToolStatus.closeEnum();

  public static array = [ToolStatus.NEW, ToolStatus.WORKING, ToolStatus.SERVICE, ToolStatus.CANCELED];
  id: number;
  name: string;
  symbol: string;
  badge: string;
  opers: ToolOperationType[];

  constructor(id: number, name: string, symbol: string, badge: string, opers: ToolOperationType[]) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
    this.opers = opers;
  }
  get getId() {
    return this.id;
  }

  public getDoneEntityStatus(operType: ToolOperationType) {
    console.log('getDoneStatus', this, operType);
    switch (this.id) {
      case ToolStatus.NEW.id:
        if (operType === ToolOperationType.ADD) {
          return ToolStatus.WORKING;
        }
        break;
      case ToolStatus.WORKING.id:
        if (operType === ToolOperationType.EDIT || operType === ToolOperationType.SERVICE) {
          return ToolStatus.WORKING;
        } else if (operType === ToolOperationType.REJECT) {
          return ToolStatus.CANCELED;
        }
        break;
      case ToolStatus.SERVICE.id:
        if (operType === ToolOperationType.EDIT) {
          return ToolStatus.SERVICE;
        } else if (operType === ToolOperationType.REVERT) {
          return ToolStatus.WORKING;
        } else if (operType === ToolOperationType.REJECT) {
          return ToolStatus.CANCELED;
        }
        break;
      case ToolStatus.CANCELED.id:
        if (operType === ToolOperationType.REVERT) {
          return ToolStatus.WORKING;
        }
        break;
      default:
        return this;
    }
  }

  public static byId(id: number): ToolStatus {
    return this.array[id];
  }
}

export class SampleStatus extends EntityStatus {
  static REGISTERED = new SampleStatus(0, 'ZAREJESTROWANA', 'REJ', 'primary', [SampleOperationType.EXAM]);
  static IN_EXAM = new SampleStatus(1, 'W BADANIU', 'EXM', 'info', [SampleOperationType.EXAM]);
  static DONE = new SampleStatus(2, 'BADANIA WYKONANE', 'DON', 'danger', [SampleOperationType.EXAM]);
  static CORRECT = new SampleStatus(3, 'W KOREKCIE', 'CRT', 'danger', [SampleOperationType.EXAM]);

  static _ = SampleStatus.closeEnum();

  public static array = [SampleStatus.REGISTERED, SampleStatus.IN_EXAM, SampleStatus.DONE, SampleStatus.CORRECT];
  id: number;
  name: string;
  symbol: string;
  badge: string;
  opers: SampleOperationType[];

  constructor(id: number, name: string, symbol: string, badge: string, opers: SampleOperationType[]) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
    this.opers = opers;
  }
  get getId() {
    return this.id;
  }
  public static byId(id: number): SampleStatus {
    return this.array[id];
  }

  public getDoneEntityStatus(operType: SampleOperationType) {
    console.log('getDoneStatus', this, operType);
    return SampleStatus.DONE;
  }
}

export enum DashboardObjectTypes {
  METHOD = 0,
  TOOL = 1,
  PERSONEL = 2,
  OFFER = 3,
  TRAINING = 4,
  METRO = 5,
  CERT = 6,
  SAMPLE = 7,
}

export class UserStatus extends EntityStatus {
  static NEW = new UserStatus(0, 'NOWY', 'NEW', 'success', [PersonelOperationType.ADD]);
  static WORKING = new UserStatus(1, 'W PRACY', 'WRK', 'primary', [
    PersonelOperationType.SUSPEND,
    PersonelOperationType.REJECT,
  ]);
  static SUSPENDED = new UserStatus(2, 'NIEOBECNOŚĆ', 'SUS', 'secondary', [
    PersonelOperationType.REVERT,
    PersonelOperationType.REJECT,
  ]);
  static FIRED = new UserStatus(3, 'NIEOBECNOŚĆ DŁ.', 'CNL', 'danger', [PersonelOperationType.REVERT]);
  static _ = UserStatus.closeEnum();

  public static array = [UserStatus.NEW, UserStatus.WORKING, UserStatus.SUSPENDED, UserStatus.FIRED];
  id: number;
  name: string;
  symbol: string;
  badge: string;
  opers: PersonelOperationType[];

  constructor(id: number, name: string, symbol: string, badge: string, opers: PersonelOperationType[]) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
    this.opers = opers;
  }
  get getId() {
    return this.id;
  }

  public getDoneEntityStatus(operType: PersonelOperationType) {
    console.log('getDoneStatus', this, operType);
    switch (this.id) {
      case UserStatus.NEW.id:
        if (operType === PersonelOperationType.ADD) {
          return UserStatus.WORKING;
        }
        break;
      case UserStatus.WORKING.id:
        if (operType === PersonelOperationType.TRAINING || operType === PersonelOperationType.VACATION) {
          return UserStatus.WORKING;
        } else if (operType === PersonelOperationType.SUSPEND) {
          return UserStatus.SUSPENDED;
        } else if (operType === PersonelOperationType.REJECT) {
          return UserStatus.FIRED;
        }
        break;
      case UserStatus.SUSPENDED.id:
        if (operType === PersonelOperationType.REVERT) {
          return UserStatus.WORKING;
        } else if (operType === PersonelOperationType.REJECT) {
          return UserStatus.FIRED;
        }
        break;
      case UserStatus.FIRED.id:
        if (operType === PersonelOperationType.REVERT) {
          return UserStatus.WORKING;
        }
        break;
      default:
        return this;
    }
  }

  public static byId(id: number): UserStatus {
    return this.array[id];
  }
}

export enum MessageType {
  INFO = 0,
  WARNING = 1,
  DANGER = 2,
}

export enum MessageObjectType {
  ACTION = 0,
  ORDEER = 1,
  VISIT_AUTHORISED = 2,
}

export class MessageGroup extends Enumify {
  static ACTION = new MessageGroup(0, 'Akcja', 'ACT', 'primary');
  static ORDER = new MessageGroup(1, 'Zamówienie', 'VAU', 'info');
  static VISIT_AUTHORISE = new MessageGroup(2, 'Autoryzacja wizyty', 'VAU', 'info');
  static _ = MessageGroup.closeEnum();

  private static array = [MessageGroup.ACTION, MessageGroup.ORDER, MessageGroup.VISIT_AUTHORISE];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): MessageGroup {
    return this.array[id];
  }
}

export class FileGroup extends Enumify {
  static NOT_SPECIFIED = new FileGroup(0, 'Brak grupy', 'ACT', 'primary');
  static METHOD = new FileGroup(0, 'Plik metody badawczej', 'METHOD', 'primary');
  static TOOL = new FileGroup(1, 'Plik urządzenia badawczego', 'TOOL', 'info');
  static _ = FileGroup.closeEnum();

  private static array = [FileGroup.METHOD, FileGroup.TOOL];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): FileGroup {
    return this.array[id];
  }
}

export const PROCESS_STATUS_FREE = 2;

console.log('--- CONSTS FILE LODING END ---');
