








































import { Component, Ref, Vue } from 'vue-property-decorator';
import * as types from '../../types';
import * as API from '../../store/api';
import Modal from './Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ImportSamplesCSV extends Vue {
  @Ref() readonly modal: Modal;

  uploading = false;
  analysis = [];
  allLinesCount = 0;
  counter = 0;
  failedCounter = 0;
  modalMessage = '';
  dates = [];
  dots = '';
  dotsInterval;
  terminate = false;
  regex = /;\r\n|;\n/;
  mappingArray = [
    [/PELET SŁONECZNIKOWY/g, 'Pelet ze słonecznika'],
    [/Pobrał/g, 'Pobrał;'],
    [/Bioenergia/g, 'Bioenergia;'],
    [/Elpolab/g, 'Elpolab;'],
    [/;;/g, ';'],
  ];
  disabledOkButton = true;

  async importFileChange(e) {
    const response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
    }
    console.log('Import start');
    const formData = new FormData();
    formData.append('rd_file', e.target.files[0]);
    formData.set('filename', e.target.files[0].name);

    this.uploading = true;
    console.log(e.target.files[0]);
    const reader = new FileReader();
    this.modal.showModal();

    reader.onload = async function (theFile) {
      console.log('theFile', theFile);
      let resultString = reader.result.toString();
      for (let j = 0; j < this.mappingArray.length; j++) {
        resultString = resultString.replace(this.mappingArray[j][0], this.mappingArray[j][1]);
      }
      const allLines = resultString.split(this.regex);
      console.log('allLines', allLines);
      this.allLinesCount = allLines.length - 1;
      let response = await API.fetchSamples();
      let data = response.data;
      const samples = data.samples;
      response = await API.fetchDicts();
      data = response.data;
      const dicts = data.dicts;

      const func = function () {
        this.dots += '.';
        if (this.dots.length > 3) {
          this.dots = '';
        }
      }.bind(this);
      this.dotsInterval = window.setInterval(func, 500);
      for (let i = 1; i < allLines.length; i++) {
        if (this.terminate) {
          this.terminate = false;
          this.uploading = false;
          break;
        }
        console.log('lp. ', i);
        let line = allLines[i];
        if (line === '') {
          continue;
        }
        line = line.replace(/"/g, '');

        const sampleCsvRow = line.split(';');
        if (samples.find(s => s.number === sampleCsvRow[0])) {
          this.counter++;
          console.log('EXISTS');
        } else if (sampleCsvRow[0] == '') {
          continue;
        } else {
          console.log(sampleCsvRow);

          const NUMBER_COL = 0;
          const HOUR_COL = 2;
          const PLACE_COL = 3;
          const PT_NUMBER_COL = 4;
          const OBJECT_TYPE_ID_COL = 5;
          const IDENTIFIER_COL = 6;
          const VEHICLE_COUNT = 7;
          const TONNAGE_COL = 8;
          const TAKE_PERSON_COL = 9;


          const sample = new types.Sample();
          sample.number = sampleCsvRow[NUMBER_COL];
          const place = dicts.find(d => d.type === 8 && d.value.toLowerCase() === sampleCsvRow[3].toLowerCase());
          if (place) {
            sample.take_place_ids = [{ id: place.id }];
          }

          sample.pt_number = sampleCsvRow[PT_NUMBER_COL];
          sample.object_type_id = dicts.find(
            d => d.type === 4 && d.value.toLowerCase() === sampleCsvRow[OBJECT_TYPE_ID_COL].toLowerCase()
          ).id;
          sample.sample_object_id = dicts.find(d => d.type === 1 && d.value.toLowerCase() === 'biomasa stała').id;
          sample.vehicle_count = parseInt(sampleCsvRow[VEHICLE_COUNT]);
          sample.tonnage = parseFloat(sampleCsvRow[TONNAGE_COL]);
          sample.take_person_string = sampleCsvRow[TAKE_PERSON_COL];
          console.log(this.analysis);
          sample.tests = this.analysis.filter(a => ['132', '134', '135', '138'].includes(a.number));

          const takeHours = sampleCsvRow[HOUR_COL].split(/\r\n|\n/);
          const takeIds = sampleCsvRow[IDENTIFIER_COL].split(/\r\n|\n/);
          sample.sample_dates = [];
          for (let h = 0; h < takeHours.length; h++) {
            const sampleDate = {
              date_from: sampleCsvRow[1],
              time_from: takeHours[h],
              number: takeIds[h],
            };
            sample.sample_dates.push(sampleDate);
          }
          const response = await API.saveSample(sample);
          if (response.data.success) {
            this.counter++;
          } else {
            this.failedCounter++;
          }
        }
      }
      this.uploading = false;
      this.disabledOkButton = false;
      clearInterval(this.dotsInterval);
    }.bind(this);

    reader.readAsText(e.target.files[0], 'UTF-8');
  }
  stopClick() {
    this.disabledOkButton = false;
    this.terminate = true;
  }
}
