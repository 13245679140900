








































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, User, UserGroup, UserRight } from '../../types';
import moment from 'moment';
import * as helpers from '../../helpers';
import validator from 'validator';

const USER_ALREADY_EXISTED = 1;
const USER_EMAIL_ALREADY_EXISTED = 2;

@Component
export default class ModalUser extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  enabled = false;

  leftArrowSign = '<<';
  rightArrowSign = '>>';

  show = false;
  new = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;
  person: User = null;
  orginalPerson: User = null;
  item: DashboardItem = null;

  choosenRightGroups = []; // wybrane grupy uprawnien
  choosenGroupRights = []; // uprawnienia wybranej grupy
  choosenRights = []; // wybrane uprawnienia

  availableRightGroups = [];
  availableRights = [];

  addGroupToUserEnabled = false;
  addRightToUserEnabled = false;
  invalidate = 0;
  invalidateRight = 0;

  get status() {
    return consts.UserStatus.byId(this.person.status_id);
  }
  get getFullName() {
    return this.person?.firstname + ' ' + this.person?.lastname;
  }

  get isGroupSelected() {
    this.invalidate;
    let gr = this.availableRightGroups.find(g => g['selected'] === true);
    if (gr === undefined) {
      gr = this.choosenRightGroups.find(g => g['selected'] === true);
    }
    return gr !== undefined;
  }

  get isRightSelected() {
    this.invalidateRight;
    let right = this.availableRights.find(r => r['selected'] === true);
    if (right === undefined) {
      right = this.choosenRights.find(r => r['selected'] === true);
    }
    return right !== undefined;
  }

  get allUserRights() {
    const rights = [...this.choosenRights];
    this.choosenRightGroups.forEach(rg => {
      rg.rights.forEach(r => {
        if (!rights.includes(r)) {
          rights.push(r);
        }
      });
    });
    return rights;
  }

  addGroupToUser() {
    if (this.addGroupToUserEnabled) {
      const group = this.availableRightGroups.find(g => g['selected'] === true);
      if (group) {
        this.availableRightGroups = this.availableRightGroups.filter(g => g['selected'] !== true);
        this.choosenRightGroups.push(group);
        this.addGroupToUserEnabled = false;
      }
    }
  }
  removeUserGroup() {
    if (!this.addGroupToUserEnabled) {
      const group = this.choosenRightGroups.find(g => g['selected'] === true);
      if (group) {
        this.choosenRightGroups = this.choosenRightGroups.filter(g => g['selected'] !== true);
        this.availableRightGroups.push(group);
        this.addGroupToUserEnabled = true;
      }
    }
  }

  addRightToUser() {
    if (this.addRightToUserEnabled) {
      const right = this.availableRights.find(r => r['selected'] === true);
      if (right) {
        this.availableRights = this.availableRights.filter(r => r['selected'] !== true);
        this.choosenRights.push(right);
        this.addRightToUserEnabled = false;
      }
    }
  }

  removeUserRight() {
    if (!this.addRightToUserEnabled) {
      const right = this.choosenRights.find(r => r['selected'] === true);
      if (right) {
        this.choosenRights = this.choosenRights.filter(r => r['selected'] !== true);
        this.availableRights.push(right);
        this.addRightToUserEnabled = true;
      }
    }
  }

  clearRightSelection() {
    this.availableRights.forEach(r => {
      r['selected'] = false;
    });
    this.choosenRights.forEach(r => {
      r['selected'] = false;
    });
  }
  loadAvailableRights() {
    this.availableRights = []; //usersModule.allRights.filter(r => !this.person.rights.includes(r));
    console.log('Loaded rights', this.availableRights);
    this.availableRights.forEach(r => {
      r['selected'] = false;
    });
  }

  loadChoosenUserRights() {
    this.choosenRights = this.person.rights;
    this.choosenRights.forEach(r => {
      r['selected'] = false;
    });
  }

  clearGroupSelection() {
    this.availableRightGroups.forEach(g => {
      g['selected'] = false;
    });
    this.choosenRightGroups.forEach(g => {
      g['selected'] = false;
    });
  }

  loadSelectedGroupRights(group: UserGroup) {
    this.choosenGroupRights = group.rights;
  }

  selectGroup(group: UserGroup) {
    this.clearGroupSelection();
    group['selected'] = true;
    this.addGroupToUserEnabled = true;

    this.loadSelectedGroupRights(group);
    this.$forceUpdate();
    this.invalidate++;
  }

  loadAvailableRightGroups() {
    this.availableRightGroups = []; //usersModule.allGroups.filter(g => !this.person.groups.includes(g));
    this.availableRightGroups.forEach(g => {
      g['selected'] = false;
      g.load();
    });
  }

  selectChoosenGroup(group: UserGroup) {
    this.clearGroupSelection();
    group['selected'] = true;
    this.loadSelectedGroupRights(group);
    this.addGroupToUserEnabled = false;
    this.$forceUpdate();
    this.invalidate++;
  }

  loadChoosenRightGroups() {
    this.choosenRightGroups = this.person.groups;
    this.choosenRightGroups.forEach(g => {
      g['selected'] = false;
      g.load();
    });
  }

  selectChoosenRight(right: UserRight) {
    this.clearRightSelection();
    right['selected'] = true;
    this.addRightToUserEnabled = false;
    this.$forceUpdate();
    this.invalidateRight++;
  }
  selectAvailableRight(right: UserRight) {
    this.clearRightSelection();
    right['selected'] = true;
    this.addRightToUserEnabled = true;
    this.$forceUpdate();
    this.invalidateRight++;
  }

  showModal(params: DashboardItem = null) {
    this.show = true;
    this.new = params === null;
    if (this.new) {
      this.item = new DashboardItem();
      this.item.object_type_id = consts.DashboardObjectTypes.PERSONEL;
      this.params = this.item;
      this.params['enabled'] = true;
      this.enabled = true;
      this.person = new User();
    } else {
      this.params = params;
      this.item = params;
      this.enabled = params.enabled;
      this.person = Object.assign(new User(), params.object);
      this.orginalPerson = params.object as User;
      this.person.load();
    }
    this.loadAvailableRightGroups();
    this.loadChoosenRightGroups();
    this.loadAvailableRights();
    this.loadChoosenUserRights();
    console.log('showModal', this.item, this.new);
  }

  async saveModal() {
    this.save = true;
    this.params['save'] = true;
    this.params['new'] = this.new;

    if (this.person.firstname.length === 0) {
      helpers.error('Proszę wypełnić Imię');
      this.btnSave++;
      return;
    }
    if (this.person.lastname.length === 0) {
      helpers.error('Proszę wypełnić Nazwisko');
      this.btnSave++;
      return;
    }

    if (this.person.email.length === 0) {
      helpers.error('Proszę wypełnić adres Email');
      this.btnSave++;
      return;
    }

    if (!validator.isEmail(this.person.email)) {
      helpers.error('Niepoprawny adres Email !');
      this.btnSave++;
      return;
    }

    if (this.new) {
      this.person.created_date_time = moment().format(consts.DATE_TIME_FORMAT);
    }
    this.person.changed_date_time = moment().format(consts.DATE_TIME_FORMAT);

    this.person.groups = this.choosenRightGroups;
    this.person.rights = this.choosenRights;

    console.log('Saving user', this.person);
    //let response = null;
    /*
    if (this.new) {
      response = await usersModule.addUser(this.person);
    } else {
      response = await usersModule.updateUser(this.person);
    }*/
    /*
    if (response.data.success === false && response.data.error_code === USER_ALREADY_EXISTED) {
      console.log('User already existed');
      helpers.error('Podane imię i nazwisko jest już wykorzystane, użyj innego');
      this.btnSave++;
      return;
    }

    if (response.data.success === false && response.data.error_code === USER_EMAIL_ALREADY_EXISTED) {
      console.log('User email already existed');
      helpers.error('Podany email jest już wykorzystany, użyj innego');
      this.btnSave++;
      return;
    }
    this.person.id = response.data.user['id'];
    */
    this.params['object'] = this.person;
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);

    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
