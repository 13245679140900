








import { ExtendedParam, Dict } from '../../types';
import { Component, Vue, Prop, Ref, Emit, Watch } from 'vue-property-decorator';
import { ModalEvents } from '../../consts';
import GenericField from '../../components/spec/GenericField.vue';

@Component({
  components: { GenericField },
})
export default class GenericForm extends Vue {
  @Prop({ type: String, required: false, default: '' }) cellCss;
  @Prop({ type: String, required: false, default: 'lg' }) size;
  @Prop() readonly displayParams: Array<ExtendedParam>;
  @Prop({ type: Object, required: true }) object;

  @Ref() readonly fields: GenericField[];

  get getCellStyle() {
    if (this.cellCss) return this.cellCss;
    else return 'mb-3';
  }

  @Watch('displayParams')
  showParams() {
    console.log(this.displayParams, 'PARAMS');
  }

  created() {
    this.showParams();
  }
}
