import { render, staticRenderFns } from "./GenericField.vue?vue&type=template&id=514826fe&scoped=true&"
import script from "./GenericField.vue?vue&type=script&lang=ts&"
export * from "./GenericField.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514826fe",
  null
  
)

export default component.exports