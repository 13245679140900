



































import { Component, Vue, Ref } from 'vue-property-decorator';
import * as API from '../../../store/api';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import Modal from '../../../components/spec/Modal.vue';
import * as consts from '../../../consts';
import * as types from '../../../types';
import * as helpers from '@/helpers';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class ClientList extends Vue {
  @Ref() readonly modalFilter: Modal;
  params = new Array<types.ExtendedParam>();
  @Ref() readonly clientModal: Modal;
  @Ref() readonly list: GenericList;
  objects = [];
  modalParams = new Array<types.ExtendedParam>();
  show = true;
  objectCopy = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<types.ExtendedParam>();
  clientModalTitle = '';

  @Ref() filterForm: GenericForm;

  badgeActive = {
    name: 'Aktywny',
    variant: 'success',
  };

  badgeNotActive = {
    name: 'Nieaktywny',
    variant: 'danger',
  };

  processLoaded(object: any) {
    object.badge = object.active ? this.badgeActive : this.badgeNotActive;
    console.log(object, 'obj');
  }

  showClientModal(object: any = {}) {
    console.log(object, 'Received object...');
    if (!object['address']) {
      object['address'] = {};
      this.clientModalTitle = 'Dodaj nowego klienta';
    } else {
      this.clientModalTitle = 'Edytuj klienta';
      object['postal_code'] = object['address']['postal_code'];
      object['city'] = object['address']['city'];
      object['street_and_house_no'] = object['address']['street_and_house_no'];
    }

    this.objectCopy = Object.assign({}, object);
    this.clientModal.showModal();
  }
  async saveClient() {
    this.objectCopy['address'] = {};
    if (this.objectCopy['name'] == '' || this.objectCopy['nip'] == '' || this.objectCopy['short_name'] == '') {
      helpers.error('Nie wprowadzono wymaganych danych.');
      return;
    }

    this.objectCopy['active'] = this.objectCopy['active'] ? this.objectCopy['active'] : false;
    this.objectCopy['address']['city'] = this.objectCopy['city'];
    this.objectCopy['address']['street_and_house_no'] = this.objectCopy['street_and_house_no'];
    this.objectCopy['address']['postal_code'] = this.objectCopy['postal_code'];

    const response = await API.saveClient(this.objectCopy as types.Client);
    if (response.data.success) {
      helpers.info('Pomyślnie zapisano');
      this.list.fillObjects();
    } else {
      helpers.error('Wystąpił nieoczekiwany błąd po stronie serwera');
    }
    this.clientModal.hideModal();
    this.objectCopy = null;
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
  }

  async deleteClient(client) {
    const response = await API.deleteObjectModelById(client.id, 'Client');
    if (response.data.success) {
      helpers.info('Pomyślnie usunięto');
      this.list.fillObjects();
    }
  }

  sendFilterQuery(e: any) {
    console.log('OK', this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa klienta',
      },
      {
        fieldType: 'input',
        fieldName: 'contact_email',
        headerName: 'Email',
      },
      {
        fieldType: 'input',
        fieldName: 'contact_phone',
        headerName: 'Numer telefonu',
      },
      {
        fieldType: 'input',
        fieldName: 'nip',
        headerName: 'Numer NIP',
      },
      {
        fieldType: 'input',
        fieldName: 'address__city',
        headerName: 'Miasto',
      },

      {
        fieldType: 'multiselect',
        fieldName: 'active',
        options: [
          { name: 'TAK', id: 1 },
          { name: 'NIE', id: 0 },
        ],
        multiSelectTrack: 'name',
        action: (e: any, object: any) => {
          object.active = e.id;
        },
        headerName: 'Aktywny',
      },
    ];

    this.params = [
      {
        headerName: 'NAZWA KLIENTA',
        value: '$name',
        columnCss: 'col-2 p-0 text-left',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'NIP',
        value: '$nip',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'MIASTO',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
        value: '$address__city',
      },
      {
        headerCss: 'col-2',
        headerName: 'OSOBA KONTAKTOWA',
        value: '$contact_person_name',
      },
      {
        headerName: 'EMAIL',
        value: '$contact_email',
      },
      {
        fieldType: 'badge',
        badgeVariant: 'badge__variant',
        headerName: 'STATUS',
        sortField: 'active',
        value: '$badge__name',
      },
      {
        headerName: 'AKCJE',
        fieldType: 'bdropdown',
        getValues: (obj: any) => {
          return [
            {
              css: 'font-12',
              text: 'Usuń',
              action: async client => {
                this.deleteClient(client);
              },
            },
          ];
        },
        value: '',
      },
    ];

    this.modalParams = [
      {
        headerName: 'Nazwa firmy *',
        fieldType: 'input',
        fieldName: 'name',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Nazwa skrócona firmy *',
        fieldType: 'input',
        fieldName: 'short_name',
        mainCss: 'mt-2 wi-50',
      },
      {
        headerName: 'NIP *',
        fieldType: 'input',
        changed: (object, param) => {
          if (!helpers.checkNIP(object.nip)) {
            object.nip = '';
            helpers.error('Nieprawidłowy NIP');
          }
        },
        fieldName: 'nip',
        mainCss: 'mt-2 wi-30',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'checkbox',
        fieldName: 'active',
        mainCss: 'mt-2',
      },
      {
        headerName: '',
        fieldType: 'hr',
        fieldName: '',
        mainCss: 'my-3 light-gray',
      },
      {
        headerName: 'Imię i nazwisko osoby kontaktowej',
        fieldType: 'input',
        fieldName: 'contact_person_name',
        mainCss: 'mt-2',
      },
      {
        fieldType: 'input',
        headerName: 'Email kontaktowy',
        changed: (object, param) => {
          if (!helpers.checkMail(object.contact_email)) {
            object.contact_email = '';
            helpers.error('Nieprawidłowy email');
          }
        },
        fieldName: 'contact_email',
        mainCss: 'mt-2',
      },
      {
        fieldType: 'input',
        headerName: 'Telefon kontaktowy',
        changed: (object, param) => {
          if (!helpers.checkPhoneNumber(object.contact_phone)) {
            object.contact_phone = '';
            helpers.error('Nieprawidłowy numer telefonu');
          }
        },
        fieldName: 'contact_phone',
        mainCss: 'mt-2 wi-50',
      },
      {
        headerName: '',
        fieldType: 'hr',
        fieldName: '',
        mainCss: 'my-3 light-gray',
      },
      {
        headerName: 'Ulica i numer domu',
        fieldType: 'input',
        fieldName: 'street_and_house_no',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Miasto',
        fieldType: 'input',
        fieldName: 'city',
        mainCss: 'mt-2 wi-50',
      },
      {
        headerName: 'Kod pocztowy',
        fieldType: 'input',
        fieldName: 'postal_code',
        mainCss: 'mt-2 wi-25',
      },
      {
        headerName: '',
        fieldType: 'hr',
        fieldName: '',
        mainCss: 'my-3 light-gray',
      },
      {
        headerName: 'Umowa numer',
        fieldType: 'input',
        fieldName: 'contract',
        mainCss: 'mt-2',
      },
    ];
    console.log(this.objects, 'fetched objects');
  }
}
