



































import { Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus, BusEvents } from '@/helpers/eventbus';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ModalExamFilter from '@/components/spec/ModalExamFilter.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericList from '@/components/spec/GenericList.vue';
import MethodForm from '@/views/laborant/administration/MethodForm.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as consts from '@/consts';
import * as helpers from '@/helpers';
import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '@/consts';
import { Method, ExtendedParam, StandardMultiselectEngine } from '@/types';

@Component({
  components: {
    TopPanel,
    MethodForm,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class MethodList extends Vue {
  @Ref() readonly modalFilter: Modal;
  params = new Array<ExtendedParam>();
  @Ref() readonly methodModal: Modal;
  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  objectCopy = {};
  object = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;
  @Ref() readonly list: GenericList;
  isEditing = false;

  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }

  get getModalTitle() {
    if (this.isEditing) return 'Edycja metody';
    else return 'Nowa metoda';
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  processLoaded(object: any) {
    //console.log('loading obj....', object);
    object.status = consts.MethodStatus.byId(object.status);
  }

  showMethodModal(object: any = {}) {
    this.objectCopy = Object.assign({}, object);
    if (Object.entries(object).length == 0) {
      this.isEditing = false;
    } else this.isEditing = true;
    this.methodModal.showModal();
  }
  async saveModal(e: any) {
    this.objectCopy['status'] = 0;
    this.objectCopy['price'] = 0;
    this.objectCopy['sampleTestParams'] = this.objectCopy['sampleTestParams'].map(stp => ({
      ...stp,
      META: { MODEL_NAME: 'TestParam' },
    }));
    this.objectCopy['testToolGroups'] = this.objectCopy['testToolGroups'].map(ttg => ({
      ...ttg,
      testId: this.objectCopy['id'],
    }));
    console.log(this.objectCopy, 'objectCopy');
    const obj = this.objectCopy as any;
    console.log(this.objectCopy, 'Zapisywanie metody');
    const response = await API.saveMethod(this.objectCopy);
    if (response.data.success) {
      helpers.info('Pomyślnie zapisano metodę');
      this.list.fillObjects();
    }
    this.objectCopy = {};
  }

  hideModal() {
    EventBus.$emit(BusEvents.CLEAR);
  }

  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'number',
        headerName: 'Numer',
      },
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa metody',
      },
      {
        fieldType: 'input',
        fieldName: 'norm',
        headerName: 'Norma',
      },
      {
        fieldType: 'multiselect',
        value: '$section_id',
        headerName: 'Sekcja',
        action: (e: any, obj: any) => {
          obj.section_id = e.id;
        },
        options: await helpers.getDictAsArray(3),
        multiSelectTrack: 'value',
      },
      {
        headerName: 'Status',
        fieldType: 'multiselect',
        options: consts.MethodStatus.array.map(el => {
          return { id: el.id, name: el.name };
        }),
        fieldName: 'status',
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('status', 'id'),
      },
      {
        fieldType: 'multiselect',
        fieldName: 'actual',
        options: [
          { name: 'TAK', id: 0 },
          { name: 'NIE', id: 1 },
          { name: 'Wycofana bez zastąpienia', id: 2 },
        ],
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('actual', 'id'),
        headerName: 'Metoda aktualna',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'accreditation',
        options: [{ name: 'BRAK WYBORU' }, { name: 'N.AKREDYTOWANA', id: 0 }, { name: 'AKREDYTOWANA', id: 1 }],
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('accreditation', 'id'),
        headerName: 'Akredytowana(?)',
      },
    ];

    this.params = [
      {
        value: '$number',
        headerName: 'NUMER',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        value: 'name',
        fieldType: 'ntexts',
        headerName: 'NAZWA METODY',
        href: {
          getValues: (obj: any) => {
            return [
              {
                text: obj.name,
                css: 'font-14 text-left',
              },
            ];
          },
        },
        headerCss: 'col-3 p-0',
      },
      {
        value: '$norm',
        headerName: 'NORMA',
        headerCss: 'col-2 p-0',
      },
      {
        headerName: 'SEKCJA',
        value: '$section__value',
        headerCss: 'col-1 p-0',
      },
      {
        fieldType: 'badge',
        value: '$status__name',
        sortField: 'status',
        badgeVariant: '$status__badge',
        headerName: 'STATUS',
      },

      {
        fieldType: 'boolean',
        value: 'accreditation',
        headerName: 'AKR',
      },
      {
        headerName: 'AKT',
        sortField: 'actual',
        value: (object: Method) => {
          const arr = ['TAK', 'NIE', 'Wycofana bez zastąpienia'];
          return arr[object.actual];
        },
      },
      {
        headerName: 'OPERACJE',

        fieldType: 'ntexts',
        href: {
          getValues: (obj: Method) => {
            if (!obj) return;
            const opers = obj.status.opers;
            return opers.map(el => {
              return { text: el.name, css: 'font-12 clickable-text', oper: el };
            });
          },
          click: async (obj: Method, href: any) => {
            console.log(obj, href, 'METODA');
            const operation = href['oper'] as consts.MethodOperationType;
            const transform_type = {
              RVT: consts.MethodStatus.WORKING.id,
              RJC: consts.MethodStatus.CANCELED.id,
              NEW: consts.MethodStatus.WORKING.id,
            };
            obj.statusId = transform_type[operation.symbol];
            obj.status = consts.MethodStatus.byId(obj.statusId);
            const tempObj = Object.assign({}, obj);

            tempObj.status = obj.status.id as any;

            const response = await helpers.saveObjectModel(tempObj, 'Test');
            if (response) {
              helpers.info('Wprowadzono zmianę statusu.');
            } else {
              helpers.error('Wystąpił nieoczekiwany błąd.');
            }
          },
        },
        value: 'method_group__name',
      },
    ];

    this.modalParams = [
      {
        fieldType: 'input',
        value: 'invent_number',
        headerName: 'Numer inwenteryzacyjny',
      },
      {
        fieldType: 'input',
        value: 'name',
        headerName: 'Nazwa',
      },
      {
        fieldType: 'input',
        value: 'localization_name',
        headerName: 'Lokalizacja',
      },
    ];
    //console.log(this.objects, 'fetched objects');
  }

  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      setTimeout(() => {
        this.filterObject = { ...this.$route.query };
      }, 1000);
    }
  }
}
