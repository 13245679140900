


































































import { Component, Vue, Ref } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import ExamCardHeader from '../../../components/spec/ExamCardHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import * as API from '../../../store/api';
import store from '../../../store';
import multiselect from 'vue-multiselect';
import Modal from '../../../components/spec/Modal.vue';
import pdf from 'vue-pdf';
import * as helpers from '@/helpers';
import { ReportStatus } from '@/consts';

@Component({
  components: { TopPanel, ExamCardHeader, Footer, VueHtml2pdf, Modal, pdf },
})
export default class ResultsDocF10 extends Vue {
  @Ref() readonly rdPrint: VueHtml2pdf;
  @Ref() readonly uploadModal: Modal;
  @Ref() readonly acceptModal: Modal;

  sampleIds: Array<number> = [];
  finished = true;
  reloading = true;
  resultDoc: any = {};
  user: any = {};
  field = '';
  page = 1;
  src = '';

  pageCount = 0;

  async addUser() {
    this.finished = false;
    const field = this.field;
    this.resultDoc[field] = this.user.id;
    console.log(this.user, this.resultDoc, 'User, RD');

    let successText = '';

    if (this.resultDoc.user_authorised_id) {
      successText = 'Pomyślnie zautoryzowano.';
      this.resultDoc.status_id = ReportStatus.AUTHORISED.id;
    } else if (this.resultDoc.user_accepted_id) {
      successText = 'Pomyślnie zaakceptowano.';
      this.resultDoc.status_id = ReportStatus.ACCEPTED.id;
    }

    let response = await helpers.saveObjectModel(this.resultDoc, 'ResultsDoc');
    if (response.data.success) {
      response = await API.updateUserSignature(this.resultDoc.id);
      if (response.data.success) {
        helpers.info(successText);
        this.getDocument(this.resultDoc.id);
        this.finished = true;
      }
    }
  }

  modalText = '';
  showAcceptModal(choice) {
    this.field = choice;

    if (choice === 'user_accepted_id') {
      this.modalText = `Czy na pewno chcesz zaakceptować sprawozdanie o numerze ${this.resultDoc.number}?`;
    } else if (choice === 'user_authorised_id') {
      this.modalText = `Czy na pewno chcesz zautoryzować sprawozdanie o numerze ${this.resultDoc.number}?`;
    }
    this.acceptModal.showModal();
  }

  downloadPdf() {
    document.getElementById('downloadPdf').click();
  }

  downloadDocx() {
    document.getElementById('downloadDocx').click();
  }

  get docxUrl() {
    return this.src.replaceAll('.pdf', '.docx');
  }

  get pdfUrl() {
    return this.src.replaceAll('.docx', '.pdf');
  }

  async showAndDownload() {
    await this.uploadModal.showModal();
    //document.getElementById('downloadDoPcx').click();
  }

  get mediaURL() {
    return process.env.VUE_APP_API_URL + 'media';
  }

  file: any;
  fileData: any;
  swapBlob(e) {
    this.file = e.target.files[0];
  }

  async swapFiles() {
    const form = new FormData();
    form.append('file', this.file);
    form.append('guid', '');
    form.append(
      'data',
      JSON.stringify({
        model: 'ResultsDoc',
        related_id: this.resultDoc.id,
        id: this.fileData.id,
      })
    );
    form.append('filename', this.file.name);
    const response = await API.swapFile(form);
    const id = this.resultDoc.id;
    this.getDocument(id);
  }

  getLastChar (s: string) {
    return s[s.length - 1];
  }
  async createDocument() {
    this.finished = false;
    this.sampleIds = store.getters.getResultsDocSampleIds;
    console.log(this.$store.state, 'current store content');
    const response = await API.generateResult(
      this.$store.state.resultsDocSampleIds,
      this.$store.state.templateSelected,
      this.user.id
    );

    if (response.data.success === false) {
      helpers.error(response.data.err_descr);
      return;
    }

    if(this.getLastChar(this.mediaURL) === '/' || response.data.src[0] === '/') {
      this.src = `${this.mediaURL}${response.data.src}`;
    } else {
      this.src = `${this.mediaURL}/${response.data.src}`;
    }
    this.resultDoc = response.data.resultDoc;
    this.fileData = response.data.fileData;
    this.finished = true;
  }

  async getDocument(id) {
    this.finished = false;
    this.resultDoc = await helpers.getObject(parseInt(id), 'ResultsDoc');
    const response = await API.fetchFiles(this.resultDoc.id, 'ResultsDoc');
    if (!response.data.files[0]) {
      window.alert('Brak plików dla tego sprawozdania');
    }
    const file = response.data.files[0];
    this.fileData = file;
    if(this.getLastChar(this.mediaURL) === '/' || file.path[0] === '/') {
      this.src = `${this.mediaURL}${file.path}`;
    } else {
      this.src = `${this.mediaURL}/${file.path}`;
    }
    this.finished = true;
  }

  //wyrzucic templatke na ekran wyboru probek
  async mounted() {
    const id = this.$route.params.id;
    this.user = this.$store.state.currentUser;
    if (!id) {
      this.createDocument();
    } else {
      this.getDocument(id);
    }
  }
}
